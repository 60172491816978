import * as React from 'react'

import SvgIcon, { SvgIconProps } from '../../SvgIcon'

const FacebookIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => (
  <SvgIcon width="13" height="24" viewBox="0 0 13 24" fill="none" {...props}>
    <path
      d="M12.1481 13.5L12.8222 9.15656H8.60725V6.33797C8.60725 5.14969 9.19605 3.99141 11.0838 3.99141H13V0.293438C13 0.293438 11.2611 0 9.59853 0C6.12738 0 3.85847 2.08031 3.85847 5.84625V9.15656H0V13.5H3.85847V24H8.60725V13.5H12.1481Z"
      fill="#BBBBBB"
    />
  </SvgIcon>
)

export default FacebookIcon
