/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** An opaque cursor for pagination */
  Cursor: { input: any; output: any; }
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: Date | string; output: string; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: Date | string; output: string; }
  /**
   * A time string representing a time without timezone, using the format `"HH:mm:ss"` or `"12:00:00"`
   *
   */
  LocalTimeInput: { input: any; output: any; }
  /** A field whose value exists in the standard IANA Time Zone Database: https://www.iana.org/time-zones */
  TimeZone: { input: string; output: string; }
  /** A URL */
  URL: { input: string; output: string; }
};

export type About = {
  __typename: 'About';
  general: Scalars['String']['output'];
  team: Maybe<Scalars['String']['output']>;
};

export type Activity = {
  __typename: 'Activity';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum ActivityAvailability {
  ACTIVITY_AVAILABILITY_AFTERNOON = 'ACTIVITY_AVAILABILITY_AFTERNOON',
  ACTIVITY_AVAILABILITY_EVENING = 'ACTIVITY_AVAILABILITY_EVENING',
  ACTIVITY_AVAILABILITY_MORNING = 'ACTIVITY_AVAILABILITY_MORNING'
}

export enum ActivityLocationType {
  INDOOR = 'INDOOR',
  ONLINE = 'ONLINE',
  OUTDOOR = 'OUTDOOR'
}

export enum ActivitySpotLimitDisplayType {
  ACTIVITY_SPOT_LIMIT_DISPLAY_TYPE_FREE_SPOTS_COUNT = 'ACTIVITY_SPOT_LIMIT_DISPLAY_TYPE_FREE_SPOTS_COUNT',
  ACTIVITY_SPOT_LIMIT_DISPLAY_TYPE_PARTICIPANTS_COUNT = 'ACTIVITY_SPOT_LIMIT_DISPLAY_TYPE_PARTICIPANTS_COUNT'
}

export type AddMatch = BallsportMatch | ExpectedErrors;

export type AddOn = {
  __typename: 'AddOn';
  description: Scalars['String']['output'];
  fields: Array<AddOnField>;
  id: Scalars['ID']['output'];
  mandatory: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type AddOnField = NumberAddOnField | SelectAddOnField | TextAddOnField;

export type AddOnFieldInput = {
  __typename: 'AddOnFieldInput';
  id: Scalars['ID']['output'];
  multiSelectionValue: Maybe<Array<Scalars['ID']['output']>>;
  numberValue: Maybe<Scalars['Float']['output']>;
  singleSelectionValue: Maybe<Scalars['ID']['output']>;
  textValue: Maybe<Scalars['String']['output']>;
};

export type AddOnFieldInputInput = {
  id: Scalars['ID']['input'];
  multiSelectionValue?: InputMaybe<Array<Scalars['ID']['input']>>;
  numberValue?: InputMaybe<Scalars['Float']['input']>;
  singleSelectionValue?: InputMaybe<Scalars['ID']['input']>;
  textValue?: InputMaybe<Scalars['String']['input']>;
};

export type AddOnInput = {
  __typename: 'AddOnInput';
  fields: Array<AddOnFieldInput>;
  id: Scalars['ID']['output'];
};

export type AddOnInputInput = {
  fields: Array<AddOnFieldInputInput>;
  id: Scalars['ID']['input'];
};

export type AddParticipantOnMatchV2 = ExpectedErrors | MatchParticipant;

export enum AddParticipantToMatchAnswer {
  NO = 'NO',
  YES = 'YES'
}

export type AddToWaitingListResponse = ExpectedErrors | WaitingList;

export type AdditionalProfileInput = {
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
};

/** A budget which is applied to a cart item */
export type AppliedBudget = {
  __typename: 'AppliedBudget';
  /** The name of the Corporate Budget */
  budgetName: Scalars['String']['output'];
  /** The name of the Corporate providing the Budget */
  corporateName: Scalars['String']['output'];
  /** The amount used for this booking */
  reduction: MonetaryValue;
};

/** A voucher which is applied to a cart by the user */
export type AppliedVoucher = {
  __typename: 'AppliedVoucher';
  /** The voucher-code the user applied to the cart */
  code: Scalars['String']['output'];
  originalValue: Maybe<MonetaryValue>;
  remainingValue: Maybe<MonetaryValue>;
};

export enum Area {
  INDOOR = 'Indoor',
  OUTDOOR = 'Outdoor'
}

/** Products that a user has assigned, and which they can use for a booking order to use for booking `BookableItem`s */
export type AssignedBookingProduct = AssignedCard | AssignedMembership;

export type AssignedCard = AssignedProductInterface & {
  __typename: 'AssignedCard';
  assignedProductValidity: AssignedProductValidity;
  id: Scalars['ID']['output'];
  product: Card;
  totalUsages: Maybe<Scalars['Float']['output']>;
  usagesLeft: Maybe<Scalars['Float']['output']>;
  validFrom: Maybe<Scalars['Date']['output']>;
  validUntil: Maybe<Scalars['Date']['output']>;
};

export type AssignedMembership = AssignedProductInterface & {
  __typename: 'AssignedMembership';
  assignedProductValidity: AssignedProductValidity;
  id: Scalars['ID']['output'];
  paymentAndCancellationTerms: Maybe<Scalars['String']['output']>;
  periods: Array<MembershipPeriod>;
  product: Membership;
};

/** Products that a user has purchased */
export type AssignedProduct = AssignedCard | AssignedMembership | AssignedRecording | AssignedVoucher | GenericAssignedProduct;

export type AssignedProductInterface = {
  assignedProductValidity: AssignedProductValidity;
  id: Scalars['ID']['output'];
};

export type AssignedProductOrderItem = OrderItemInterface & {
  __typename: 'AssignedProductOrderItem';
  assignedProduct: AssignedProduct;
  confirmedWith: Maybe<ConfirmWithOption>;
  endPrice: Scalars['Float']['output'];
  purchasedWith: Maybe<PurchasedWith>;
  quantity: Scalars['Float']['output'];
  reduction: Scalars['Float']['output'];
};

export type AssignedProductValidity = {
  __typename: 'AssignedProductValidity';
  additionalInformation: Maybe<Scalars['String']['output']>;
  isValid: Scalars['Boolean']['output'];
  /** @deprecated Please use UsageLimitPeriodV2 instead, which returns `start` and `end` as datetime string in ISO 8601 format */
  usageLimitPeriod: Maybe<UsageLimitPeriod>;
  usageLimitPeriodV2: Maybe<UsageLimitPeriodV2>;
};

/** Products that are assigned to a user (by purchase or manually), only for user profile */
export type AssignedProductsForUserProfileType = AssignedCard | AssignedMembership;

export type AssignedRecording = {
  __typename: 'AssignedRecording';
  id: Scalars['ID']['output'];
  password: Maybe<Scalars['String']['output']>;
  product: Recording;
  url: Scalars['String']['output'];
  validUntil: Maybe<Scalars['DateTime']['output']>;
};

export type AssignedRecordingConnection = {
  __typename: 'AssignedRecordingConnection';
  edges: Array<AssignedRecordingEdge>;
  /** The actual "AssignedRecording" items */
  nodes: Array<AssignedRecording>;
  pageInfo: PageInfo;
};

export type AssignedRecordingEdge = {
  __typename: 'AssignedRecordingEdge';
  cursor: Scalars['Cursor']['output'];
  node: AssignedRecording;
};

export type AssignedVoucher = AssignedProductInterface & {
  __typename: 'AssignedVoucher';
  assignedProductValidity: AssignedProductValidity;
  /** The code of the voucher */
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  product: Voucher;
  remainingValue: MonetaryValue;
  usedValue: MonetaryValue;
  value: MonetaryValue;
};

export type AuthParamsInput = {
  /** The personal token of a pending corporate employee. */
  corporateInvitationToken?: InputMaybe<Scalars['ID']['input']>;
  /** The ID of the corporate who invited this user as an employee. If added to the request the user will become a corporate employee. */
  corporatePartner?: InputMaybe<Scalars['ID']['input']>;
  origin: Origin;
  /**
   * This is an optional query string to append to the verification link inside E-Mails that are sent as part of the authentication flow.
   *     The resulting link looks something like this: https://www.eversports.at/auth/verify-email?token=${token}&${queryString}
   */
  queryString?: InputMaybe<Scalars['String']['input']>;
  /** Region describing where this call is made from. This is primarily used to generate valid localized content (eg urls for registration emails). Use a valid ISO code here like `AT` */
  region?: InputMaybe<Scalars['String']['input']>;
};

export type AuthResponse = AuthResult | ExpectedErrors;

export type AuthResult = {
  __typename: 'AuthResult';
  /** ONLY DEV: This is needed for e2e tests and convenient for local development */
  activationLink: Maybe<Scalars['String']['output']>;
  apiToken: Scalars['String']['output'];
  registrationSource: Maybe<RegistrationSource>;
  user: User;
};

/** Defines timeranges on certain weekdays, within certain periods of the year. E.g. During summer season, on Tuesdays and Thursdays from 9:00-17:00. This is mostly used for opening hours */
export type Availability = {
  __typename: 'Availability';
  /** Seasonality end, month and day formatted as MM-DD */
  end: Scalars['String']['output'];
  periods: Array<AvailabilityPeriod>;
  /** Seasonality start, month and day formatted as MM-DD */
  start: Scalars['String']['output'];
};

export type AvailabilityPeriod = {
  __typename: 'AvailabilityPeriod';
  /** Time end, hour and minute formatted as hh:mm */
  end: Scalars['String']['output'];
  /** Time start, hour and minute formatted as hh:mm */
  start: Scalars['String']['output'];
  weekdays: Array<Weekday>;
};

export type AvailableBenefits = {
  __typename: 'AvailableBenefits';
  /** the corporate partner providing for these benefits */
  corporatePartner: Maybe<CorporatePartner>;
  isBudgetAvailable: Scalars['Boolean']['output'];
  isSpecialPriceAvailable: Scalars['Boolean']['output'];
};

export type AvailableBudget = {
  __typename: 'AvailableBudget';
  /** the Budget settings regulating this user's available Budget */
  corporateBudget: CorporateBudget;
  corporatePartner: CorporatePartner;
  display: BudgetLimit;
  employeeBudgetDepleted: Scalars['Boolean']['output'];
  employeeLimit: BudgetLimit;
  totalBudgetDepleted: Scalars['Boolean']['output'];
};

export type BallsportMatch = MatchInterface & ParticipationInterface & {
  __typename: 'BallsportMatch';
  /** Friends of the logged in user who are participating */
  attendingFriends: Maybe<FriendConnection>;
  /** @deprecated Information about the booking will be resolved on the 'BallsportMatch' directly */
  booking: Maybe<CourtBooking>;
  cancellationCondition: Scalars['String']['output'];
  comments: MatchCommentConnection;
  competitionType: Maybe<MatchCompetitionType>;
  court: Maybe<Court>;
  end: Scalars['DateTime']['output'];
  gender: Maybe<MatchGender>;
  id: Scalars['ID']['output'];
  isPaid: Scalars['Boolean']['output'];
  level: Maybe<MatchLevel>;
  matchVisibilityValidations: Array<MatchVisibilityValidation>;
  maxParticipants: Maybe<Scalars['Int']['output']>;
  meta: MetaData;
  minParticipants: Maybe<Scalars['Int']['output']>;
  /** @deprecated Deprecated in favour of organizerV2 */
  organizer: User;
  organizerNote: Maybe<Scalars['String']['output']>;
  organizerV2: MatchOrganizerType;
  participants: Array<MatchParticipant>;
  participationState: Maybe<ParticipationState>;
  price: Maybe<MonetaryValue>;
  relativeLink: Scalars['String']['output'];
  sport: Sport;
  start: Scalars['DateTime']['output'];
  state: BallsportMatchState;
  venue: Venue;
  visibility: MatchVisibility;
};


export type BallsportMatchAttendingFriendsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BallsportMatchCommentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type BallsportMatchConnection = {
  __typename: 'BallsportMatchConnection';
  edges: Array<BallsportMatchEdge>;
  /** The actual "BallsportMatch" items */
  nodes: Array<BallsportMatch>;
  pageInfo: PageInfo;
};

export type BallsportMatchEdge = {
  __typename: 'BallsportMatchEdge';
  cursor: Scalars['Cursor']['output'];
  node: BallsportMatch;
};

export enum BallsportMatchState {
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  MOVED = 'MOVED',
  PLANNED = 'PLANNED',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED'
}

export type BaseNotification = NotificationInterface & {
  __typename: 'BaseNotification';
  contentType: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  headerText: Maybe<Scalars['String']['output']>;
  icon: Maybe<NotificationIcon>;
  id: Scalars['ID']['output'];
  link: Maybe<NotificationLink>;
  seen: Scalars['Boolean']['output'];
  text: Maybe<Scalars['String']['output']>;
};

export type BookableItem = CourtBookableItem | EventBookableItem;

export type BookableItemCartItem = CartItemInterface & {
  __typename: 'BookableItemCartItem';
  addOns: Array<AddOn>;
  addOnsInput: Array<AddOnInput>;
  appliedBudget: Maybe<AppliedBudget>;
  appliedVoucher: Maybe<AppliedVoucher>;
  /** @deprecated Use `confirmWithOptions` instead, which is consistent for `BookableItemCartItem` and `ProductCartItem` */
  assignedProducts: Array<AssignedBookingProduct>;
  bookableItem: BookableItem;
  commentInput: Maybe<Scalars['String']['output']>;
  confirmWithOptions: Array<ConfirmWithOption>;
  id: Scalars['ID']['output'];
  /** Provides Information on whether the user can or must add other players to their booking. This is mostly used at Tennis Clubs, where members provide the information with what fellow member they are playing upon reservation of a court */
  multiPlayerSettings: Maybe<MultiPlayerSettings>;
  purchaseOptions: Array<PurchaseOption>;
  selectedProductId: Maybe<Scalars['String']['output']>;
  settleWith: Maybe<SettlementOption>;
};

export type Booking = CourtBooking | EventBooking;

export type BookingInterface = {
  id: Scalars['ID']['output'];
  timeRange: TimeRange;
};

export type BookingOrderItem = OrderItemInterface & {
  __typename: 'BookingOrderItem';
  booking: Booking;
  confirmedWith: Maybe<ConfirmWithOption>;
  endPrice: Scalars['Float']['output'];
  purchasedWith: Maybe<PurchasedWith>;
  reduction: Scalars['Float']['output'];
};

export type BoundingBox = {
  __typename: 'BoundingBox';
  /** Right upper coordinates of the bounding box */
  northEast: Coordinate;
  /** Left lower coordinates of the bounding box */
  southWest: Coordinate;
};

export type BoundingBoxArgs = {
  /** Right upper coordinates of the bounding box */
  northEast: CoordinateArgs;
  /** Left lower coordinates of the bounding box */
  southWest: CoordinateArgs;
};

export enum BudgetInterval {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  YEAR = 'YEAR'
}

export type BudgetLimit = Limited | Unlimited;

export enum BudgetType {
  ONCE = 'ONCE',
  RENEWING = 'RENEWING'
}

export type CancelMatch = BallsportMatch | ExpectedErrors;

export type Card = ProductInterface & {
  __typename: 'Card';
  /** List of activities for which this Card can be used. */
  activities: Array<Activity>;
  /** This is a special offer reserved for new customers */
  allowedOnlyForNewCustomers: Scalars['Boolean']['output'];
  /** List of time periods within which this Card can be used. */
  availabilities: Array<Availability>;
  /** This product can be bought only once per customer */
  canBePurchasedOnlyOncePerCustomer: Scalars['Boolean']['output'];
  cardType: CardType;
  category: Maybe<CardCategory>;
  /** List of courts on which this Card can be used. */
  courts: Array<Court>;
  description: Maybe<Scalars['String']['output']>;
  displayInformation: CardDisplayInformation;
  /** A list of special prices the currently logged in user is actually eligible for. Check `specialPrices` if you want to know the generally available special prices independently from user context. */
  eligibleSpecialPrices: Array<SpecialPrice>;
  id: Scalars['ID']['output'];
  /** This is an exclusive reduced price for corporate employees */
  isCorporatePrice: Scalars['Boolean']['output'];
  /** This is a trial offer for people new to the studio */
  isTrialCard: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** A previous "was" price of the product, has no relevance for actual purchase, but is only meant to be displayed as strike-through */
  originalPrice: Maybe<MonetaryValue>;
  price: MonetaryValue;
  productPurchaseInformation: ProductPurchaseInformation;
  /** A list of *all* special prices theoretically available for that product. Check `eligibleSpecialPrices` if your only interested in the prices the currently logged in user is actually eligible for. */
  specialPrices: Array<SpecialPrice>;
  /** List of sports for which this Card can be used. */
  sports: Array<Sport>;
  usages: Array<UsageLimit>;
  /** @deprecated Deprecated because of renamed type, pls use validityV2 instead */
  validity: Maybe<Validity>;
  validityV2: Maybe<TimePeriod>;
  /** List of venues at which the Card can be used. */
  venues: Array<Venue>;
};

/** An arbitrary categorization by the venue to group their products, which is only used for display and has no logical implications. */
export type CardCategory = {
  __typename: 'CardCategory';
  name: Scalars['String']['output'];
  priority: Scalars['Float']['output'];
};

export type CardConnection = {
  __typename: 'CardConnection';
  edges: Array<CardEdge>;
  /** The actual "Card" items */
  nodes: Array<Card>;
  pageInfo: PageInfo;
};

/** This is only displayinformation irrelevant for booking rules */
export type CardDisplayInformation = {
  __typename: 'CardDisplayInformation';
  area: Maybe<Area>;
  displayLimit: DisplayLimit;
  surface: Maybe<Surface>;
  targetAudience: Maybe<Scalars['String']['output']>;
};

export type CardEdge = {
  __typename: 'CardEdge';
  cursor: Scalars['Cursor']['output'];
  node: Card;
};

export enum CardType {
  CARD_TYPE_COURT_ABO = 'CARD_TYPE_COURT_ABO',
  CARD_TYPE_COURT_BLOCK = 'CARD_TYPE_COURT_BLOCK',
  CARD_TYPE_COURT_CREDIT = 'CARD_TYPE_COURT_CREDIT',
  CARD_TYPE_COURT_SEASON = 'CARD_TYPE_COURT_SEASON',
  CARD_TYPE_EVENT_BLOCK = 'CARD_TYPE_EVENT_BLOCK',
  CARD_TYPE_EVENT_TIME = 'CARD_TYPE_EVENT_TIME'
}

export type Cart = {
  __typename: 'Cart';
  /** The Budget a user could potentially use for this cart */
  availableBudget: Maybe<AvailableBudget>;
  /** The benefits available on the current state of the cart */
  availableCorporateBenefits: AvailableBenefits;
  canConfirmOrder: Scalars['Boolean']['output'];
  /** Returns the customer associated with the cart */
  customer: Maybe<Customer>;
  id: Scalars['ID']['output'];
  /** `True` if vouchers are enabled for this cart, otherwise `false` */
  isVoucherEnabled: Scalars['Boolean']['output'];
  items: Array<CartItem>;
  /** An optional `Mandate` that is available for this cart */
  mandate: Maybe<Mandate>;
  multiAnswerPatronQuestionInput: Maybe<PatronageAnswer>;
  patronQuestionInput: Maybe<Scalars['Boolean']['output']>;
  /** Determines which type of patron question should be shown to the user */
  patronQuestionType: PatronQuestionType;
  /** Returns an array of currently possible payment methods for the given cart. This value might change over time based on the carts configuration. If there's no payment necessary this will be null */
  paymentMethods: Maybe<Array<PaymentMethod>>;
  /** Delivers all the venue-policies the user has to accept for this cart */
  policies: Array<Policy>;
  /** Policies the user accepted for this cart */
  policiesInput: Array<Scalars['ID']['output']>;
  priceTable: Maybe<PriceTable>;
  quickCheckoutAvailable: Scalars['Boolean']['output'];
  /** Determines whether the patron question (PATRON_QUESTION_TYPE_CHANNEL) should be randomized or not */
  randomizePatronQuestion: Scalars['Boolean']['output'];
  requiredInformation: Array<RequiredInformationType>;
  requiredInformationInput: RequiredInformation;
  /**
   * Determines whether a question should be shown asking the user whether or not they have been at this venue before
   * @deprecated Use `patronQuestionType` instead, as we now support more than one type of patron question
   */
  showPatronQuestion: Scalars['Boolean']['output'];
  /** Available `StoredPaymentMethod`  available for this cart */
  storedPaymentMethods: Array<StoredPaymentMethod>;
  valid: Scalars['Boolean']['output'];
  venue: Venue;
};

export type CartItem = BookableItemCartItem | ProductCartItem;

export type CartItemInterface = {
  appliedBudget: Maybe<AppliedBudget>;
  appliedVoucher: Maybe<AppliedVoucher>;
  confirmWithOptions: Array<ConfirmWithOption>;
  id: Scalars['ID']['output'];
  purchaseOptions: Array<PurchaseOption>;
  settleWith: Maybe<SettlementOption>;
};

export type CartResponse = Cart | ExpectedErrors;

export type CheapestProduct = Card | Membership;

export type City = {
  __typename: 'City';
  /** The URL under which pages showing this city should be hosted. Can be used to refer to the proper canonical URL on the client for pages like the venue profile or city discover pages. */
  canonicalHost: Scalars['String']['output'];
  districts: Array<District>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  popularSports: Array<Sport>;
  slug: Scalars['String']['output'];
};

export type CitySearchResult = SearchResult & {
  __typename: 'CitySearchResult';
  coordinate: Coordinate;
  country: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  matchedName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  secondaryMatch: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
};

export type Company = {
  __typename: 'Company';
  id: Scalars['ID']['output'];
  latestPolicies: Array<Policy>;
  name: Scalars['String']['output'];
  venues: Array<Venue>;
};

export enum ComparisonOperator {
  EQUAL = 'EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  SMALLER_THAN = 'SMALLER_THAN',
  SMALLER_THAN_OR_EQUAL = 'SMALLER_THAN_OR_EQUAL'
}

/** Products that a user has assigned, and which they can use to confirm a `CartItem` without payment */
export type ConfirmWithOption = AssignedCard | AssignedMembership;

export type Contact = {
  __typename: 'Contact';
  email: Maybe<Scalars['String']['output']>;
  facebook: Maybe<Scalars['String']['output']>;
  instagram: Maybe<Scalars['String']['output']>;
  telephone: Maybe<Scalars['String']['output']>;
  website: Maybe<Scalars['String']['output']>;
  youtube: Maybe<Scalars['String']['output']>;
};

export type Coordinate = {
  __typename: 'Coordinate';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type CoordinateArgs = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type CorporateBenefits = {
  __typename: 'CorporateBenefits';
  availableBudget: Maybe<AvailableBudget>;
  corporatePartner: CorporatePartner;
  hasSpecialPriceAccess: Scalars['Boolean']['output'];
};

export type CorporateBudget = {
  __typename: 'CorporateBudget';
  employeeLimit: BudgetLimit;
  expiry: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Please use the nullable intervalV2 instead! This non-nullable interval does not account for new absolute one-time budget. (It returns YEAR instead) */
  interval: BudgetInterval;
  intervalV2: Maybe<BudgetInterval>;
  totalLimit: BudgetLimit;
  type: BudgetType;
};

export type CorporateInvitationData = {
  __typename: 'CorporateInvitationData';
  corporateBudget: Maybe<CorporateBudget>;
  corporatePartner: CorporatePartner;
  hasSpecialPriceAccess: Scalars['Boolean']['output'];
};

export type CorporatePartner = {
  __typename: 'CorporatePartner';
  additionalDescription: Maybe<Scalars['String']['output']>;
  defaultBudget: Maybe<CorporateBudget>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  logo: Maybe<Image>;
  name: Scalars['String']['output'];
  /** @deprecated Corporate Vouchercodes are being replaced by the new Budget. */
  voucherCode: Maybe<Scalars['String']['output']>;
};

export type Country = {
  __typename: 'Country';
  iso: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Court = {
  __typename: 'Court';
  area: Maybe<CourtAreaType>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  surface: Maybe<CourtSurfaceType>;
};

export enum CourtAreaType {
  INDOOR = 'INDOOR',
  OUTDOOR = 'OUTDOOR'
}

export type CourtAvailability = {
  date: Scalars['Date']['input'];
  time?: InputMaybe<Scalars['LocalTimeInput']['input']>;
};

export type CourtBookableItem = {
  __typename: 'CourtBookableItem';
  activityImage: Maybe<Image>;
  cancellationCondition: Scalars['String']['output'];
  court: Court;
  id: Scalars['ID']['output'];
  sport: Sport;
  timeRange: TimeRange;
  venue: Venue;
};

export type CourtBooking = BookingInterface & ParticipationInterface & {
  __typename: 'CourtBooking';
  /** Friends of the logged in user who are participating */
  attendingFriends: Maybe<FriendConnection>;
  bookableItem: CourtBookableItem;
  confirmedWith: Maybe<CourtBookingConfirmWithOption>;
  court: Court;
  customer: Customer;
  customerAccessPin: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /**
   * Returns true if
   *       a) payment state of the first fully paid invoice is succeeded
   *       OR
   *       b) first issued invoice amount == 0 with no payment (company budget, discount)
   *
   */
  isPaid: Scalars['Boolean']['output'];
  match: Maybe<Match>;
  participationState: Maybe<ParticipationState>;
  payment: Maybe<Payment>;
  purchasedWith: Maybe<SinglePurchase>;
  state: CourtBookingState;
  timeRange: TimeRange;
};


export type CourtBookingAttendingFriendsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Products that a user has assigned, and which they can use confirm a court booking */
export type CourtBookingConfirmWithOption = AssignedCard | GenericAssignedProduct;

export enum CourtBookingState {
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  MOVED = 'MOVED',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED'
}

export enum CourtSurfaceType {
  ARTIFICIAL_GRASS = 'ARTIFICIAL_GRASS',
  ARTIFICIAL_ICE = 'ARTIFICIAL_ICE',
  ASH = 'ASH',
  ASPHALT = 'ASPHALT',
  CARPET = 'CARPET',
  CARPET_GRANULE = 'CARPET_GRANULE',
  CONCRETE = 'CONCRETE',
  FELT = 'FELT',
  GRANULE = 'GRANULE',
  GRASS = 'GRASS',
  HARD_COURT = 'HARD_COURT',
  ICE = 'ICE',
  OPTICOURT = 'OPTICOURT',
  PARQUET = 'PARQUET',
  PVC = 'PVC',
  RED_COURT = 'RED_COURT',
  SAND = 'SAND',
  SYNTHETIC = 'SYNTHETIC',
  TARTAN = 'TARTAN',
  WATER = 'WATER'
}

export type CreatedBallsportMatch = BallsportMatch | ExpectedErrors;

export type CredentialLoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Customer = {
  __typename: 'Customer';
  accessPin: Maybe<Scalars['String']['output']>;
  customerGroup: CustomerGroup;
  id: Scalars['ID']['output'];
  isSubscribedToNewsletter: Scalars['Boolean']['output'];
  mandate: Maybe<Mandate>;
};

export type CustomerGroup = {
  __typename: 'CustomerGroup';
  company: Company;
  id: Scalars['ID']['output'];
};

export type DeleteMatch = BallsportMatch | ExpectedErrors;

/** This data is used on our discover pages to improve the seo ranking */
export type DiscoverContent = {
  __typename: 'DiscoverContent';
  bookableSports: Array<Sport>;
  /** additional information about the page */
  description: Maybe<Scalars['String']['output']>;
  /** heading of the page eg. h1 */
  heading: Scalars['String']['output'];
  sections: Array<Section>;
  similarSports: Array<Sport>;
};

export type DiscoverContext = {
  boundingBox: BoundingBox;
  center: Coordinate;
  city: Maybe<City>;
  content: Maybe<DiscoverContent>;
  meta: Maybe<MetaData>;
  sport: Maybe<Sport>;
};

export type DiscoverContextForCity = DiscoverContext & DiscoverVenueContext & {
  __typename: 'DiscoverContextForCity';
  /** The sports bookable in and around this city */
  bookableSports: Array<Sport>;
  boundingBox: BoundingBox;
  center: Coordinate;
  city: City;
  content: Maybe<DiscoverContent>;
  meta: Maybe<MetaData>;
  onlineVenues: VenueConnection;
  sport: Maybe<Sport>;
};

export type DiscoverContextForCityAndSport = DiscoverContext & DiscoverVenueContext & {
  __typename: 'DiscoverContextForCityAndSport';
  boundingBox: BoundingBox;
  center: Coordinate;
  city: Maybe<City>;
  content: Maybe<DiscoverContent>;
  /** This field returns the events for the sport discover page. */
  eventBookableItems: EventBookableItemConnection;
  /** This field returns the events for the sport discover page. Be aware that this is slower than `eventBookableItems`. */
  eventSessions: EventSessionConnection;
  /** This field returns, on the basis of the sport category, available filters for the sport discover page. */
  filters: DiscoverFilter;
  meta: Maybe<MetaData>;
  offerings: Array<DiscoverOfferingType>;
  onlineVenues: VenueConnection;
  sport: Sport;
  /** @deprecated This resolveProperty just fixes the current initial load issues on the react application. It should be removed and only exist in the venue resolver. */
  venueSearch: VenueConnection;
};


export type DiscoverContextForCityAndSportEventBookableItemsArgs = {
  activityLocationType?: InputMaybe<Array<ActivityLocationType>>;
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  difficultyLevel?: InputMaybe<Array<EventDifficultyLevel>>;
  eventTypes?: InputMaybe<Array<EventType>>;
  eventVisibility?: InputMaybe<Array<EventVisibility>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  timeRange?: InputMaybe<OpenEndTimeRangeInput>;
};


export type DiscoverContextForCityAndSportEventSessionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  eventFilters: EventFilters;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  venueFilters: DiscoverContextVenueFilters;
};


export type DiscoverContextForCityAndSportVenueSearchArgs = {
  filters: DiscoverContextVenueFilters;
  sort?: InputMaybe<VenueSearchSort>;
  userLocation?: InputMaybe<CoordinateArgs>;
};

export type DiscoverContextVenueFilters = {
  activityAvailabilities?: InputMaybe<Array<ActivityAvailability>>;
  courtAreas?: InputMaybe<Array<CourtAreaType>>;
  courtAvailability?: InputMaybe<CourtAvailability>;
  courtSurfaces?: InputMaybe<Array<CourtSurfaceType>>;
  districts?: InputMaybe<Array<Scalars['ID']['input']>>;
  reviewCount?: InputMaybe<IntegerValueQuery>;
  /** Introduce minor randomness into the order of venues to make the page appear more recent and ever-changing to search engines. */
  seoShuffle?: InputMaybe<Scalars['Boolean']['input']>;
  sports?: InputMaybe<Array<Scalars['ID']['input']>>;
  venueAmenities?: InputMaybe<Array<VenueAmenityType>>;
  venueAttributes?: InputMaybe<Array<VenueAttributes>>;
  venueOfferingTypes?: InputMaybe<Array<VenueOfferingType>>;
};

export type DiscoverCoordinateContext = {
  __typename: 'DiscoverCoordinateContext';
  boundingBox: BoundingBox;
  center: Coordinate;
  offerings: Array<DiscoverOfferingType>;
};

export type DiscoverFilter = {
  __typename: 'DiscoverFilter';
  surfaces: Maybe<Array<CourtSurfaceType>>;
};

export type DiscoverMatchContext = DiscoverContext & {
  __typename: 'DiscoverMatchContext';
  boundingBox: BoundingBox;
  center: Coordinate;
  city: Maybe<City>;
  content: Maybe<DiscoverContent>;
  filters: DiscoverMatchFilter;
  /** Find public matches. Currently only future matches, sorted by start asc */
  matches: BallsportMatchConnection;
  meta: Maybe<MetaData>;
  /** Offerings are only available for a certain sport. If no sport is passed, it resolves as empty array */
  offerings: Array<DiscoverOfferingType>;
  sport: Maybe<Sport>;
};


export type DiscoverMatchContextMatchesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filters: MatchFilters;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DiscoverMatchFilter = {
  __typename: 'DiscoverMatchFilter';
  sports: Maybe<Array<Sport>>;
  venues: Maybe<Array<Venue>>;
};

export enum DiscoverOfferingType {
  DISCOVER_OFFERING_CAMP_ACTIVITY = 'DISCOVER_OFFERING_CAMP_ACTIVITY',
  DISCOVER_OFFERING_CLASS_ACTIVITY = 'DISCOVER_OFFERING_CLASS_ACTIVITY',
  DISCOVER_OFFERING_COURSE_ACTIVITY = 'DISCOVER_OFFERING_COURSE_ACTIVITY',
  DISCOVER_OFFERING_EDUCATION_ACTIVITY = 'DISCOVER_OFFERING_EDUCATION_ACTIVITY',
  DISCOVER_OFFERING_EVENT_ACTIVITY = 'DISCOVER_OFFERING_EVENT_ACTIVITY',
  DISCOVER_OFFERING_LIVESTREAM = 'DISCOVER_OFFERING_LIVESTREAM',
  DISCOVER_OFFERING_OPENTRAINING_ACTIVITY = 'DISCOVER_OFFERING_OPENTRAINING_ACTIVITY',
  DISCOVER_OFFERING_PUBLIC_MATCH = 'DISCOVER_OFFERING_PUBLIC_MATCH',
  DISCOVER_OFFERING_RETREAT_ACTIVITY = 'DISCOVER_OFFERING_RETREAT_ACTIVITY',
  DISCOVER_OFFERING_TRAINING_ACTIVITY = 'DISCOVER_OFFERING_TRAINING_ACTIVITY',
  DISCOVER_OFFERING_VIDEO = 'DISCOVER_OFFERING_VIDEO',
  DISCOVER_OFFERING_WORKSHOP_ACTIVITY = 'DISCOVER_OFFERING_WORKSHOP_ACTIVITY'
}

export type DiscoverVenueContext = {
  boundingBox: BoundingBox;
  center: Coordinate;
  city: Maybe<City>;
  content: Maybe<DiscoverContent>;
  meta: Maybe<MetaData>;
  onlineVenues: VenueConnection;
  sport: Maybe<Sport>;
};

/** This has nothing to do with actual booking stuff and is mostly legacy leftovers */
export type DisplayLimit = {
  __typename: 'DisplayLimit';
  limit: Maybe<Scalars['Int']['output']>;
  period: Maybe<DisplayLimitPeriod>;
  unit: Maybe<Scalars['String']['output']>;
};

export enum DisplayLimitPeriod {
  DISPLAY_LIMIT_PERIOD_DAY = 'DISPLAY_LIMIT_PERIOD_DAY',
  DISPLAY_LIMIT_PERIOD_MONTH = 'DISPLAY_LIMIT_PERIOD_MONTH',
  DISPLAY_LIMIT_PERIOD_SEASON = 'DISPLAY_LIMIT_PERIOD_SEASON',
  DISPLAY_LIMIT_PERIOD_WEEK = 'DISPLAY_LIMIT_PERIOD_WEEK',
  DISPLAY_LIMIT_PERIOD_YEAR = 'DISPLAY_LIMIT_PERIOD_YEAR'
}

export type District = {
  __typename: 'District';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type EventBookableItem = {
  __typename: 'EventBookableItem';
  activityImage: Maybe<Image>;
  activityName: Scalars['String']['output'];
  autoCancelCondition: Maybe<Scalars['String']['output']>;
  availableSpots: Maybe<SpotLimit>;
  cancellationCondition: Scalars['String']['output'];
  /** The point in time until which this event can be cancelled. Nullable for ticket events, where the cancellation deadline will be relative to the actual ticket/sessions you buy. */
  cancellationDeadline: Maybe<Scalars['DateTime']['output']>;
  /** A free text by the venue describing their policy. Note that what actually counts is the cancellationDeadline */
  cancellationDescription: Maybe<FormattedText>;
  /** A free text description of the event */
  description: Maybe<FormattedText>;
  eventSessions: Array<EventSession>;
  /** Determines the type of this event */
  eventType: EventType;
  id: Scalars['ID']['output'];
  images: Array<Image>;
  isUserOnWaitingList: Scalars['Boolean']['output'];
  /** The level */
  level: Maybe<EventDifficultyLevel>;
  location: Location;
  locations: Array<Location>;
  participatingFriends: FriendConnection;
  purchaseOptions: Array<PurchaseOption>;
  /** whether or not this item is bookable. May change depending on the logged in state of the user. E.g. certain thing like ALREADY_BOOKED can only be validated for a particular user. */
  registrationState: EventRegistrationState;
  /** Determines how people register for this event */
  registrationType: EventRegistrationType;
  /** The window within which this event is bookable */
  registrationWindow: OpenTimeRange;
  /** The current state relative to registration window */
  registrationWindowState: EventRegistrationWindowState;
  sessionComment: Maybe<SessionComment>;
  shortId: Scalars['String']['output'];
  sport: Sport;
  /**
   * Delivers the trainer for the eventsessions
   * @deprecated Use trainers field instead as list fields should use plural naming.
   */
  trainer: Maybe<Array<Trainer>>;
  trainerLabel: Scalars['String']['output'];
  /** Get the trainers for the bookable items */
  trainers: Array<Trainer>;
  venue: Venue;
};


export type EventBookableItemParticipatingFriendsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type EventBookableItemConnection = {
  __typename: 'EventBookableItemConnection';
  edges: Array<EventBookableItemEdge>;
  /** The actual "EventBookableItem" items */
  nodes: Array<EventBookableItem>;
  pageInfo: PageInfo;
};

export type EventBookableItemEdge = {
  __typename: 'EventBookableItemEdge';
  cursor: Scalars['Cursor']['output'];
  node: EventBookableItem;
};

export type EventBooking = BookingInterface & {
  __typename: 'EventBooking';
  /** @deprecated Use bookableItemV2 instead, which returns the entire bookable item, with all sessions, rather than just the ones included in your booking. If you want just the sessions included in your booking use sessions instead */
  bookableItem: EventBookableItem;
  /** return the full bookableItem, including all session of the bookable item, not just the ones included in the booking. */
  bookableItemV2: EventBookableItem;
  id: Scalars['ID']['output'];
  purchasedWith: Maybe<PurchasedWith>;
  /** The sessions included in this booking */
  sessions: Array<EventSession>;
  timeRange: TimeRange;
};

export enum EventDifficultyLevel {
  ALL = 'ALL',
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
  PROFESSIONAL = 'PROFESSIONAL'
}

export type EventFilters = {
  activityLocationType?: InputMaybe<Array<ActivityLocationType>>;
  difficultyLevel?: InputMaybe<Array<EventDifficultyLevel>>;
  eventTypes?: InputMaybe<Array<EventType>>;
  eventVisibility?: InputMaybe<Array<EventVisibility>>;
  timeRange?: InputMaybe<OpenEndTimeRangeInput>;
};

export type EventRegistrationState = {
  __typename: 'EventRegistrationState';
  bookable: Scalars['Boolean']['output'];
  validationProblems: Array<EventRegistrationValidationProblem>;
};

/**
 * Determines how customers are registering for this Event.
 *     - "SingleSession" means registrations are always made for only exactly 1 session at a time
 *     - "AllSessions" means registrations are always made for *all* the sessions in the event at once
 *     - "Tickets" means that and entity called _Ticket_ defines specific subsets of 1, some or all sessions and registration are made for these predefined subsets of Sessions
 */
export enum EventRegistrationType {
  ALL_SESSIONS = 'ALL_SESSIONS',
  SINGLE_SESSION = 'SINGLE_SESSION',
  TICKETS = 'TICKETS'
}

/** A reason why an eventbookable item cannot be booked */
export enum EventRegistrationValidationProblem {
  ALREADY_REGISTERED = 'ALREADY_REGISTERED',
  CANCELLED = 'CANCELLED',
  CUSTOMERGROUP_RESTRICTED = 'CUSTOMERGROUP_RESTRICTED',
  FULLY_BOOKED = 'FULLY_BOOKED',
  INVALID_EVENT = 'INVALID_EVENT',
  IN_PAST = 'IN_PAST',
  MAX_FUTURE_BOOKINGS = 'MAX_FUTURE_BOOKINGS',
  NOT_ACTIVE = 'NOT_ACTIVE',
  NOT_BOOKABLE = 'NOT_BOOKABLE',
  REGISTRATION_WINDOW = 'REGISTRATION_WINDOW'
}

/** Indicates whether this event is currently open for booking, not yet open or no longer open. Note that "open" does not automatically mean it is actually bookable. Other factors such as availability also need to be considered independently. (see _registrationState_) */
export enum EventRegistrationWindowState {
  CLOSED = 'CLOSED',
  NOT_YET_OPEN = 'NOT_YET_OPEN',
  OPEN = 'OPEN'
}

export type EventSession = {
  __typename: 'EventSession';
  availableSpots: SpotLimit;
  court: Maybe<Court>;
  end: Scalars['DateTime']['output'];
  event: EventBookableItem;
  hasOnlineStream: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  image: Maybe<Image>;
  location: Maybe<Location>;
  name: Scalars['String']['output'];
  onlineStream: Maybe<OnlineStream>;
  shortId: Scalars['ID']['output'];
  start: Scalars['DateTime']['output'];
  trainer: Maybe<Trainer>;
};

export type EventSessionConnection = {
  __typename: 'EventSessionConnection';
  edges: Array<EventSessionEdge>;
  /** The actual "EventSession" items */
  nodes: Array<EventSession>;
  pageInfo: PageInfo;
};

export type EventSessionEdge = {
  __typename: 'EventSessionEdge';
  cursor: Scalars['Cursor']['output'];
  node: EventSession;
};

export enum EventType {
  CAMP = 'Camp',
  CLASS = 'Class',
  COURSE = 'Course',
  EDUCATION = 'Education',
  EVENT = 'Event',
  OPENTRAINING = 'OpenTraining',
  RETREAT = 'Retreat',
  TRAINING = 'Training',
  WORKSHOP = 'Workshop'
}

export enum EventVisibility {
  BOOKABLE = 'BOOKABLE',
  DISPLAY = 'DISPLAY',
  HIDE = 'HIDE'
}

export type ExpectedError = {
  __typename: 'ExpectedError';
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  path: Maybe<Scalars['String']['output']>;
};

export type ExpectedErrors = {
  __typename: 'ExpectedErrors';
  errors: Array<ExpectedError>;
};

export type ExternalLoginInput = {
  accessToken: Scalars['String']['input'];
  additionalProfileInformation?: InputMaybe<AdditionalProfileInput>;
  provider: ExternalLoginProvider;
};

export enum ExternalLoginProvider {
  EXTERNAL_LOGIN_PROVIDER_APPLE = 'EXTERNAL_LOGIN_PROVIDER_APPLE',
  EXTERNAL_LOGIN_PROVIDER_FACEBOOK = 'EXTERNAL_LOGIN_PROVIDER_FACEBOOK',
  EXTERNAL_LOGIN_PROVIDER_GOOGLE = 'EXTERNAL_LOGIN_PROVIDER_GOOGLE'
}

export type ExternalTrackingIds = {
  __typename: 'ExternalTrackingIds';
  googleAnalyticsId: Maybe<Scalars['String']['output']>;
};

export type Family = {
  __typename: 'Family';
  id: Scalars['ID']['output'];
  members: Array<User>;
  owner: User;
};

export type FamilyResponse = ExpectedErrors | Family;

export enum FamilyRole {
  FAMILY_ROLE_MEMBER = 'FAMILY_ROLE_MEMBER',
  FAMILY_ROLE_OWNER = 'FAMILY_ROLE_OWNER'
}

export type FavoriteVenue = {
  __typename: 'FavoriteVenue';
  frequencyDescription: Maybe<Scalars['String']['output']>;
  venue: Venue;
};

export type FavoriteVenueConnection = {
  __typename: 'FavoriteVenueConnection';
  edges: Array<FavoriteVenueEdge>;
  /** The actual "FavoriteVenue" items */
  nodes: Array<FavoriteVenue>;
  pageInfo: PageInfo;
};

export type FavoriteVenueEdge = {
  __typename: 'FavoriteVenueEdge';
  cursor: Scalars['Cursor']['output'];
  node: FavoriteVenue;
};

export type FeaturePreview = {
  __typename: 'FeaturePreview';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isPublic: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  on: Scalars['Boolean']['output'];
  rolloutPercentage: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** A freetext possibly containing markup like lists, paragraphs, emphasis etc. */
export type FormattedText = {
  __typename: 'FormattedText';
  /** An HTML representation of the description containing semantic HTML tags like <p>, <li> or <b> and HTML entities like &amp; to represent a & sign. */
  html: Scalars['String']['output'];
  /** A plain text representation of the formatted text using plain text characters like e.g. newline (`\n`) to replace a paragraph. */
  plainText: Scalars['String']['output'];
};

export type Friend = {
  __typename: 'Friend';
  id: Scalars['ID']['output'];
  publicUser: PublicUserV2;
  since: Scalars['DateTime']['output'];
};

export type FriendConnection = {
  __typename: 'FriendConnection';
  edges: Array<FriendEdge>;
  /** The actual "Friend" items */
  nodes: Array<Friend>;
  pageInfo: PageInfo;
};

export type FriendEdge = {
  __typename: 'FriendEdge';
  cursor: Scalars['Cursor']['output'];
  node: Friend;
};

export type FriendRequest = {
  __typename: 'FriendRequest';
  from: PublicUserV2;
  id: Scalars['ID']['output'];
  requestedAt: Scalars['DateTime']['output'];
  to: PublicUserV2;
};

export type FriendRequestConnection = {
  __typename: 'FriendRequestConnection';
  edges: Array<FriendRequestEdge>;
  /** The actual "FriendRequest" items */
  nodes: Array<FriendRequest>;
  pageInfo: PageInfo;
};

export type FriendRequestEdge = {
  __typename: 'FriendRequestEdge';
  cursor: Scalars['Cursor']['output'];
  node: FriendRequest;
};

export enum Gender {
  GENDER_FEMALE = 'GENDER_FEMALE',
  GENDER_MALE = 'GENDER_MALE',
  GENDER_OTHER = 'GENDER_OTHER'
}

/** Temporary type for generic stuff that can be on an order but is not implemented yet */
export type GenericAssignedProduct = {
  __typename: 'GenericAssignedProduct';
  id: Scalars['ID']['output'];
};

export type GeoSearchByRadiusAndCoordinate = {
  coordinate: CoordinateArgs;
  /** Radius in meter */
  radius: Scalars['Float']['input'];
};

export type Image = {
  __typename: 'Image';
  large: Scalars['String']['output'];
  listBasic: Scalars['String']['output'];
  listPremium: Scalars['String']['output'];
  medium: Scalars['String']['output'];
  small: Scalars['String']['output'];
  xLarge: Scalars['String']['output'];
  xSmall: Scalars['String']['output'];
};

export type IntegerValueQuery = {
  operator: ComparisonOperator;
  value: Scalars['Int']['input'];
};

export type Interval = {
  __typename: 'Interval';
  type: IntervalUnit;
  value: Scalars['Int']['output'];
};

export enum IntervalUnit {
  INTERVAL_UNIT_DAYS = 'INTERVAL_UNIT_DAYS',
  INTERVAL_UNIT_HOURS = 'INTERVAL_UNIT_HOURS',
  INTERVAL_UNIT_MONTHS = 'INTERVAL_UNIT_MONTHS',
  INTERVAL_UNIT_WEEKS = 'INTERVAL_UNIT_WEEKS',
  INTERVAL_UNIT_YEARS = 'INTERVAL_UNIT_YEARS'
}

export type InventorySearchResult = SportSearchResult | VenueSearchResult;

export type Invitation = {
  __typename: 'Invitation';
  customers: Array<Customer>;
  invitedBy: InvitedBy;
  isEversportsUser: Scalars['Boolean']['output'];
  showAppPromotion: Scalars['Boolean']['output'];
  state: InvitationState;
  user: User;
  /** @deprecated Deprecated in favor of customer.customerGroup.company */
  venue: Maybe<Venue>;
};

export enum InvitationState {
  INVITATION_STATE_ACCEPTED = 'INVITATION_STATE_ACCEPTED',
  INVITATION_STATE_ACTIVE = 'INVITATION_STATE_ACTIVE',
  INVITATION_STATE_EXPIRED = 'INVITATION_STATE_EXPIRED'
}

/** It will return a User if the invitation was created by a family owner removing a family member and inviting it to eversports, or a Venue otherwise */
export type InvitedBy = User | Venue;

export type Invoice = {
  __typename: 'Invoice';
  amount: MonetaryValue;
  downloadLink: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** @deprecated Use state field to know if invoice is fully paid or not */
  isFullyPaid: Scalars['Boolean']['output'];
  issueDate: Maybe<Scalars['DateTime']['output']>;
  number: Scalars['String']['output'];
  state: InvoiceState;
  usedPaymentServices: Array<PaymentServiceType>;
};

/**
 *
 *   - INVOICE_STATE_FULLY_PAID: Fully paid invoice
 *   - INVOICE_STATE_OPEN: Not fully paid
 *   - INVOICE_STATE_CANCELLED: Cancelled invoice
 *   - INVOICE_STATE_REFUND: refund invoice for a cancelled invoice
 *
 */
export enum InvoiceState {
  INVOICE_STATE_CANCELLED = 'INVOICE_STATE_CANCELLED',
  INVOICE_STATE_FULLY_PAID = 'INVOICE_STATE_FULLY_PAID',
  INVOICE_STATE_OPEN = 'INVOICE_STATE_OPEN',
  INVOICE_STATE_REFUND = 'INVOICE_STATE_REFUND'
}

export enum JoinMatchAnswer {
  NO = 'NO',
  YES = 'YES'
}

export type JoinMatchV2 = BallsportMatch | ExpectedErrors;

export type LeaveMatchV2 = BallsportMatch | ExpectedErrors;

/** A limit of how much Budget is available */
export type Limited = {
  __typename: 'Limited';
  /** If `true` means the above `value` was capped for non-disclosure reasons. I.e. the actual display might be larger than the displayed sum. Hence the client should display the value as "more than x" */
  displayCapped: Scalars['Boolean']['output'];
  limit: MonetaryValue;
  type: Scalars['String']['output'];
};

/** A limit of spots */
export type LimitedSpots = {
  __typename: 'LimitedSpots';
  /** If `true` means the above `limit` was capped for non-disclosure reasons. I.e. the actual available spots might be larger than the displayed amount. Hence the client should display the value as "more than x" */
  displayCapped: Scalars['Boolean']['output'];
  /** The number of free spots. It might be capped in case `displayCapped` equals `true` */
  limit: Scalars['Int']['output'];
  /** The number of booked spots */
  occupied: Scalars['Int']['output'];
  /** Maximum number of bookable spots */
  total: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type Location = {
  __typename: 'Location';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  /** This field should include information for the house number, street number or door number */
  detail: Scalars['String']['output'];
  /** Note this field is NULLABLE. In some cases like a venue's location we don't have this info. If indoor is explicitly "false", the location is outdoor. */
  indoor: Maybe<Scalars['Boolean']['output']>;
  latitude: Maybe<Scalars['Float']['output']>;
  longitude: Maybe<Scalars['Float']['output']>;
  street: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type LocationInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  /** This field should include information for the house number, street number or door number */
  detail: Scalars['String']['input'];
  /** Note this field is NULLABLE. In some cases like a venue's location we don't have this info. If indoor is explicitly "false", the location is outdoor. */
  indoor?: InputMaybe<Scalars['Boolean']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  street: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type Mandate = {
  __typename: 'Mandate';
  bankAccount: StoredBankAccount;
  isVerified: Scalars['Boolean']['output'];
};

export type Match = BallsportMatch;

export type MatchComment = {
  __typename: 'MatchComment';
  commentator: MatchCommentator;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
};

export type MatchCommentConnection = {
  __typename: 'MatchCommentConnection';
  edges: Array<MatchCommentEdge>;
  /** The actual "MatchComment" items */
  nodes: Array<MatchComment>;
  pageInfo: PageInfo;
};

export type MatchCommentEdge = {
  __typename: 'MatchCommentEdge';
  cursor: Scalars['Cursor']['output'];
  node: MatchComment;
};

export type MatchCommentator = {
  __typename: 'MatchCommentator';
  name: Scalars['String']['output'];
  profilePicture: Maybe<Image>;
  userId: Scalars['ID']['output'];
};

export enum MatchCompetitionType {
  COMPETITIVE = 'COMPETITIVE',
  FRIENDLY = 'FRIENDLY'
}

export type MatchFilters = {
  competitionTypes?: InputMaybe<Array<MatchCompetitionType>>;
  genders?: InputMaybe<Array<MatchGender>>;
  /** Geo search of matches by radius around a coordinate */
  geoSearch?: InputMaybe<GeoSearchByRadiusAndCoordinate>;
  /** If set to `true`, it will filter for matches with open spots. `false` and `undefined` will not filter the result */
  hasOpenSpots?: InputMaybe<Scalars['Boolean']['input']>;
  levels?: InputMaybe<Array<MatchLevel>>;
  sportIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  timeRange?: InputMaybe<OpenEndTimeRangeInput>;
  venueId?: InputMaybe<Scalars['ID']['input']>;
  venueIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  visibility?: InputMaybe<MatchVisibility>;
};

export enum MatchGender {
  FEMALE_ONLY = 'FEMALE_ONLY',
  MALE_ONLY = 'MALE_ONLY',
  UNISEX = 'UNISEX'
}

export type MatchInterface = {
  comments: MatchCommentConnection;
  competitionType: Maybe<MatchCompetitionType>;
  end: Scalars['DateTime']['output'];
  gender: Maybe<MatchGender>;
  id: Scalars['ID']['output'];
  level: Maybe<MatchLevel>;
  matchVisibilityValidations: Array<MatchVisibilityValidation>;
  maxParticipants: Maybe<Scalars['Int']['output']>;
  minParticipants: Maybe<Scalars['Int']['output']>;
  /** @deprecated Deprecated in favour of organizerV2 */
  organizer: User;
  organizerNote: Maybe<Scalars['String']['output']>;
  organizerV2: MatchOrganizerType;
  participants: Array<MatchParticipant>;
  relativeLink: Scalars['String']['output'];
  sport: Sport;
  start: Scalars['DateTime']['output'];
  venue: Venue;
  visibility: MatchVisibility;
};


export type MatchInterfaceCommentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum MatchLevel {
  ADVANCED = 'ADVANCED',
  ANY = 'ANY',
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE'
}

export enum MatchMissingField {
  COMPETITION_TYPE = 'COMPETITION_TYPE',
  GENDER = 'GENDER',
  LEVEL = 'LEVEL',
  MAX_PARTICIPANTS = 'MAX_PARTICIPANTS'
}

export type MatchOrganizerInterface = {
  bio: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  handle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  profilePicture: Maybe<Image>;
};

/** Possible types of an organizer, depending on the logged in user */
export type MatchOrganizerType = PrivateMatchOrganizer | PublicMatchOrganizer;

export type MatchParticipant = {
  __typename: 'MatchParticipant';
  answer: MatchParticipantAnswer;
  id: Scalars['ID']['output'];
  /** @deprecated Deprecated in favour of user field */
  identity: MatchParticipantIdentity;
  /** @deprecated This is just there to avoid clients to break. Must be removed when all clients remove the dependency. always empty string */
  secretId: Scalars['String']['output'];
  user: MatchParticipantUserType;
};

export enum MatchParticipantAnswer {
  NO = 'NO',
  YES = 'YES'
}

export type MatchParticipantIdentity = PublicUser | User;

export type MatchParticipantInput = {
  answer: MatchParticipantAnswer;
  /** Has value when participant is an anonymous user. Is exclusive with "userId" propery. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Has value when participant is a member of eversports. Is exclusive with "name" propery. */
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MatchParticipantUserType = PublicUserV2 | User;

export type MatchUpdateFields = {
  competitionType?: InputMaybe<MatchCompetitionType>;
  gender?: InputMaybe<MatchGender>;
  level?: InputMaybe<MatchLevel>;
  maxParticipants?: InputMaybe<Scalars['Float']['input']>;
  minParticipants?: InputMaybe<Scalars['Float']['input']>;
  organizerNote?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<MatchVisibility>;
};

export enum MatchVisibility {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export type MatchVisibilityValidation = PrivateMatchVisibilityValidation | PublicMatchVisibilityValidation;

export type MatchVisibilityValidationInterface = {
  missingFields: Array<MatchMissingField>;
  requiredFields: Array<MatchMissingField>;
};

export type Member = {
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
};

/**
 * Membership, for example "Monthly flex"
 *
 * A membership is characterized by having a runtime instead of a single
 * validity period, and a payment plan instead of a one-time price.
 *
 * - _Can_ define _repeating_ `UsageLimit`s.
 */
export type Membership = ProductInterface & {
  __typename: 'Membership';
  /** List of activities for which this Membership can be used. */
  activities: Array<Activity>;
  conditions: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** The partial payment information if the membership is bought today. */
  partialPriceInformation: Maybe<PartialPriceInformation>;
  paymentAndCancellationTerms: Maybe<Scalars['String']['output']>;
  paymentPlan: PaymentPlan;
  productPurchaseInformation: ProductPurchaseInformation;
  runtime: Runtime;
  usages: Array<UsageLimit>;
  /** List of venues at which the Membership can be used. */
  venues: Array<Venue>;
};

export type MembershipPeriod = {
  __typename: 'MembershipPeriod';
  id: Scalars['ID']['output'];
  invoices: Array<Invoice>;
};

export type MetaData = {
  __typename: 'MetaData';
  /** meta description of the html document. should be used within the meta description tag */
  description: Maybe<Scalars['String']['output']>;
  /** if true, the page should be indexed by search engines */
  index: Maybe<Scalars['Boolean']['output']>;
  /** data relevant for social media */
  social: Social;
  /** title of the html document. should be used within the title tag */
  title: Scalars['String']['output'];
};

/**
 * This type represents any kind of monetary value, such as price or balance.
 * It is a combination of a `value` in cents, and a `currency` as an
 * [ISO 4217 Currency Code](https://www.xe.com/iso4217.php).
 *
 * ### Example
 *
 * A price of `1, 99 €` will be represented as
 *
 * ```
 * {
 *     value: 199,
 *     currency: "EUR"
 *   }
 * ```
 */
export type MonetaryValue = {
  __typename: 'MonetaryValue';
  currency: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type MultiPlayerSettings = {
  __typename: 'MultiPlayerSettings';
  /** Maximum number of players that can be added to the booking. This includes the user themselves */
  maxPlayers: Scalars['Int']['output'];
  /** Minimum number of players required for the booking. This includes the user themselves */
  minPlayers: Scalars['Int']['output'];
  /** If true, other players must be named for this booking */
  required: Scalars['Boolean']['output'];
};

export type MultiPlayerUser = {
  __typename: 'MultiPlayerUser';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Mutation = {
  __typename: 'Mutation';
  /** Abort an open order */
  abortOrder: OrderResponse;
  acceptEversportsPolicies: AuthResponse;
  /** Accept a friend request, returns the `PublicUserV2` of the requested user */
  acceptFriendRequest: FriendRequest;
  acceptInvitation: AuthResponse;
  acceptVenuePolicies: AuthResponse;
  addFamilyMember: UserResponseType;
  addMatchComment: AddMatch;
  /** Adds the id of a multiplayer user to a bookableItem cartItem */
  addMultiplayerUserToBookableItemCartItem: CartResponse;
  addNetPromoterScore: Scalars['Boolean']['output'];
  /**
   * add a participant to a match
   * @deprecated Return type is not specific to mutation. Use addParticipantOnMatchV2
   */
  addParticipantOnMatch: MatchParticipant;
  /** add a participant to a match */
  addParticipantOnMatchV2: AddParticipantOnMatchV2;
  addToWaitingList: AddToWaitingListResponse;
  addVenueToFavourites: Venue;
  /** Cancel booking */
  cancelBooking: Scalars['Boolean']['output'];
  cancelFriendRequest: FriendRequest;
  /** cancel a match (by organizer) */
  cancelMatch: CancelMatch;
  changePassword: AuthResponse;
  /** Confirm an open order */
  confirmOrder: OrderResponse;
  /**
   * Confirm a payment
   * @deprecated Deprecated in favor of `confirmPaymentWithPaymentMethod`
   */
  confirmPayment: PaymentResponseType;
  /** Confirm a payment */
  confirmPaymentWithPaymentMethod: PaymentResponseType;
  /** Creates a new cart for booking an court bookable item */
  createCartFromCourtBookableItem: CartResponse;
  /** Creates a new cart for booking an event bookable item */
  createCartFromEventBookableItem: CartResponse;
  /** Creates a new cart for a shop product */
  createCartFromProduct: CartResponse;
  /** Creates a new cart for a voucher */
  createCartFromVoucher: CartResponse;
  /** Creates a court booking. Called from the marketplace etc. */
  createCourtBookableItem: Scalars['String']['output'];
  createFamily: FamilyResponse;
  /** create a match from a booking (by the person who did the booking) */
  createMatchFromBooking: CreatedBallsportMatch;
  /** Create an order from a given cart. May result in an order with a `CONFIRMED` or an `OPEN` state, based on whether a form of payment is necessary or not */
  createOrderFromCart: OrderResponse;
  createPrivateFeedback: PrivateFeedbackResponse;
  credentialLogin: AuthResponse;
  credentialRegistration: AuthResponse;
  declineFriendRequest: FriendRequest;
  deleteMatchComment: DeleteMatch;
  dismissNetPromoterScore: Scalars['Boolean']['output'];
  dismissNetPromoterScoreV2: Scalars['Boolean']['output'];
  endFriendship: PublicUserV2;
  externalLogin: AuthResponse;
  familyLogin: AuthResponse;
  inviteFamilyMemberAsEversportsUser: UserResponseType;
  /**
   * add current logged-in user to a given match participants
   * @deprecated Return type is not specific to mutation. Use joinMatchV2
   */
  joinMatch: Match;
  /** add current logged-in user to a given match participants */
  joinMatchV2: JoinMatchV2;
  /**
   * remove current logged-in user from the list of participants of a given match
   * @deprecated Return type is not specific to mutation. Use leaveMatchV2
   */
  leaveMatch: Match;
  /** remove current logged-in user from the list of participants of a given match */
  leaveMatchV2: LeaveMatchV2;
  logout: Scalars['Boolean']['output'];
  markAllNotificationsAsSeen: Scalars['Boolean']['output'];
  maskerade: AuthResponse;
  /** Setup a payment intent for a payment */
  preparePayment: PaymentResponseType;
  removeFamilyMember: UserResponseType;
  removeFromWaitingList: RemoveFromWaitingListResponse;
  /** Removes the id of a multiplayer user from a bookableItem cartItem */
  removeMultiplayerUserFromBookableItemCartItem: CartResponse;
  /**
   * remove some participants from a match
   * @deprecated Return type is not specific to mutation. Use removeParticipantsFromMatchV2
   */
  removeParticipantsFromMatch: Match;
  /** remove some participants from a match */
  removeParticipantsFromMatchV2: RemoveParticipantsFromMatchV2;
  /** Remove a `PaymentMethod` from user stored payment methods */
  removePaymentMethod: StoredPaymentMethodResponse;
  removeVenueFromFavourites: Venue;
  /** Request a friendship, returns the `PublicUserV2` of the requested user */
  requestFriendship: FriendRequest;
  requestPasswordReset: ResetPasswordResponse;
  requestVerificationEmail: RequestVerificationResponse;
  resetPassword: AuthResponse;
  seenNotifications: Scalars['Boolean']['output'];
  /** Set add-on for a bookable item cart item */
  setAddOnsForBookableItemCartItem: CartResponse;
  /** Set the number of guest players for multiplayer booking */
  setCommentOnBookableItemCartItem: CartResponse;
  /** Sets the answer to a multi answer patron question for the given cart */
  setMultiAnswerPatronQuestionOnCart: CartResponse;
  /** Set the number of guest players for multiplayer booking */
  setNumberOfGuestsOnBookableItemCartItem: CartResponse;
  /**
   * set organizer note of the match (by organizer)
   * @deprecated use updateMatch mutation instead
   */
  setOrganizerNoteOnMatch: Match;
  /** Sets the patron question for the given cart */
  setPatronQuestionOnCart: CartResponse;
  /** Sets the given policies as accepted, which policies are necessary is given in the field `cart.policies`. */
  setPoliciesOnCart: CartResponse;
  /**
   * Set selected product for a bookable item cart item
   * @deprecated Use `setProductForCartItem` instead, which is consistent for product and bookableitem checkout
   */
  setProductForBookableItemCartItem: CartResponse;
  /** Set selected product for a product or bookableitem cart item */
  setProductForCartItem: CartResponse;
  /** Set eventually necessary missing/required information on a cart */
  setRequiredInformationOnCart: CartResponse;
  /**
   * Sets whether to use or not use a potential corporate Budget on this Cart
   * @deprecated Use "setUseCorporateBenefitsOnCart" to opt in and out of both discounts and budget at once.
   */
  setUseBudgetOnCart: CartResponse;
  /** Sets whether to use or not use any potential corporate Budget or Corporate Discounts on this Cart */
  setUseCorporateBenefitsOnCart: CartResponse;
  /** Adds or deletes a voucher from the cart */
  setVoucherOnCart: CartResponse;
  /** Set voucher on an order */
  setVoucherOnOrder: OrderResponse;
  /** Prepare a payment for recurring payments */
  setupRecurringPaymentMethod: PaymentResponseType;
  shareEvent: Scalars['Boolean']['output'];
  shareMatch: Scalars['Boolean']['output'];
  shareVenue: Scalars['Boolean']['output'];
  startGeneratingInvoiceDownloadLink: Scalars['String']['output'];
  /** Store a `PaymentMethod` for the currently logged in user. */
  storePaymentMethod: StoredPaymentMethodResponse;
  /** Subscribe the customer to the newsletter. */
  subscribeToNewsletter: Customer;
  /** Track the completion of a new checkout for a certain device and session id */
  trackCheckoutComplete: Scalars['Boolean']['output'];
  /** Track the start of a new checkout for a certain device and session id */
  trackCheckoutStart: Scalars['Boolean']['output'];
  /** Update a subset of match properties */
  updateMatch: UpdateMatch;
  updatePrivacySettings: Array<PrivacySetting>;
  verifyEmail: AuthResponse;
};


export type MutationAbortOrderArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationAcceptFriendRequestArgs = {
  userId: Scalars['String']['input'];
};


export type MutationAcceptInvitationArgs = {
  password?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};


export type MutationAcceptVenuePoliciesArgs = {
  venueId: Scalars['ID']['input'];
};


export type MutationAddFamilyMemberArgs = {
  member: Member;
};


export type MutationAddMatchCommentArgs = {
  matchId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};


export type MutationAddMultiplayerUserToBookableItemCartItemArgs = {
  cartId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationAddNetPromoterScoreArgs = {
  channel: NetPromoterScoreChannel;
  comment?: InputMaybe<Scalars['String']['input']>;
  score: Scalars['Int']['input'];
};


export type MutationAddParticipantOnMatchArgs = {
  answer: AddParticipantToMatchAnswer;
  matchId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationAddParticipantOnMatchV2Args = {
  answer: AddParticipantToMatchAnswer;
  matchId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationAddToWaitingListArgs = {
  eventBookableItemId: Scalars['ID']['input'];
};


export type MutationAddVenueToFavouritesArgs = {
  venueId: Scalars['String']['input'];
};


export type MutationCancelBookingArgs = {
  id: Scalars['ID']['input'];
  isLateCancellation?: InputMaybe<Scalars['Boolean']['input']>;
  origin: Origin;
};


export type MutationCancelFriendRequestArgs = {
  userId: Scalars['String']['input'];
};


export type MutationCancelMatchArgs = {
  matchId: Scalars['ID']['input'];
  origin: Origin;
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};


export type MutationConfirmOrderArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationConfirmPaymentArgs = {
  bankAccountId: Scalars['String']['input'];
  paymentId: Scalars['String']['input'];
};


export type MutationConfirmPaymentWithPaymentMethodArgs = {
  paymentId: Scalars['ID']['input'];
  pspPaymentMethodId: Scalars['String']['input'];
};


export type MutationCreateCartFromCourtBookableItemArgs = {
  courtId: Scalars['ID']['input'];
  device?: InputMaybe<Scalars['String']['input']>;
  end: Scalars['String']['input'];
  origin: Origin;
  sportId: Scalars['ID']['input'];
  start: Scalars['String']['input'];
};


export type MutationCreateCartFromEventBookableItemArgs = {
  bookableItemId: Scalars['ID']['input'];
  device?: InputMaybe<Scalars['String']['input']>;
  origin: Origin;
};


export type MutationCreateCartFromProductArgs = {
  device?: InputMaybe<Scalars['String']['input']>;
  origin: Origin;
  productId: Scalars['ID']['input'];
  venueId: Scalars['ID']['input'];
};


export type MutationCreateCartFromVoucherArgs = {
  amount: Scalars['Int']['input'];
  device?: InputMaybe<Scalars['String']['input']>;
  imageId: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  origin: Origin;
  venueId: Scalars['ID']['input'];
};


export type MutationCreateCourtBookableItemArgs = {
  courtId: Scalars['ID']['input'];
  sportId: Scalars['ID']['input'];
  timeRange: TimeRangeInput;
};


export type MutationCreateFamilyArgs = {
  members: Array<Member>;
};


export type MutationCreateMatchFromBookingArgs = {
  bookingId: Scalars['ID']['input'];
  minParticipants?: InputMaybe<Scalars['Int']['input']>;
  organizerNote?: InputMaybe<Scalars['String']['input']>;
  participants?: InputMaybe<Array<MatchParticipantInput>>;
};


export type MutationCreateOrderFromCartArgs = {
  cartId: Scalars['ID']['input'];
};


export type MutationCreatePrivateFeedbackArgs = {
  rating: Scalars['Float']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
  venueId: Scalars['ID']['input'];
};


export type MutationCredentialLoginArgs = {
  credentials: CredentialLoginInput;
  params: AuthParamsInput;
};


export type MutationCredentialRegistrationArgs = {
  params: AuthParamsInput;
  profile: RegistrationProfileInput;
  settings: RegistrationSettingsInput;
};


export type MutationDeclineFriendRequestArgs = {
  userId: Scalars['String']['input'];
};


export type MutationDeleteMatchCommentArgs = {
  commentId: Scalars['ID']['input'];
};


export type MutationDismissNetPromoterScoreV2Args = {
  channel: NetPromoterScoreChannel;
};


export type MutationEndFriendshipArgs = {
  userId: Scalars['String']['input'];
};


export type MutationExternalLoginArgs = {
  credentials: ExternalLoginInput;
  params: AuthParamsInput;
};


export type MutationFamilyLoginArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationInviteFamilyMemberAsEversportsUserArgs = {
  email: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationJoinMatchArgs = {
  answer: JoinMatchAnswer;
  matchId: Scalars['ID']['input'];
};


export type MutationJoinMatchV2Args = {
  answer: JoinMatchAnswer;
  matchId: Scalars['ID']['input'];
};


export type MutationLeaveMatchArgs = {
  matchId: Scalars['ID']['input'];
};


export type MutationLeaveMatchV2Args = {
  matchId: Scalars['ID']['input'];
};


export type MutationMaskeradeArgs = {
  email: Scalars['String']['input'];
  params: AuthParamsInput;
};


export type MutationPreparePaymentArgs = {
  paymentId: Scalars['ID']['input'];
  paymentMethodType: PaymentMethodType;
};


export type MutationRemoveFamilyMemberArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationRemoveFromWaitingListArgs = {
  eventBookableItemId: Scalars['ID']['input'];
};


export type MutationRemoveMultiplayerUserFromBookableItemCartItemArgs = {
  cartId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRemoveParticipantsFromMatchArgs = {
  matchId: Scalars['ID']['input'];
  matchParticipantIds: Array<Scalars['ID']['input']>;
};


export type MutationRemoveParticipantsFromMatchV2Args = {
  matchId: Scalars['ID']['input'];
  matchParticipantIds: Array<Scalars['ID']['input']>;
};


export type MutationRemovePaymentMethodArgs = {
  paymentMethodId: Scalars['ID']['input'];
};


export type MutationRemoveVenueFromFavouritesArgs = {
  venueId: Scalars['String']['input'];
};


export type MutationRequestFriendshipArgs = {
  userId: Scalars['String']['input'];
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['String']['input'];
  queryString?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
};


export type MutationResetPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSeenNotificationsArgs = {
  notificationIds: Array<Scalars['String']['input']>;
};


export type MutationSetAddOnsForBookableItemCartItemArgs = {
  addOns: Array<AddOnInputInput>;
  cartId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
};


export type MutationSetCommentOnBookableItemCartItemArgs = {
  cartId: Scalars['ID']['input'];
  comment: Scalars['String']['input'];
};


export type MutationSetMultiAnswerPatronQuestionOnCartArgs = {
  cartId: Scalars['ID']['input'];
  patronageAnswer: PatronageAnswer;
};


export type MutationSetNumberOfGuestsOnBookableItemCartItemArgs = {
  cartId: Scalars['ID']['input'];
  numberOfGuests: Scalars['Int']['input'];
};


export type MutationSetOrganizerNoteOnMatchArgs = {
  matchId: Scalars['ID']['input'];
  note: Scalars['String']['input'];
};


export type MutationSetPatronQuestionOnCartArgs = {
  cartId: Scalars['ID']['input'];
  isPatron: Scalars['Boolean']['input'];
};


export type MutationSetPoliciesOnCartArgs = {
  cartId: Scalars['ID']['input'];
  policies: Array<Scalars['ID']['input']>;
};


export type MutationSetProductForBookableItemCartItemArgs = {
  cartId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
};


export type MutationSetProductForCartItemArgs = {
  cartId: Scalars['ID']['input'];
  itemId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
};


export type MutationSetRequiredInformationOnCartArgs = {
  cartId: Scalars['ID']['input'];
  requiredInformation: RequiredInformationInput;
};


export type MutationSetUseBudgetOnCartArgs = {
  cartId: Scalars['ID']['input'];
  useBudget: Scalars['Boolean']['input'];
};


export type MutationSetUseCorporateBenefitsOnCartArgs = {
  cartId: Scalars['ID']['input'];
  useBenefits: Scalars['Boolean']['input'];
};


export type MutationSetVoucherOnCartArgs = {
  cartId: Scalars['ID']['input'];
  voucher?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetVoucherOnOrderArgs = {
  orderId: Scalars['ID']['input'];
  voucher?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetupRecurringPaymentMethodArgs = {
  paymentId: Scalars['ID']['input'];
};


export type MutationShareEventArgs = {
  eventId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};


export type MutationShareMatchArgs = {
  matchId: Scalars['ID']['input'];
  userIds: Array<Scalars['ID']['input']>;
};


export type MutationShareVenueArgs = {
  userIds: Array<Scalars['ID']['input']>;
  venueId: Scalars['ID']['input'];
};


export type MutationStartGeneratingInvoiceDownloadLinkArgs = {
  invoiceId: Scalars['ID']['input'];
};


export type MutationStorePaymentMethodArgs = {
  pspId: Scalars['ID']['input'];
};


export type MutationSubscribeToNewsletterArgs = {
  customerId: Scalars['ID']['input'];
};


export type MutationTrackCheckoutCompleteArgs = {
  deviceId: Scalars['String']['input'];
  orderId: Scalars['ID']['input'];
  sessionId: Scalars['Float']['input'];
  userTrackingId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationTrackCheckoutStartArgs = {
  cartId: Scalars['ID']['input'];
  deviceId: Scalars['String']['input'];
  sessionId: Scalars['Float']['input'];
  userTrackingId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateMatchArgs = {
  fields: MatchUpdateFields;
  matchId: Scalars['ID']['input'];
};


export type MutationUpdatePrivacySettingsArgs = {
  args: PrivacySettingsInput;
};


export type MutationVerifyEmailArgs = {
  token: Scalars['String']['input'];
};

export enum NetPromoterScoreChannel {
  CHANNEL_ANDROID = 'CHANNEL_ANDROID',
  CHANNEL_IOS = 'CHANNEL_IOS',
  CHANNEL_MARKETPLACE = 'CHANNEL_MARKETPLACE'
}

export type Notification = BaseNotification;

export type NotificationConnection = {
  __typename: 'NotificationConnection';
  edges: Array<NotificationEdge>;
  /** The actual "Notification" items */
  nodes: Array<Notification>;
  pageInfo: PageInfo;
};

export type NotificationEdge = {
  __typename: 'NotificationEdge';
  cursor: Scalars['Cursor']['output'];
  node: Notification;
};

export type NotificationIcon = NotificationIconImage | NotificationIconUser;

export type NotificationIconImage = {
  __typename: 'NotificationIconImage';
  image: Image;
};

export type NotificationIconUser = {
  __typename: 'NotificationIconUser';
  user: PublicUserV2;
};

export type NotificationInterface = {
  contentType: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  headerText: Maybe<Scalars['String']['output']>;
  icon: Maybe<NotificationIcon>;
  id: Scalars['ID']['output'];
  link: Maybe<NotificationLink>;
  seen: Scalars['Boolean']['output'];
  text: Maybe<Scalars['String']['output']>;
};

export type NotificationLink = NotificationLinkEvent | NotificationLinkEventSession | NotificationLinkMatch | NotificationLinkUrl | NotificationLinkUser | NotificationLinkVenue;

export type NotificationLinkEvent = {
  __typename: 'NotificationLinkEvent';
  event: EventBookableItem;
};

export type NotificationLinkEventSession = {
  __typename: 'NotificationLinkEventSession';
  bookableItem: EventBookableItem;
  event: NotificationLinkEventSessionEvent;
  eventSession: EventSession;
};

export type NotificationLinkEventSessionEvent = {
  __typename: 'NotificationLinkEventSessionEvent';
  eventType: EventType;
  id: Scalars['ID']['output'];
  shortId: Scalars['String']['output'];
};

export type NotificationLinkMatch = {
  __typename: 'NotificationLinkMatch';
  match: Match;
};

export type NotificationLinkUrl = {
  __typename: 'NotificationLinkUrl';
  url: Scalars['String']['output'];
};

export type NotificationLinkUser = {
  __typename: 'NotificationLinkUser';
  user: PublicUserV2;
};

export type NotificationLinkVenue = {
  __typename: 'NotificationLinkVenue';
  venue: Venue;
};

export type NumberAddOnField = {
  __typename: 'NumberAddOnField';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  price: Maybe<MonetaryValue>;
};

export type OnlineStream = {
  __typename: 'OnlineStream';
  end: Scalars['DateTime']['output'];
  info: Maybe<OnlineStreamInfo>;
  isWithinStreamingTime: Scalars['Boolean']['output'];
  link: Maybe<Scalars['String']['output']>;
  provider: Maybe<OnlineStreamProvider>;
  start: Scalars['DateTime']['output'];
};

export type OnlineStreamInfo = {
  __typename: 'OnlineStreamInfo';
  externalMeetingPassword: Scalars['Boolean']['output'];
  id: Scalars['Float']['output'];
  uuid: Scalars['String']['output'];
};

export type OnlineStreamProvider = {
  __typename: 'OnlineStreamProvider';
  id: Scalars['Float']['output'];
  key: OnlineStreamProviderPlatform;
  priority: Scalars['Float']['output'];
  showGDPR: Scalars['Boolean']['output'];
};

/** Online stream provider platform names */
export enum OnlineStreamProviderPlatform {
  HANGOUT = 'HANGOUT',
  INSTAGRAM = 'INSTAGRAM',
  OTHER = 'OTHER',
  SKYPE = 'SKYPE',
  YOUTUBE = 'YOUTUBE',
  YOUTUBE_LIVE = 'YOUTUBE_LIVE',
  ZOOM = 'ZOOM'
}

export type OpenEndTimeRangeInput = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start: Scalars['DateTime']['input'];
};

export type OpenGraph = SocialInterface & {
  __typename: 'OpenGraph';
  description: Maybe<Scalars['String']['output']>;
  image: Image;
  siteName: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type OpenTimeRange = {
  __typename: 'OpenTimeRange';
  end: Maybe<Scalars['DateTime']['output']>;
  start: Maybe<Scalars['DateTime']['output']>;
};

export type Order = {
  __typename: 'Order';
  amount: MonetaryValue;
  appliedVoucher: Maybe<AppliedVoucher>;
  /** The latest point in time an `Order` can be confirmed. */
  confirmableUntil: Maybe<Scalars['DateTime']['output']>;
  /** The creation `Date` of an `Order`. Can be used together with `confirmableUntil` to calculate the remaining time of confirming an `Order` */
  createdAt: Scalars['DateTime']['output'];
  /** Returns the customer associated with the order */
  customer: Maybe<Customer>;
  id: Scalars['ID']['output'];
  invoice: Maybe<Invoice>;
  isConfirmable: Scalars['Boolean']['output'];
  isSelfCheckinAvailable: Scalars['Boolean']['output'];
  /** `True` if vouchers are enabled for this order, otherwise `false` */
  isVoucherEnabled: Scalars['Boolean']['output'];
  items: Array<OrderItem>;
  payment: Maybe<Payment>;
  priceTable: PriceTable;
  state: OrderState;
  venue: Venue;
};

export type OrderItem = AssignedProductOrderItem | BookingOrderItem;

export type OrderItemInterface = {
  confirmedWith: Maybe<ConfirmWithOption>;
  endPrice: Scalars['Float']['output'];
  purchasedWith: Maybe<PurchasedWith>;
  reduction: Scalars['Float']['output'];
};

export type OrderResponse = ExpectedErrors | Order;

export enum OrderState {
  ORDER_STATE_ABORTED = 'ORDER_STATE_ABORTED',
  ORDER_STATE_CONFIRMED = 'ORDER_STATE_CONFIRMED',
  ORDER_STATE_OPEN = 'ORDER_STATE_OPEN'
}

export enum Origin {
  ORIGIN_ANDROID = 'ORIGIN_ANDROID',
  ORIGIN_IOS = 'ORIGIN_IOS',
  ORIGIN_MARKETPLACE = 'ORIGIN_MARKETPLACE',
  ORIGIN_PROVIDER_API = 'ORIGIN_PROVIDER_API',
  ORIGIN_WIDGET = 'ORIGIN_WIDGET'
}

export type PageInfo = {
  __typename: 'PageInfo';
  /** The `Cursor` of the last item in this page. You can use it as the `after` input argument to retrieve the next page. */
  endCursor: Maybe<Scalars['Cursor']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  /** The `Cursor` of the first item in this page. You can use it as the `before` input argument to retrieve the previous page. */
  startCursor: Maybe<Scalars['Cursor']['output']>;
};

export type PageLink = SportInCityPageLink;

export type PartialPriceInformation = {
  __typename: 'PartialPriceInformation';
  price: MonetaryValue;
  timeRange: TimeRange;
};

export type ParticipationInterface = {
  /** Friends of the logged in user who are participating */
  attendingFriends: Maybe<FriendConnection>;
  participationState: Maybe<ParticipationState>;
};


export type ParticipationInterfaceAttendingFriendsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum ParticipationState {
  PARTICIPATION_STATE_BOOKED = 'PARTICIPATION_STATE_BOOKED',
  PARTICIPATION_STATE_JOINED = 'PARTICIPATION_STATE_JOINED'
}

export enum PatronQuestionType {
  PATRON_QUESTION_TYPE_CHANNEL = 'PATRON_QUESTION_TYPE_CHANNEL',
  PATRON_QUESTION_TYPE_NONE = 'PATRON_QUESTION_TYPE_NONE',
  PATRON_QUESTION_TYPE_PAST_ATTENDANCE = 'PATRON_QUESTION_TYPE_PAST_ATTENDANCE'
}

export enum PatronageAnswer {
  PATRONAGE_ANSWER_EVERSPORTS = 'PATRONAGE_ANSWER_EVERSPORTS',
  PATRONAGE_ANSWER_VENUE_CHANNELS = 'PATRONAGE_ANSWER_VENUE_CHANNELS',
  PATRONAGE_ANSWER_VENUE_CUSTOMER = 'PATRONAGE_ANSWER_VENUE_CUSTOMER',
  PATRONAGE_ANSWER_VENUE_MEMBERS = 'PATRONAGE_ANSWER_VENUE_MEMBERS'
}

export type Payment = {
  __typename: 'Payment';
  canSetupRecurringPaymentMethod: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  lastAttemptAt: Maybe<Scalars['DateTime']['output']>;
  /** An optional `Mandate` that is available for this `Payment` */
  mandate: Maybe<Mandate>;
  paymentIntent: Maybe<PaymentIntent>;
  paymentMethods: Array<PaymentMethod>;
  paymentSetupIntent: Maybe<PaymentSetupIntent>;
  state: PaymentState;
  /** Available `StoredPaymentMethod` for this `Payment` */
  storedPaymentMethods: Array<StoredPaymentMethod>;
  /** The `User`  that is charged for this `Payment` */
  user: User;
};

export type PaymentIntent = {
  __typename: 'PaymentIntent';
  amount: Scalars['Float']['output'];
  clientSecret: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type PaymentMethod = {
  __typename: 'PaymentMethod';
  name: Scalars['String']['output'];
  type: PaymentMethodType;
  verifyWith: Maybe<PaymentMethodVerificationMethod>;
};

export enum PaymentMethodType {
  PAYMENT_METHOD_BANCONTACT = 'PAYMENT_METHOD_BANCONTACT',
  PAYMENT_METHOD_CREDIT_CARD = 'PAYMENT_METHOD_CREDIT_CARD',
  PAYMENT_METHOD_EPS = 'PAYMENT_METHOD_EPS',
  PAYMENT_METHOD_GIROPAY = 'PAYMENT_METHOD_GIROPAY',
  PAYMENT_METHOD_IDEAL = 'PAYMENT_METHOD_IDEAL',
  PAYMENT_METHOD_MULTIBANCO = 'PAYMENT_METHOD_MULTIBANCO',
  PAYMENT_METHOD_PAYPAL = 'PAYMENT_METHOD_PAYPAL',
  PAYMENT_METHOD_SEPA_ONLINE = 'PAYMENT_METHOD_SEPA_ONLINE',
  PAYMENT_METHOD_SOFORT = 'PAYMENT_METHOD_SOFORT',
  PAYMENT_METHOD_TWINT = 'PAYMENT_METHOD_TWINT'
}

export enum PaymentMethodVerificationMethod {
  PAYMENT_METHOD_IDEAL = 'PAYMENT_METHOD_IDEAL',
  PAYMENT_METHOD_SOFORT = 'PAYMENT_METHOD_SOFORT'
}

export type PaymentPlan = {
  __typename: 'PaymentPlan';
  initial: Maybe<MonetaryValue>;
  interval: Interval;
  offset: Maybe<Scalars['Int']['output']>;
  promotion: Maybe<Promotion>;
  recurring: MonetaryValue;
};

export type PaymentResponseType = ExpectedErrors | Payment;

export enum PaymentServiceType {
  PAYMENT_SERVICE_BANCONTACT = 'PAYMENT_SERVICE_BANCONTACT',
  PAYMENT_SERVICE_CASH = 'PAYMENT_SERVICE_CASH',
  PAYMENT_SERVICE_CHEQUECARD = 'PAYMENT_SERVICE_CHEQUECARD',
  PAYMENT_SERVICE_CHEQUEVACANCE = 'PAYMENT_SERVICE_CHEQUEVACANCE',
  PAYMENT_SERVICE_CREDIT = 'PAYMENT_SERVICE_CREDIT',
  PAYMENT_SERVICE_CREDITCARD = 'PAYMENT_SERVICE_CREDITCARD',
  PAYMENT_SERVICE_CREDITCARD_OTC = 'PAYMENT_SERVICE_CREDITCARD_OTC',
  PAYMENT_SERVICE_DEBITCARD = 'PAYMENT_SERVICE_DEBITCARD',
  PAYMENT_SERVICE_EPS = 'PAYMENT_SERVICE_EPS',
  PAYMENT_SERVICE_GIROPAY = 'PAYMENT_SERVICE_GIROPAY',
  PAYMENT_SERVICE_IDEAL = 'PAYMENT_SERVICE_IDEAL',
  PAYMENT_SERVICE_MANUAL = 'PAYMENT_SERVICE_MANUAL',
  PAYMENT_SERVICE_MARKETINGDISCOUNT = 'PAYMENT_SERVICE_MARKETINGDISCOUNT',
  PAYMENT_SERVICE_PAYPAL = 'PAYMENT_SERVICE_PAYPAL',
  PAYMENT_SERVICE_SEPA = 'PAYMENT_SERVICE_SEPA',
  PAYMENT_SERVICE_SEPA_ONLINE = 'PAYMENT_SERVICE_SEPA_ONLINE',
  PAYMENT_SERVICE_SOFORT = 'PAYMENT_SERVICE_SOFORT',
  PAYMENT_SERVICE_TRANSFER = 'PAYMENT_SERVICE_TRANSFER',
  PAYMENT_SERVICE_TWINT = 'PAYMENT_SERVICE_TWINT',
  PAYMENT_SERVICE_TWINT_OTC = 'PAYMENT_SERVICE_TWINT_OTC',
  PAYMENT_SERVICE_VOUCHER = 'PAYMENT_SERVICE_VOUCHER',
  PAYMENT_SERVICE_VOUCHER_OTC = 'PAYMENT_SERVICE_VOUCHER_OTC'
}

export type PaymentSetupIntent = {
  __typename: 'PaymentSetupIntent';
  clientSecret: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  paymentMethod: Maybe<StoredPaymentMethod>;
};

export enum PaymentState {
  PAYMENT_STATE_CANCELLED = 'PAYMENT_STATE_CANCELLED',
  PAYMENT_STATE_FAILED = 'PAYMENT_STATE_FAILED',
  PAYMENT_STATE_IN_PROGRESS = 'PAYMENT_STATE_IN_PROGRESS',
  PAYMENT_STATE_ON_HOLD = 'PAYMENT_STATE_ON_HOLD',
  PAYMENT_STATE_OPEN = 'PAYMENT_STATE_OPEN',
  PAYMENT_STATE_SUCCEEDED = 'PAYMENT_STATE_SUCCEEDED'
}

export enum PeriodStartType {
  VALIDITY_START_TYPE_DATE = 'VALIDITY_START_TYPE_DATE',
  VALIDITY_START_TYPE_FIRST_BOOKING = 'VALIDITY_START_TYPE_FIRST_BOOKING',
  VALIDITY_START_TYPE_PURCHASE_DATE = 'VALIDITY_START_TYPE_PURCHASE_DATE'
}

export type Policy = {
  __typename: 'Policy';
  id: Scalars['ID']['output'];
  isUpdate: Scalars['Boolean']['output'];
  mandatory: Scalars['Boolean']['output'];
  type: PolicyType;
  url: Scalars['String']['output'];
  validFrom: Scalars['DateTime']['output'];
};

/** Policies can be either terms of service, or privacy policies */
export enum PolicyType {
  POLICY_TYPE_PRIVACY = 'POLICY_TYPE_PRIVACY',
  POLICY_TYPE_TERMS_OF_SERVICE = 'POLICY_TYPE_TERMS_OF_SERVICE'
}

export type PriceTable = {
  __typename: 'PriceTable';
  /** @deprecated For handling Corporate Budgets and Special Prices correctly please switch to `itemsV3` which features a specific type for Corporate Budget */
  items: Array<PriceTableItem>;
  /** @deprecated For handling Special Prices correctly please switch to `itemsV3` which features a specific type for Special Prices */
  itemsV2: Array<PriceTableItemV2>;
  itemsV3: Array<PriceTableItemV3>;
  /**
   *
   * If the price table has any items which reduces the price (discounts, vouchers, special-offers),
   * the original price, without any reductions, will be shown here.
   * If no reduction is present this will be null
   */
  originalPrice: Maybe<MonetaryValue>;
  total: Maybe<MonetaryValue>;
};

export type PriceTableItem = PriceTableItemAddon | PriceTableItemBasic | PriceTableItemMembership | PriceTableItemVoucher;

/** This item represents a addon priceTableItem */
export type PriceTableItemAddon = PriceTableItemInterface & {
  __typename: 'PriceTableItemAddon';
  /** The number of items */
  amount: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  /** The single price for this item */
  singlePrice: Maybe<MonetaryValue>;
  /** The total price of the product (`singlePrice` * `amount`) */
  totalPrice: Maybe<MonetaryValue>;
};

/** This item represents a basic priceTableItem, currently this is used for: CourtBooking and EventBooking */
export type PriceTableItemBasic = PriceTableItemInterface & {
  __typename: 'PriceTableItemBasic';
  /** The number of items */
  amount: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  /** The single price for this item */
  singlePrice: Maybe<MonetaryValue>;
  /** The total price of the product (`singlePrice` * `amount`) */
  totalPrice: Maybe<MonetaryValue>;
};

/** The corporate budget applied on this cart */
export type PriceTableItemBudget = PriceTableItemInterface & {
  __typename: 'PriceTableItemBudget';
  /** The number of items */
  amount: Scalars['Float']['output'];
  /** The name of the Corporate Budget */
  budgetName: Scalars['String']['output'];
  /** The name of the Corporate providing the Budget */
  corporateName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** The single price for this item */
  singlePrice: Maybe<MonetaryValue>;
  /** The total price of the product (`singlePrice` * `amount`) */
  totalPrice: Maybe<MonetaryValue>;
};

export type PriceTableItemInterface = {
  /** The number of items */
  amount: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  /** The single price for this item */
  singlePrice: Maybe<MonetaryValue>;
  /** The total price of the product (`singlePrice` * `amount`) */
  totalPrice: Maybe<MonetaryValue>;
};

/** This item represents a price table item for a membership */
export type PriceTableItemMembership = PriceTableItemInterface & {
  __typename: 'PriceTableItemMembership';
  /** The number of items */
  amount: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  /** The time range of the current membership-period if we have a partial price. If no partial price is present this will be null */
  partialPriceTimeRange: Maybe<TimeRange>;
  paymentInterval: Maybe<Interval>;
  promotion: Maybe<Promotion>;
  /** The recurring price of the membership which the user has to pay each membership-period */
  recurringPrice: MonetaryValue;
  /** The single price for this item */
  singlePrice: Maybe<MonetaryValue>;
  /** The total price of the product (`singlePrice` * `amount`) */
  totalPrice: Maybe<MonetaryValue>;
};

/** This item represents a reduction due to a special price, currently this is used for corporate prices */
export type PriceTableItemSpecialPrice = PriceTableItemInterface & {
  __typename: 'PriceTableItemSpecialPrice';
  /** The number of items */
  amount: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  /** The single price for this item */
  singlePrice: Maybe<MonetaryValue>;
  specialPriceType: SpecialPriceType;
  /** The total price of the product (`singlePrice` * `amount`) */
  totalPrice: Maybe<MonetaryValue>;
};

export type PriceTableItemV2 = PriceTableItemAddon | PriceTableItemBasic | PriceTableItemBudget | PriceTableItemMembership | PriceTableItemVoucher;

export type PriceTableItemV3 = PriceTableItemAddon | PriceTableItemBasic | PriceTableItemBudget | PriceTableItemMembership | PriceTableItemSpecialPrice | PriceTableItemVoucher;

export type PriceTableItemVoucher = PriceTableItemInterface & {
  __typename: 'PriceTableItemVoucher';
  /** The number of items */
  amount: Scalars['Float']['output'];
  /** The voucher-code the user applied to the cart/order */
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** The single price for this item */
  singlePrice: Maybe<MonetaryValue>;
  /** The total price of the product (`singlePrice` * `amount`) */
  totalPrice: Maybe<MonetaryValue>;
};

export type PrivacySetting = {
  __typename: 'PrivacySetting';
  field: PrivacySettingField;
  visibility: PrivacySettingVisibility;
};

export enum PrivacySettingField {
  FAVORITE_VENUES = 'FAVORITE_VENUES',
  FRIENDS = 'FRIENDS',
  FRIENDS_COUNT = 'FRIENDS_COUNT',
  PROFILE = 'PROFILE',
  UPCOMING_BOOKINGS = 'UPCOMING_BOOKINGS'
}

export type PrivacySettingFieldInput = {
  field: PrivacySettingField;
  visibility: PrivacySettingVisibility;
};

export enum PrivacySettingVisibility {
  FRIEND = 'FRIEND',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export type PrivacySettingsInput = {
  fieldInputs: Array<PrivacySettingFieldInput>;
};

export type PrivateFeedback = {
  __typename: 'PrivateFeedback';
  id: Scalars['String']['output'];
  rating: Scalars['Float']['output'];
  text: Maybe<Scalars['String']['output']>;
  venue: Venue;
};

export type PrivateFeedbackResponse = ExpectedErrors | PrivateFeedback;

export type PrivateMatchOrganizer = MatchOrganizerInterface & UserInterface & {
  __typename: 'PrivateMatchOrganizer';
  bio: Maybe<Scalars['String']['output']>;
  customer: Maybe<Customer>;
  firstName: Scalars['String']['output'];
  handle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  profilePicture: Maybe<Image>;
};

export type PrivateMatchVisibilityValidation = MatchVisibilityValidationInterface & {
  __typename: 'PrivateMatchVisibilityValidation';
  missingFields: Array<MatchMissingField>;
  requiredFields: Array<MatchMissingField>;
};

export type ProductCartItem = CartItemInterface & {
  __typename: 'ProductCartItem';
  appliedBudget: Maybe<AppliedBudget>;
  appliedVoucher: Maybe<AppliedVoucher>;
  confirmWithOptions: Array<ConfirmWithOption>;
  id: Scalars['ID']['output'];
  product: ShopProduct;
  purchaseOptions: Array<PurchaseOption>;
  settleWith: Maybe<SettlementOption>;
};

export type ProductInterface = {
  id: Scalars['ID']['output'];
  productPurchaseInformation: ProductPurchaseInformation;
};

export type ProductPurchaseInformation = {
  __typename: 'ProductPurchaseInformation';
  additionalInformation: Maybe<Scalars['String']['output']>;
  checkoutURL: Maybe<Scalars['URL']['output']>;
  purchasable: Scalars['Boolean']['output'];
};


export type ProductPurchaseInformationCheckoutUrlArgs = {
  origin: Origin;
  returnTo?: InputMaybe<Scalars['URL']['input']>;
  venueId: Scalars['ID']['input'];
};

export type Promotion = {
  __typename: 'Promotion';
  period: Interval;
  price: MonetaryValue;
};

export type PublicCourtBooking = BookingInterface & ParticipationInterface & {
  __typename: 'PublicCourtBooking';
  /** Friends of the logged in user who are participating */
  attendingFriends: Maybe<FriendConnection>;
  bookableItem: CourtBookableItem;
  court: Court;
  id: Scalars['ID']['output'];
  participationState: Maybe<ParticipationState>;
  state: CourtBookingState;
  timeRange: TimeRange;
};


export type PublicCourtBookingAttendingFriendsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PublicEventSessionParticipation = ParticipationInterface & {
  __typename: 'PublicEventSessionParticipation';
  /** Friends of the logged in user who are participating */
  attendingFriends: Maybe<FriendConnection>;
  /** return the full bookableItem, including all session of the bookable item, not just the ones included in the booking. */
  bookableItem: EventBookableItem;
  id: Scalars['ID']['output'];
  participationState: Maybe<ParticipationState>;
  session: EventSession;
  timeRange: TimeRange;
};


export type PublicEventSessionParticipationAttendingFriendsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PublicMatchOrganizer = MatchOrganizerInterface & UserInterface & {
  __typename: 'PublicMatchOrganizer';
  bio: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  handle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  profilePicture: Maybe<Image>;
};

export type PublicMatchVisibilityValidation = MatchVisibilityValidationInterface & {
  __typename: 'PublicMatchVisibilityValidation';
  missingFields: Array<MatchMissingField>;
  requiredFields: Array<MatchMissingField>;
};

export type PublicParticipation = PublicCourtBooking | PublicEventSessionParticipation;

export type PublicParticipationConnection = {
  __typename: 'PublicParticipationConnection';
  edges: Array<PublicParticipationEdge>;
  /** The actual "PublicParticipation" items */
  nodes: Array<PublicParticipation>;
  pageInfo: PageInfo;
};

export type PublicParticipationEdge = {
  __typename: 'PublicParticipationEdge';
  cursor: Scalars['Cursor']['output'];
  node: PublicParticipation;
};

export type PublicParticipationV2 = BallsportMatch | PublicCourtBooking | PublicEventSessionParticipation;

export type PublicParticipationV2Connection = {
  __typename: 'PublicParticipationV2Connection';
  edges: Array<PublicParticipationV2Edge>;
  /** The actual "PublicParticipationV2" items */
  nodes: Array<PublicParticipationV2>;
  pageInfo: PageInfo;
};

export type PublicParticipationV2Edge = {
  __typename: 'PublicParticipationV2Edge';
  cursor: Scalars['Cursor']['output'];
  node: PublicParticipationV2;
};

/** Deprecated in favor of PublicUserV2 */
export type PublicUser = {
  __typename: 'PublicUser';
  name: Scalars['String']['output'];
};

/** Use this type if you want to resolve a user in public queries */
export type PublicUserV2 = UserInterface & {
  __typename: 'PublicUserV2';
  bio: Maybe<Scalars['String']['output']>;
  /** Returns the number of common activities in the last numberOfDays */
  commonActivitiesCount: Maybe<Scalars['Int']['output']>;
  commonFriendsCount: Maybe<Scalars['Int']['output']>;
  /** Returns `null` if the requester is not allowed to see this information according to the privacy settings of the data owner. */
  favouriteVenues: Maybe<FavoriteVenueConnection>;
  firstName: Scalars['String']['output'];
  friends: Maybe<FriendConnection>;
  friendsCount: Maybe<Scalars['Int']['output']>;
  handle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isPrivate: Scalars['Boolean']['output'];
  /** Defines if the public user is a friend of the currently authenticated user */
  isYourFriend: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  pendingFriendRequest: Maybe<FriendRequest>;
  profilePicture: Maybe<Image>;
  /** Returns `null` if the requester is not allowed to see this information according to the privacy settings of the data owner. */
  sportExperience: Maybe<Array<SportExperience>>;
  /** Returns `null` if the requester is not allowed to see this information according to the privacy settings of the data owner. */
  statistics: Maybe<UserStatistics>;
  /**
   * Returns `null` if the requester is not allowed to see this information according to the privacy settings of the data owner.
   * @deprecated Use `upcomingParticipationsV2` instead to include matches
   */
  upcomingParticipations: Maybe<PublicParticipationConnection>;
  /** Returns `null` if the requester is not allowed to see this information according to the privacy settings of the data owner. */
  upcomingParticipationsV2: Maybe<PublicParticipationV2Connection>;
};


/** Use this type if you want to resolve a user in public queries */
export type PublicUserV2FavouriteVenuesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Use this type if you want to resolve a user in public queries */
export type PublicUserV2FriendsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Use this type if you want to resolve a user in public queries */
export type PublicUserV2UpcomingParticipationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Use this type if you want to resolve a user in public queries */
export type PublicUserV2UpcomingParticipationsV2Args = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PublicUserV2Connection = {
  __typename: 'PublicUserV2Connection';
  edges: Array<PublicUserV2Edge>;
  /** The actual "PublicUserV2" items */
  nodes: Array<PublicUserV2>;
  pageInfo: PageInfo;
};

export type PublicUserV2Edge = {
  __typename: 'PublicUserV2Edge';
  cursor: Scalars['Cursor']['output'];
  node: PublicUserV2;
};

/** Options to purchase a cart. This can either be a `Product` or a direct payment (Ticket, SinglePurchase) */
export type PurchaseOption = Card | Membership | SinglePurchase | Ticket;

/** Single purchase of an item without using an existing product */
export type PurchasedWith = SinglePurchase | Ticket;

export type Query = {
  __typename: 'Query';
  assignedProduct: AssignedProductsForUserProfileType;
  /** Returns an assigned recording by its id. */
  assignedRecording: AssignedRecording;
  /** Provides a list of assigned recordings. */
  assignedRecordings: AssignedRecordingConnection;
  cart: Cart;
  city: City;
  citySearch: Array<CitySearchResult>;
  company: Company;
  corporateInvitation: CorporateInvitationData;
  /** This will return the CorporatePartner details for the given Company ID. */
  corporatePartner: CorporatePartner;
  countries: Array<Country>;
  discoverContextByCity: DiscoverContextForCity;
  discoverContextByCityAndSportSlug: DiscoverContextForCityAndSport;
  /** This context query is used by the mobile clients instead of discoverContextByCity */
  discoverContextByCoordinate: DiscoverCoordinateContext;
  discoverMatchContext: DiscoverMatchContext;
  featurePreviews: Array<FeaturePreview>;
  /** Retrieve an EventBookableItem by its id. */
  getEventBookableItem: EventBookableItem;
  /** Get the marketplace fee created by a certain order */
  getMarketplaceFeeForOrder: Maybe<MonetaryValue>;
  inventorySearch: Array<InventorySearchResult>;
  invitation: Invitation;
  /** Get a single invoice by its ID */
  invoice: Invoice;
  latestEversportsPolicies: Array<Policy>;
  match: Match;
  /** Find public matches. Currently only future matches, sorted by start asc */
  matches: BallsportMatchConnection;
  me: Maybe<User>;
  /** Allows users to search for fellow members of a club. This is mostly used for Tennis Clubs, where members provide the information with whom they are playing upon reservation of a court */
  multiPlayerSearch: MultiPlayerUser;
  order: Order;
  popularCities: Array<City>;
  product: ShopProduct;
  products: Array<ShopProduct>;
  publicUser: PublicUserV2;
  /** Returns a list of random page links */
  randomPageLinks: Array<PageLink>;
  recap: Recap;
  /** Returns a recording by its id. */
  recording: Recording;
  /** Provides a search for recording categories. */
  recordingCategories: RecordingCategoryConnection;
  /** Returns a recording category by its id. */
  recordingCategory: RecordingCategory;
  /** Provides a search for recordings. */
  recordings: RecordingConnection;
  /** Returns a sport by its id. */
  sport: Sport;
  /** Returns a sport by its slug. */
  sportBySlug: Sport;
  /** Provides a search for sports. */
  sports: SportConnection;
  syncContacts: Array<SyncContactsResponse>;
  /** Paginated version of syncContacts query */
  syncContactsV2: SyncContactsResponseV2Connection;
  userSearch: Array<PublicUserV2>;
  venue: Venue;
  venueContext: VenueContext;
  venueSearch: VenueConnection;
};


export type QueryAssignedProductArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAssignedRecordingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAssignedRecordingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCartArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCityArgs = {
  slug: Scalars['String']['input'];
};


export type QueryCitySearchArgs = {
  coordinate?: InputMaybe<CoordinateArgs>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCompanyArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryCorporateInvitationArgs = {
  corporatePartnerId?: InputMaybe<Scalars['ID']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCorporatePartnerArgs = {
  corporatePartnerId: Scalars['ID']['input'];
};


export type QueryDiscoverContextByCityArgs = {
  citySlug: Scalars['String']['input'];
};


export type QueryDiscoverContextByCityAndSportSlugArgs = {
  citySlug?: InputMaybe<Scalars['String']['input']>;
  coordinate?: InputMaybe<CoordinateArgs>;
  sportSlug: Scalars['String']['input'];
};


export type QueryDiscoverContextByCoordinateArgs = {
  coordinate: CoordinateArgs;
};


export type QueryDiscoverMatchContextArgs = {
  citySlug?: InputMaybe<Scalars['String']['input']>;
  coordinate?: InputMaybe<CoordinateArgs>;
  sportSlug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFeaturePreviewsArgs = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  public?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetEventBookableItemArgs = {
  eventType?: InputMaybe<EventType>;
  id?: InputMaybe<Scalars['String']['input']>;
  shortId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetMarketplaceFeeForOrderArgs = {
  orderId: Scalars['ID']['input'];
};


export type QueryInventorySearchArgs = {
  citySlug?: InputMaybe<Scalars['String']['input']>;
  coordinate: CoordinateArgs;
  limit?: Scalars['Int']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInvitationArgs = {
  invitationId: Scalars['ID']['input'];
};


export type QueryInvoiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMatchArgs = {
  matchId: Scalars['ID']['input'];
};


export type QueryMatchesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  filters: MatchFilters;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMultiPlayerSearchArgs = {
  cartId: Scalars['ID']['input'];
  search: Scalars['ID']['input'];
};


export type QueryOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPopularCitiesArgs = {
  countryCode: Scalars['String']['input'];
};


export type QueryProductArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductsArgs = {
  venueId: Scalars['ID']['input'];
};


export type QueryPublicUserArgs = {
  handle: Scalars['String']['input'];
};


export type QueryRandomPageLinksArgs = {
  count?: Scalars['Int']['input'];
};


export type QueryRecapArgs = {
  args: RecapArgs;
};


export type QueryRecordingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRecordingCategoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  venueId: Scalars['ID']['input'];
};


export type QueryRecordingCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRecordingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sportId?: InputMaybe<Scalars['ID']['input']>;
  venueId?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerySportArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySportBySlugArgs = {
  slug: Scalars['String']['input'];
};


export type QuerySportsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  venueId: Scalars['ID']['input'];
};


export type QuerySyncContactsArgs = {
  telephoneNumbers: Array<Scalars['String']['input']>;
};


export type QuerySyncContactsV2Args = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  telephoneNumbers: Array<Scalars['String']['input']>;
};


export type QueryUserSearchArgs = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVenueArgs = {
  venueId?: InputMaybe<Scalars['ID']['input']>;
  venueSlug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVenueContextArgs = {
  slug: Scalars['String']['input'];
};


export type QueryVenueSearchArgs = {
  city?: InputMaybe<Scalars['ID']['input']>;
  filters: VenueFilters;
  sort?: InputMaybe<VenueSearchSort>;
  userLocation?: InputMaybe<CoordinateArgs>;
};

export type Rating = {
  __typename: 'Rating';
  type: RatingType;
  value: Scalars['Float']['output'];
};

export enum RatingType {
  ATMOSPHERE = 'ATMOSPHERE',
  CLEANLINESS = 'CLEANLINESS',
  FRIENDLINESS_SERVICE = 'FRIENDLINESS_SERVICE',
  PRICE_PERFORMANCE = 'PRICE_PERFORMANCE',
  ROOM_QUALITY = 'ROOM_QUALITY'
}

export type Recap = {
  __typename: 'Recap';
  activeDays: Scalars['Int']['output'];
  activitiesPerMonth: Array<RecapMonth>;
  from: Scalars['DateTime']['output'];
  minutesActive: Scalars['Int']['output'];
  sports: Array<RecapSport>;
  sportsCount: Scalars['Int']['output'];
  to: Scalars['DateTime']['output'];
  totalBookings: Scalars['Int']['output'];
  user: PublicUserV2;
  venues: Array<RecapVenue>;
  venuesCount: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};


export type RecapSportsArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
};


export type RecapVenuesArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
};

export type RecapArgs = {
  token: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

export type RecapMonth = {
  __typename: 'RecapMonth';
  bookingCount: Scalars['Int']['output'];
  /** Month of the year, starting with 1 for January */
  month: Scalars['Float']['output'];
};

export type RecapSport = {
  __typename: 'RecapSport';
  bookingCount: Scalars['Int']['output'];
  sport: Sport;
};

export type RecapVenue = {
  __typename: 'RecapVenue';
  bookingCount: Scalars['Int']['output'];
  venue: Venue;
};

export type Recommendation = {
  __typename: 'Recommendation';
  recentTrainers: TrainerConnection;
  venuesWithTrialCards: VenueConnection;
};


export type RecommendationRecentTrainersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type RecommendationVenuesWithTrialCardsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type Recording = {
  __typename: 'Recording';
  assignedProducts: Array<AssignedBookingProduct>;
  assignedRecordings: Array<AssignedRecording>;
  /**
   * Nullable, `null` means it will be available indefinitely
   * @deprecated Deprecated, use the field validityV2 instead
   */
  availableUntil: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  duration: Maybe<Scalars['Float']['output']>;
  hasUserBoughtProduct: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  image: Maybe<Image>;
  language: Maybe<Scalars['String']['output']>;
  level: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** @deprecated The price of a recording is not mandatory anymore. Use the `SinglePurchase` type of the purchaseOptions instead */
  price: MonetaryValue;
  purchaseOptions: Array<PurchaseOption>;
  recordingEndDate: Maybe<Scalars['DateTime']['output']>;
  recordingStartDate: Maybe<Scalars['DateTime']['output']>;
  sport: Sport;
  trainer: Maybe<Trainer>;
  validityV2: Maybe<RecordingValidity>;
  venue: Venue;
};


export type RecordingAssignedProductsArgs = {
  isValid?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RecordingCategory = {
  __typename: 'RecordingCategory';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  recordings: RecordingConnection;
};


export type RecordingCategoryRecordingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type RecordingCategoryConnection = {
  __typename: 'RecordingCategoryConnection';
  edges: Array<RecordingCategoryEdge>;
  /** The actual "RecordingCategory" items */
  nodes: Array<RecordingCategory>;
  pageInfo: PageInfo;
};

export type RecordingCategoryEdge = {
  __typename: 'RecordingCategoryEdge';
  cursor: Scalars['Cursor']['output'];
  node: RecordingCategory;
};

export type RecordingConnection = {
  __typename: 'RecordingConnection';
  edges: Array<RecordingEdge>;
  /** The actual "Recording" items */
  nodes: Array<Recording>;
  pageInfo: PageInfo;
};

export type RecordingEdge = {
  __typename: 'RecordingEdge';
  cursor: Scalars['Cursor']['output'];
  node: Recording;
};

export type RecordingValidity = TimePeriod | TimeRange;

export type RegistrationProfileInput = {
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type RegistrationSettingsInput = {
  subscribeToEversportsNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum RegistrationSource {
  REGISTRATION_SOURCE_EXISTING_USER = 'REGISTRATION_SOURCE_EXISTING_USER',
  REGISTRATION_SOURCE_NEW_USER_VIA_APPLE = 'REGISTRATION_SOURCE_NEW_USER_VIA_APPLE',
  REGISTRATION_SOURCE_NEW_USER_VIA_CREDENTIALS = 'REGISTRATION_SOURCE_NEW_USER_VIA_CREDENTIALS',
  REGISTRATION_SOURCE_NEW_USER_VIA_FACEBOOK = 'REGISTRATION_SOURCE_NEW_USER_VIA_FACEBOOK',
  REGISTRATION_SOURCE_NEW_USER_VIA_GOOGLE = 'REGISTRATION_SOURCE_NEW_USER_VIA_GOOGLE',
  REGISTRATION_SOURCE_NEW_USER_VIA_INVITATION = 'REGISTRATION_SOURCE_NEW_USER_VIA_INVITATION'
}

export type RemoveFromWaitingListResponse = ExpectedErrors | WaitingList;

export type RemoveParticipantsFromMatchV2 = BallsportMatch | ExpectedErrors;

export type Renewal = {
  __typename: 'Renewal';
  cancellation: Interval;
  interval: Interval;
};

export type Repeating = {
  __typename: 'Repeating';
  interval: Interval;
  isTransferable: Scalars['Boolean']['output'];
};

export type RequestVerificationResponse = ExpectedErrors | RequestVerificationResult;

export type RequestVerificationResult = {
  __typename: 'RequestVerificationResult';
  /** ONLY DEV: This is needed for e2e tests and convenient for local development */
  activationLink: Maybe<Scalars['String']['output']>;
};

export type RequiredInformation = {
  __typename: 'RequiredInformation';
  address: Maybe<Location>;
  birthday: Maybe<Scalars['Date']['output']>;
  fiscalCode: Maybe<Scalars['String']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
};

export type RequiredInformationInput = {
  address?: InputMaybe<LocationInput>;
  birthday?: InputMaybe<Scalars['Date']['input']>;
  fiscalCode?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum RequiredInformationType {
  REQUIRED_INFORMATION_TYPE_ADDRESS = 'REQUIRED_INFORMATION_TYPE_ADDRESS',
  REQUIRED_INFORMATION_TYPE_BIRTHDAY = 'REQUIRED_INFORMATION_TYPE_BIRTHDAY',
  REQUIRED_INFORMATION_TYPE_FISCAL_CODE = 'REQUIRED_INFORMATION_TYPE_FISCAL_CODE',
  REQUIRED_INFORMATION_TYPE_PHONE_NUMBER = 'REQUIRED_INFORMATION_TYPE_PHONE_NUMBER'
}

export type ResetPasswordResponse = ExpectedErrors | ResetPasswordResult;

export type ResetPasswordResult = {
  __typename: 'ResetPasswordResult';
  /** ONLY DEV: This is needed for e2e tests and convenient for local development */
  activationLink: Maybe<Scalars['String']['output']>;
};

export type Review = {
  __typename: 'Review';
  comment: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  user: User;
};

export type ReviewConnection = {
  __typename: 'ReviewConnection';
  edges: Array<ReviewEdge>;
  /** The actual "Review" items */
  nodes: Array<Review>;
  pageInfo: PageInfo;
};

export type ReviewEdge = {
  __typename: 'ReviewEdge';
  cursor: Scalars['Cursor']['output'];
  node: Review;
};

export type Runtime = {
  __typename: 'Runtime';
  initial: Interval;
  pausable: Scalars['Boolean']['output'];
  renewal: Maybe<Renewal>;
};

export type SearchResult = {
  id: Scalars['ID']['output'];
  matchedName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  secondaryMatch: Maybe<Scalars['String']['output']>;
};

export type Section = {
  __typename: 'Section';
  heading: Scalars['String']['output'];
  paragraph: Scalars['String']['output'];
};

export type SelectAddOnField = {
  __typename: 'SelectAddOnField';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  options: Array<SelectAddOnFieldOption>;
  selectionType: SelectAddOnFieldSelectionType;
};

export type SelectAddOnFieldOption = {
  __typename: 'SelectAddOnFieldOption';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  price: Maybe<MonetaryValue>;
};

/** Defines whether one or multiple options can be selected. */
export enum SelectAddOnFieldSelectionType {
  SELECTION_TYPE_MULTI = 'SELECTION_TYPE_MULTI',
  SELECTION_TYPE_SINGLE = 'SELECTION_TYPE_SINGLE'
}

export type SessionComment = {
  __typename: 'SessionComment';
  comment: FormattedText;
  title: Scalars['String']['output'];
};

/**
 * A settlement option is an option which a user can use during the checkout.
 * It can either be a purchasable product, an assigned product, a ticket or single ticket
 */
export type SettlementOption = AssignedCard | AssignedMembership | Card | GenericAssignedProduct | Membership | SinglePurchase | Ticket;

/** Products that a user can buy in the shop, without booking something */
export type ShopProduct = Card | Membership | Recording | Voucher;

/** Single Booking is a kind of a product used to indicate the user only wants to purchase this 1 booking, without buying a membership or blockcard */
export type SinglePurchase = {
  __typename: 'SinglePurchase';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  price: MonetaryValue;
  productPurchaseInformation: ProductPurchaseInformation;
};

export type Slot = {
  __typename: 'Slot';
  end: Scalars['DateTime']['output'];
  isAvailable: Scalars['Boolean']['output'];
  start: Scalars['DateTime']['output'];
};

export type SlotToDiscoverFilter = {
  courtAreas?: InputMaybe<Array<CourtAreaType>>;
  courtAvailability: CourtAvailability;
  courtSurfaces?: InputMaybe<Array<CourtSurfaceType>>;
  sport: Scalars['ID']['input'];
};

export type Social = {
  __typename: 'Social';
  openGraph: OpenGraph;
  twitter: Twitter;
};

export type SocialInterface = {
  description: Maybe<Scalars['String']['output']>;
  image: Image;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type SpecialPrice = {
  __typename: 'SpecialPrice';
  /** The single price for this item */
  price: Maybe<MonetaryValue>;
  type: SpecialPriceType;
};

export enum SpecialPriceType {
  CORPORATE_EMPLOYEES = 'CORPORATE_EMPLOYEES'
}

export type Sport = {
  __typename: 'Sport';
  category: SportCategory;
  children: Array<Sport>;
  courtTermPlural: Scalars['String']['output'];
  courtTermSingular: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parents: Array<Sport>;
  recordings: RecordingConnection;
  slug: Scalars['String']['output'];
};


export type SportChildrenArgs = {
  showInDiscover?: InputMaybe<Scalars['Boolean']['input']>;
};


export type SportParentsArgs = {
  showInDiscover?: InputMaybe<Scalars['Boolean']['input']>;
};


export type SportRecordingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  venueId: Scalars['ID']['input'];
};

export enum SportCategory {
  BALLSPORT = 'BALLSPORT',
  FITNESS = 'FITNESS',
  FUNSPORT = 'FUNSPORT'
}

export type SportConnection = {
  __typename: 'SportConnection';
  edges: Array<SportEdge>;
  /** The actual "Sport" items */
  nodes: Array<Sport>;
  pageInfo: PageInfo;
};

export type SportEdge = {
  __typename: 'SportEdge';
  cursor: Scalars['Cursor']['output'];
  node: Sport;
};

export type SportExperience = {
  __typename: 'SportExperience';
  level: SportExperienceLevel;
  sport: Maybe<Sport>;
};

export enum SportExperienceLevel {
  ADVANCED = 'ADVANCED',
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE'
}

/** Everything needed to render a link to a "Sport in City" page */
export type SportInCityPageLink = {
  __typename: 'SportInCityPageLink';
  /** Parameters for  "Sport in City" page link text */
  linkParams: SportInCityPageLinkParams;
  /** URL to the "Sport in City" page */
  linkUrl: Scalars['String']['output'];
};

/** Parameters for city in sport page link text */
export type SportInCityPageLinkParams = {
  __typename: 'SportInCityPageLinkParams';
  /**
   * City name as stored in the DB. Probably in german.
   * @deprecated Use citySlug instead if possible
   */
  cityName: Scalars['String']['output'];
  /** City slug to be used as part of a translation key */
  citySlug: Scalars['String']['output'];
  /**
   * Sport name as stored in the DB. Probably in german.
   * @deprecated Use sportSlug instead if possible
   */
  sportName: Scalars['String']['output'];
  /** Sport slug to be used as part of a translation key */
  sportSlug: Scalars['String']['output'];
};

export type SportSearchResult = SearchResult & {
  __typename: 'SportSearchResult';
  cityId: Scalars['ID']['output'];
  cityName: Scalars['String']['output'];
  citySlug: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  matchedName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  secondaryMatch: Maybe<Scalars['String']['output']>;
  sportId: Scalars['ID']['output'];
  sportSlug: Scalars['String']['output'];
};

export type SpotLimit = LimitedSpots | UnlimitedSpots;

export type StoredBankAccount = StoredPaymentMethodInterface & {
  __typename: 'StoredBankAccount';
  bankCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isUsedForRecurringPayment: Scalars['Boolean']['output'];
  last4: Scalars['String']['output'];
  mandates: Array<Mandate>;
  /** @deprecated Deprecated in favor of `paymentMethod.name` */
  name: Scalars['String']['output'];
  paymentMethod: PaymentMethod;
  /** A `PaymentMethod` identifier used for external payment providers. */
  pspId: Scalars['ID']['output'];
};

export type StoredCreditCard = StoredPaymentMethodInterface & {
  __typename: 'StoredCreditCard';
  brand: Scalars['String']['output'];
  expirationMonth: Scalars['Float']['output'];
  expirationYear: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  isUsedForRecurringPayment: Scalars['Boolean']['output'];
  last4: Scalars['String']['output'];
  /** @deprecated Deprecated in favor of `paymentMethod.name` */
  name: Scalars['String']['output'];
  paymentMethod: PaymentMethod;
  /** A `PaymentMethod` identifier used for external payment providers. */
  pspId: Scalars['ID']['output'];
};

/** A payment method that is attached to a customer and can be used to fulfill payments. */
export type StoredPaymentMethod = StoredBankAccount | StoredCreditCard;

export type StoredPaymentMethodInterface = {
  id: Scalars['ID']['output'];
  isUsedForRecurringPayment: Scalars['Boolean']['output'];
  /** @deprecated Deprecated in favor of `paymentMethod.name` */
  name: Scalars['String']['output'];
  paymentMethod: PaymentMethod;
  /** A `PaymentMethod` identifier used for external payment providers. */
  pspId: Scalars['ID']['output'];
};

export type StoredPaymentMethodResponse = ExpectedErrors | StoredBankAccount | StoredCreditCard;

export enum SuggestedType {
  COMMON_ACTIVITIES = 'COMMON_ACTIVITIES',
  COMMON_FRIENDS = 'COMMON_FRIENDS'
}

export enum Surface {
  ARTIFICIALGRASS = 'ArtificialGrass',
  ARTIFICIALICE = 'ArtificialIce',
  ASH = 'Ash',
  ASPHALT = 'Asphalt',
  CARPET = 'Carpet',
  CARPETGRANULE = 'CarpetGranule',
  CONCRETE = 'Concrete',
  FELT = 'Felt',
  GRANULE = 'Granule',
  GRASS = 'Grass',
  HARDCOURT = 'HardCourt',
  ICE = 'Ice',
  OPTICOURT = 'Opticourt',
  PVC = 'PVC',
  PARQUET = 'Parquet',
  REDCOURT = 'RedCourt',
  SAND = 'Sand',
  SYNTHETIC = 'Synthetic',
  TARTAN = 'Tartan',
  WATER = 'Water'
}

export type SyncContactsResponse = {
  __typename: 'SyncContactsResponse';
  publicUser: Maybe<PublicUserV2>;
  telephoneHash: Scalars['String']['output'];
};

export type SyncContactsResponseV2 = {
  __typename: 'SyncContactsResponseV2';
  id: Scalars['ID']['output'];
  publicUser: PublicUserV2;
  telephoneHash: Scalars['String']['output'];
};

export type SyncContactsResponseV2Connection = {
  __typename: 'SyncContactsResponseV2Connection';
  edges: Array<SyncContactsResponseV2Edge>;
  /** The actual "SyncContactsResponseV2" items */
  nodes: Array<SyncContactsResponseV2>;
  pageInfo: PageInfo;
};

export type SyncContactsResponseV2Edge = {
  __typename: 'SyncContactsResponseV2Edge';
  cursor: Scalars['Cursor']['output'];
  node: SyncContactsResponseV2;
};

export type TextAddOnField = {
  __typename: 'TextAddOnField';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  price: Maybe<MonetaryValue>;
};

export type Ticket = ProductInterface & {
  __typename: 'Ticket';
  currentPriceType: TicketPriceType;
  earlyBirdPrice: Maybe<MonetaryValue>;
  /** The date until when the early bird price is available */
  earlyBirdUntil: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  /** The date from when on the last minute price is available */
  lastMinuteFrom: Maybe<Scalars['Date']['output']>;
  lastMinutePrice: Maybe<MonetaryValue>;
  name: Scalars['String']['output'];
  /** @deprecated Use standardPrice, earlyBirdPrice and lastMinutePrice in combination with the currentPriceType instead */
  price: MonetaryValue;
  /** @deprecated Use currentPriceType instead */
  priceType: TicketPriceType;
  productPurchaseInformation: ProductPurchaseInformation;
  standardPrice: MonetaryValue;
  /** The List of sessions for which this ticket is valid */
  validForSessions: Array<EventSession>;
};

/** Until a certain date, there might be an "Early Bird" price, after a certain date, there might be a "Last Minute" price */
export enum TicketPriceType {
  TICKET_PRICE_TYPE_EARLY_BIRD = 'TICKET_PRICE_TYPE_EARLY_BIRD',
  TICKET_PRICE_TYPE_LAST_MINUTE = 'TICKET_PRICE_TYPE_LAST_MINUTE',
  TICKET_PRICE_TYPE_STANDARD = 'TICKET_PRICE_TYPE_STANDARD'
}

export type TimePeriod = {
  __typename: 'TimePeriod';
  duration: Interval;
  /** The start date is only valid when the `startType` is `VALIDITY_START_TYPE_DATE`. */
  startDate: Maybe<Scalars['Date']['output']>;
  startType: PeriodStartType;
};

export type TimeRange = {
  __typename: 'TimeRange';
  end: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
};

export type TimeRangeInput = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
};

export type Trainer = {
  __typename: 'Trainer';
  description: Maybe<Scalars['String']['output']>;
  education: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  image: Maybe<Image>;
  lastName: Maybe<Scalars['String']['output']>;
  nickname: Scalars['String']['output'];
  recentlyTrainedUserAt: Maybe<Venue>;
  sports: Array<Sport>;
  venues: Array<Venue>;
};

export type TrainerConnection = {
  __typename: 'TrainerConnection';
  edges: Array<TrainerEdge>;
  /** The actual "Trainer" items */
  nodes: Array<Trainer>;
  pageInfo: PageInfo;
};

export type TrainerEdge = {
  __typename: 'TrainerEdge';
  cursor: Scalars['Cursor']['output'];
  node: Trainer;
};

export type Twitter = SocialInterface & {
  __typename: 'Twitter';
  description: Maybe<Scalars['String']['output']>;
  image: Image;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

/** Has no real properties, but its type denotes unlimited available budget */
export type Unlimited = {
  __typename: 'Unlimited';
  type: Scalars['String']['output'];
};

/** Has no properties, but its type denotes unlimited available spots */
export type UnlimitedSpots = {
  __typename: 'UnlimitedSpots';
  type: Scalars['String']['output'];
};

export type UpdateMatch = BallsportMatch | ExpectedErrors;

export type UsageLimit = {
  __typename: 'UsageLimit';
  currency: Maybe<Scalars['String']['output']>;
  repeating: Maybe<Repeating>;
  type: UsageLimitType;
  unit: UsageLimitUnit;
  usageLimit: Scalars['Float']['output'];
};

export type UsageLimitPeriod = {
  __typename: 'UsageLimitPeriod';
  allowedBookings: Scalars['Float']['output'];
  end: Scalars['Date']['output'];
  start: Scalars['Date']['output'];
  usedBookings: Scalars['Float']['output'];
};

export type UsageLimitPeriodV2 = {
  __typename: 'UsageLimitPeriodV2';
  allowedBookings: Scalars['Float']['output'];
  end: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
  usedBookings: Scalars['Float']['output'];
};

/**
 *
 *   - `TOTAL` means `value` is to be interpreted as a limit which applies to the entire card over the entirity of its lifetime.
 *   - `REPEATING` means `value` is to be interpreted as a limit which applies on an Interval Basis, where `repeating` provides information on this interval.
 *   - `PER BOOKING` means `value` is to be interpreted as a limit which applies on the basis of a single booking, like no more than 1.5 hours per booking.
 *
 */
export enum UsageLimitType {
  USAGE_LIMIT_TYPE_PER_BOOKING = 'USAGE_LIMIT_TYPE_PER_BOOKING',
  USAGE_LIMIT_TYPE_REPEATING = 'USAGE_LIMIT_TYPE_REPEATING',
  USAGE_LIMIT_TYPE_TOTAL = 'USAGE_LIMIT_TYPE_TOTAL'
}

/**
 *
 *   - `HOURS` means `value` is given in hours. E.g. a limit of 5 hours per week.
 *   - `MONETARY` means `value` is given as financial value, where `currency` provides the currency. E.g. a card allowing 100 € worth of bookings to be made.
 *   - `UNITS` means `value` is given as generic units, usually equal to bookings of activities, however some activities may cost more units than others.
 *
 */
export enum UsageLimitUnit {
  USAGE_LIMIT_UNIT_HOURS = 'USAGE_LIMIT_UNIT_HOURS',
  USAGE_LIMIT_UNIT_MONETARY = 'USAGE_LIMIT_UNIT_MONETARY',
  USAGE_LIMIT_UNIT_UNITS = 'USAGE_LIMIT_UNIT_UNITS'
}

/** This type represents an authorized user. */
export type User = UserInterface & {
  __typename: 'User';
  assignedProducts: Array<AssignedProductsForUserProfileType>;
  availableBudget: Maybe<AvailableBudget>;
  bio: Maybe<Scalars['String']['output']>;
  corporateBenefits: Maybe<CorporateBenefits>;
  dateOfBirth: Maybe<Scalars['Date']['output']>;
  email: Scalars['String']['output'];
  family: Maybe<Family>;
  familyRole: Maybe<FamilyRole>;
  firstName: Scalars['String']['output'];
  friends: FriendConnection;
  friendsCount: Scalars['Int']['output'];
  gender: Maybe<Gender>;
  handle: Scalars['String']['output'];
  hasAcceptedPublicProfileTerms: Scalars['Boolean']['output'];
  hasVerifiedEmail: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  lastLogin: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  locale: Maybe<Scalars['String']['output']>;
  missingCompanyPolicies: Array<Policy>;
  missingEversportsPolicies: Array<Policy>;
  notifications: NotificationConnection;
  /** @deprecated Use `storedPaymentMethods` and `usableStoredPaymentMethods` instead.  */
  paymentMethods: Array<StoredPaymentMethod>;
  privacySettings: Array<PrivacySetting>;
  privateFeedbacks: Array<PrivateFeedback>;
  profilePicture: Maybe<Image>;
  /** The recap token for the user. Will be null if no recap has to be shown for this user */
  recapToken: Maybe<Scalars['String']['output']>;
  receivedFriendRequests: FriendRequestConnection;
  receivedFriendRequestsCount: Scalars['Int']['output'];
  recommendation: Maybe<Recommendation>;
  sentFriendRequests: FriendRequestConnection;
  sentFriendRequestsCount: Scalars['Int']['output'];
  showNetPromoterScoreQuestion: Scalars['Boolean']['output'];
  /** All payment methods that are attached to this user */
  storedPaymentMethods: Array<StoredPaymentMethod>;
  suggestedFriends: PublicUserV2Connection;
  unreadNotificationsCount: Maybe<Scalars['Int']['output']>;
  upcomingFriendsParticipations: PublicParticipationV2Connection;
  /** Stored payment methods a user can use */
  usableStoredPaymentMethods: Array<StoredPaymentMethod>;
  /** The infrastructure type this user is rolled out to */
  usedInfrastructureType: Scalars['String']['output'];
};


/** This type represents an authorized user. */
export type UserFriendsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** This type represents an authorized user. */
export type UserMissingCompanyPoliciesArgs = {
  venueId: Scalars['ID']['input'];
};


/** This type represents an authorized user. */
export type UserNotificationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** This type represents an authorized user. */
export type UserRecapTokenArgs = {
  year: Scalars['Float']['input'];
};


/** This type represents an authorized user. */
export type UserReceivedFriendRequestsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** This type represents an authorized user. */
export type UserSentFriendRequestsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** This type represents an authorized user. */
export type UserSuggestedFriendsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  suggestedTypes?: InputMaybe<Array<SuggestedType>>;
};


/** This type represents an authorized user. */
export type UserUpcomingFriendsParticipationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserInterface = {
  bio: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  handle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  profilePicture: Maybe<Image>;
};

export type UserResponseType = ExpectedErrors | User;

export type UserStatistics = {
  __typename: 'UserStatistics';
  activityCount: Scalars['Float']['output'];
  lastActivityDate: Maybe<Scalars['DateTime']['output']>;
  mostPlayedSport: Maybe<Sport>;
  totalActivities: Scalars['Float']['output'];
};


export type UserStatisticsActivityCountArgs = {
  timeRange: OpenEndTimeRangeInput;
};

export type Validity = {
  __typename: 'Validity';
  duration: Interval;
  /** The start date is only valid when the `startType` is `VALIDITY_START_TYPE_DATE`. */
  startDate: Maybe<Scalars['Date']['output']>;
  startType: ValidityStartType;
};

export enum ValidityStartType {
  VALIDITY_START_TYPE_DATE = 'VALIDITY_START_TYPE_DATE',
  VALIDITY_START_TYPE_FIRST_BOOKING = 'VALIDITY_START_TYPE_FIRST_BOOKING',
  VALIDITY_START_TYPE_PURCHASE_DATE = 'VALIDITY_START_TYPE_PURCHASE_DATE'
}

export type Venue = {
  __typename: 'Venue';
  about: About;
  address: Scalars['String']['output'];
  amenities: Array<VenueAmenityType>;
  cheapestPrice: Maybe<MonetaryValue>;
  cheapestTrialProductPrice: Maybe<MonetaryValue>;
  city: City;
  company: Company;
  contact: Contact;
  externalTrackingIds: ExternalTrackingIds;
  faqs: Array<VenueFaq>;
  hideAddress: Scalars['Boolean']['output'];
  hideNewsletterOnRegistration: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  images: Array<Image>;
  isFavourite: Scalars['Boolean']['output'];
  /** @deprecated Deprecated in favor of venue.company.latestPolicies */
  latestPolicies: Array<Policy>;
  location: Location;
  logo: Maybe<Image>;
  name: Scalars['String']['output'];
  offerings: Array<VenueOfferingType>;
  profileImage: Maybe<Image>;
  /** Overall rating of an venue */
  rating: Maybe<Scalars['Float']['output']>;
  ratings: Array<Rating>;
  reviewCount: Scalars['Int']['output'];
  reviews: ReviewConnection;
  sessions: EventSessionConnection;
  shortId: Scalars['String']['output'];
  slotsToDiscover: Array<Slot>;
  slug: Scalars['String']['output'];
  specialPriceTypes: Array<SpecialPriceType>;
  sports: Array<Sport>;
  suggestedProducts: VenueSuggestedProducts;
  tags: Array<VenueTag>;
  timeZone: Scalars['TimeZone']['output'];
  trainers: TrainerConnection;
  /**
   * This is used in `recommendation page` to show trial cards not bought by the user at a venue
   * @deprecated Dont use this property anymore, as we will deprecate it in favour of venueUser connection
   */
  trialCards: CardConnection;
  venueSearchDebugInformation: Maybe<VenueSearchDebugInformation>;
  widgetSettings: Maybe<WidgetSettings>;
};


export type VenueCheapestPriceArgs = {
  excludeTrialCards?: InputMaybe<Scalars['Boolean']['input']>;
};


export type VenueOfferingsArgs = {
  ignoreCache?: InputMaybe<Scalars['Boolean']['input']>;
};


export type VenueReviewsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VenueSessionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  timeRange: TimeRangeInput;
};


export type VenueSlotsToDiscoverArgs = {
  filters: SlotToDiscoverFilter;
};


export type VenueTrainersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VenueTrialCardsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum VenueAmenityType {
  VENUE_AMENITY_CARDIO = 'VENUE_AMENITY_CARDIO',
  VENUE_AMENITY_CARDPAYMENT = 'VENUE_AMENITY_CARDPAYMENT',
  VENUE_AMENITY_COURSES = 'VENUE_AMENITY_COURSES',
  VENUE_AMENITY_FITNESSDEVICES = 'VENUE_AMENITY_FITNESSDEVICES',
  VENUE_AMENITY_GASTRONOMY = 'VENUE_AMENITY_GASTRONOMY',
  VENUE_AMENITY_LOCKERROOM = 'VENUE_AMENITY_LOCKERROOM',
  VENUE_AMENITY_OTHER = 'VENUE_AMENITY_OTHER',
  VENUE_AMENITY_PARKING = 'VENUE_AMENITY_PARKING',
  VENUE_AMENITY_POOL = 'VENUE_AMENITY_POOL',
  VENUE_AMENITY_SANITARY = 'VENUE_AMENITY_SANITARY',
  VENUE_AMENITY_SHOP = 'VENUE_AMENITY_SHOP',
  VENUE_AMENITY_WELLNESS = 'VENUE_AMENITY_WELLNESS'
}

export enum VenueAttributes {
  VENUE_ATTRIBUTES_CORPORATE_DISCOUNT = 'VENUE_ATTRIBUTES_CORPORATE_DISCOUNT',
  VENUE_ATTRIBUTES_FREE_TRIAL = 'VENUE_ATTRIBUTES_FREE_TRIAL',
  VENUE_ATTRIBUTES_INTRO_OFFER = 'VENUE_ATTRIBUTES_INTRO_OFFER',
  VENUE_ATTRIBUTES_NEW_VENUE = 'VENUE_ATTRIBUTES_NEW_VENUE'
}

export type VenueConnection = {
  __typename: 'VenueConnection';
  edges: Array<VenueEdge>;
  /** The actual "Venue" items */
  nodes: Array<Venue>;
  pageInfo: PageInfo;
};

export type VenueContext = {
  __typename: 'VenueContext';
  meta: MetaData;
  venue: Venue;
};

export type VenueEdge = {
  __typename: 'VenueEdge';
  cursor: Scalars['Cursor']['output'];
  node: Venue;
};

export type VenueFaq = {
  __typename: 'VenueFaq';
  answer: Scalars['String']['output'];
  question: VenueFaqQuestionType;
};

export enum VenueFaqQuestionType {
  CANCEL_INFO = 'CANCEL_INFO',
  EQUIPMENT = 'EQUIPMENT',
  EXTRA_INFO = 'EXTRA_INFO',
  FIRST_TIME = 'FIRST_TIME',
  USP = 'USP'
}

export type VenueFilters = {
  activityAvailabilities?: InputMaybe<Array<ActivityAvailability>>;
  boundingBox: BoundingBoxArgs;
  courtAreas?: InputMaybe<Array<CourtAreaType>>;
  courtAvailability?: InputMaybe<CourtAvailability>;
  courtSurfaces?: InputMaybe<Array<CourtSurfaceType>>;
  districts?: InputMaybe<Array<Scalars['ID']['input']>>;
  reviewCount?: InputMaybe<IntegerValueQuery>;
  /** Introduce minor randomness into the order of venues to make the page appear more recent and ever-changing to search engines. */
  seoShuffle?: InputMaybe<Scalars['Boolean']['input']>;
  sports?: InputMaybe<Array<Scalars['ID']['input']>>;
  venueAmenities?: InputMaybe<Array<VenueAmenityType>>;
  venueAttributes?: InputMaybe<Array<VenueAttributes>>;
  venueOfferingTypes?: InputMaybe<Array<VenueOfferingType>>;
};

export enum VenueOfferingType {
  VENUE_OFFERING_CAMP_ACTIVITY = 'VENUE_OFFERING_CAMP_ACTIVITY',
  VENUE_OFFERING_CLASS_ACTIVITY = 'VENUE_OFFERING_CLASS_ACTIVITY',
  VENUE_OFFERING_COURSE_ACTIVITY = 'VENUE_OFFERING_COURSE_ACTIVITY',
  VENUE_OFFERING_EDUCATION_ACTIVITY = 'VENUE_OFFERING_EDUCATION_ACTIVITY',
  VENUE_OFFERING_EVENT_ACTIVITY = 'VENUE_OFFERING_EVENT_ACTIVITY',
  VENUE_OFFERING_INDOOR_ACTIVITY = 'VENUE_OFFERING_INDOOR_ACTIVITY',
  VENUE_OFFERING_LIVESTREAM = 'VENUE_OFFERING_LIVESTREAM',
  VENUE_OFFERING_OPENTRAINING_ACTIVITY = 'VENUE_OFFERING_OPENTRAINING_ACTIVITY',
  VENUE_OFFERING_OUTDOOR_ACTIVITY = 'VENUE_OFFERING_OUTDOOR_ACTIVITY',
  VENUE_OFFERING_PUBLIC_MATCHES = 'VENUE_OFFERING_PUBLIC_MATCHES',
  VENUE_OFFERING_RETREAT_ACTIVITY = 'VENUE_OFFERING_RETREAT_ACTIVITY',
  VENUE_OFFERING_SHOP = 'VENUE_OFFERING_SHOP',
  VENUE_OFFERING_SPORTS_FIELDS = 'VENUE_OFFERING_SPORTS_FIELDS',
  VENUE_OFFERING_TEAM = 'VENUE_OFFERING_TEAM',
  VENUE_OFFERING_TRAINING_ACTIVITY = 'VENUE_OFFERING_TRAINING_ACTIVITY',
  VENUE_OFFERING_VIDEO = 'VENUE_OFFERING_VIDEO',
  VENUE_OFFERING_WORKSHOP_ACTIVITY = 'VENUE_OFFERING_WORKSHOP_ACTIVITY'
}

export type VenueSearchDebugInformation = {
  __typename: 'VenueSearchDebugInformation';
  score: Scalars['Float']['output'];
  usedRelevancies: Maybe<Array<VenueSearchRelevance>>;
};

export type VenueSearchRelevance = {
  __typename: 'VenueSearchRelevance';
  label: Scalars['String']['output'];
  score: Scalars['Float']['output'];
};

export type VenueSearchResult = SearchResult & {
  __typename: 'VenueSearchResult';
  cityId: Scalars['ID']['output'];
  citySlug: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  location: Location;
  matchedName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  online: Scalars['Boolean']['output'];
  secondaryMatch: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
};

export enum VenueSearchSort {
  CONSIDER_DISTANCE = 'CONSIDER_DISTANCE',
  DEFAULT = 'DEFAULT'
}

export type VenueSuggestedProducts = {
  __typename: 'VenueSuggestedProducts';
  cheapest: Maybe<CheapestProduct>;
  popular: Maybe<Card>;
};

export type VenueTag = {
  __typename: 'VenueTag';
  name: Scalars['String']['output'];
};

/** Voucher is a giftcard you can buy. Once bought a code is generated for anyone to redeem the value with that code */
export type Voucher = {
  __typename: 'Voucher';
  /** The conditions on how the voucher can be applied on the venue */
  conditions: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image: Maybe<Image>;
  name: Scalars['String']['output'];
  /** The date until this voucher is valid. If null the voucher is valid forever */
  validityInterval: Maybe<Interval>;
  value: MonetaryValue;
};

export type WaitingList = {
  __typename: 'WaitingList';
  id: Scalars['ID']['output'];
};

export enum Weekday {
  WEEKDAY_FRIDAY = 'WEEKDAY_FRIDAY',
  WEEKDAY_MONDAY = 'WEEKDAY_MONDAY',
  WEEKDAY_SATURDAY = 'WEEKDAY_SATURDAY',
  WEEKDAY_SUNDAY = 'WEEKDAY_SUNDAY',
  WEEKDAY_THURSDAY = 'WEEKDAY_THURSDAY',
  WEEKDAY_TUESDAY = 'WEEKDAY_TUESDAY',
  WEEKDAY_WEDNESDAY = 'WEEKDAY_WEDNESDAY'
}

export type WidgetSettings = {
  __typename: 'WidgetSettings';
  activitySpotLimitDisplayType: Maybe<ActivitySpotLimitDisplayType>;
  cardsFontColor: Maybe<Scalars['String']['output']>;
  colorBackground: Maybe<Scalars['String']['output']>;
  colorButton: Maybe<Scalars['String']['output']>;
  colorFont: Maybe<Scalars['String']['output']>;
  showActivityLevel: Scalars['Boolean']['output'];
  showActivityPrice: Scalars['Boolean']['output'];
  showActivityResource: Scalars['Boolean']['output'];
  showActivityTrainer: Scalars['Boolean']['output'];
};

export type AccountActivationVerifyEmailMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type AccountActivationVerifyEmailMutation = { verifyEmail: { __typename: 'AuthResult', apiToken: string, registrationSource: RegistrationSource | null, activationLink: string | null, user: { __typename: 'User', id: string, hasVerifiedEmail: boolean, missingEversportsPolicies: Array<{ __typename: 'Policy', id: string }>, family: { __typename: 'Family', id: string } | null, corporateBenefits: { __typename: 'CorporateBenefits', corporatePartner: { __typename: 'CorporatePartner', id: string } } | null } } | { __typename: 'ExpectedErrors', errors: Array<{ __typename: 'ExpectedError', id: string, message: string, path: string | null }> } };

export type ConsentScreenAcceptEversportsPoliciesMutationVariables = Exact<{ [key: string]: never; }>;


export type ConsentScreenAcceptEversportsPoliciesMutation = { acceptEversportsPolicies: { __typename: 'AuthResult', apiToken: string, registrationSource: RegistrationSource | null, activationLink: string | null, user: { __typename: 'User', id: string, hasVerifiedEmail: boolean, missingEversportsPolicies: Array<{ __typename: 'Policy', id: string }>, family: { __typename: 'Family', id: string } | null, corporateBenefits: { __typename: 'CorporateBenefits', corporatePartner: { __typename: 'CorporatePartner', id: string } } | null } } | { __typename: 'ExpectedErrors', errors: Array<{ __typename: 'ExpectedError', id: string, message: string, path: string | null }> } };

export type ConsentScreenAcceptEversportsAndVenuePoliciesMutationVariables = Exact<{
  venueId: Scalars['ID']['input'];
}>;


export type ConsentScreenAcceptEversportsAndVenuePoliciesMutation = { acceptEversportsPolicies: { __typename: 'AuthResult', apiToken: string, registrationSource: RegistrationSource | null, activationLink: string | null, user: { __typename: 'User', id: string, hasVerifiedEmail: boolean, missingEversportsPolicies: Array<{ __typename: 'Policy', id: string }>, corporateBenefits: { __typename: 'CorporateBenefits', corporatePartner: { __typename: 'CorporatePartner', id: string } } | null, family: { __typename: 'Family', id: string } | null } } | { __typename: 'ExpectedErrors', errors: Array<{ __typename: 'ExpectedError', id: string, message: string, path: string | null }> }, acceptVenuePolicies: { __typename: 'AuthResult', apiToken: string, registrationSource: RegistrationSource | null, activationLink: string | null, user: { __typename: 'User', id: string, hasVerifiedEmail: boolean, missingEversportsPolicies: Array<{ __typename: 'Policy', id: string }>, family: { __typename: 'Family', id: string } | null } } | { __typename: 'ExpectedErrors', errors: Array<{ __typename: 'ExpectedError', id: string, message: string, path: string | null }> } };

export type ConsentScreenCurrentPoliciesQueryVariables = Exact<{ [key: string]: never; }>;


export type ConsentScreenCurrentPoliciesQuery = { latestEversportsPolicies: Array<{ __typename: 'Policy', id: string, type: PolicyType, url: string }> };

export type ConsentScreenEversportsAndVenueCurrentPoliciesQueryVariables = Exact<{
  venueId: Scalars['ID']['input'];
}>;


export type ConsentScreenEversportsAndVenueCurrentPoliciesQuery = { latestEversportsPolicies: Array<{ __typename: 'Policy', id: string, type: PolicyType, url: string }>, venue: { __typename: 'Venue', id: string, name: string, company: { __typename: 'Company', id: string, latestPolicies: Array<{ __typename: 'Policy', id: string, type: PolicyType, url: string }> } } };

export type CurrentPolicyWrapperEversportsCurrentPoliciesQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentPolicyWrapperEversportsCurrentPoliciesQuery = { latestEversportsPolicies: Array<{ __typename: 'Policy', id: string, type: PolicyType, url: string }> };

export type FamilyAccountCreationCreateFamilyMutationVariables = Exact<{
  members: Array<Member> | Member;
}>;


export type FamilyAccountCreationCreateFamilyMutation = { createFamily: { __typename: 'ExpectedErrors', errors: Array<{ __typename: 'ExpectedError', id: string, message: string, path: string | null }> } | { __typename: 'Family', id: string, owner: { __typename: 'User', id: string }, members: Array<{ __typename: 'User', id: string, firstName: string, lastName: string }> } };

export type FamilyAccountDisclaimerMeQueryVariables = Exact<{ [key: string]: never; }>;


export type FamilyAccountDisclaimerMeQuery = { me: { __typename: 'User', id: string, family: { __typename: 'Family', id: string } | null } | null };

export type UserAttributesFragment = { __typename: 'User', id: string, firstName: string, lastName: string, email: string, profilePicture: { __typename: 'Image', small: string } | null };

export type FamilyAccountLoginMeQueryVariables = Exact<{ [key: string]: never; }>;


export type FamilyAccountLoginMeQuery = { me: { __typename: 'User', id: string, firstName: string, lastName: string, email: string, family: { __typename: 'Family', id: string, owner: { __typename: 'User', id: string, firstName: string, lastName: string, email: string, profilePicture: { __typename: 'Image', small: string } | null }, members: Array<{ __typename: 'User', id: string, firstName: string, lastName: string, email: string, profilePicture: { __typename: 'Image', small: string } | null }> } | null, profilePicture: { __typename: 'Image', small: string } | null } | null };

export type FamilyAccountMemberCreationAddFamilyMemberMutationVariables = Exact<{
  member: Member;
}>;


export type FamilyAccountMemberCreationAddFamilyMemberMutation = { addFamilyMember: { __typename: 'ExpectedErrors', errors: Array<{ __typename: 'ExpectedError', id: string, message: string, path: string | null }> } | { __typename: 'User', id: string, family: { __typename: 'Family', id: string, members: Array<{ __typename: 'User', id: string, firstName: string, lastName: string, email: string, profilePicture: { __typename: 'Image', small: string } | null }> } | null } };

export type ForgotPasswordRequestPasswordResetMutationVariables = Exact<{
  email: Scalars['String']['input'];
  queryString: Scalars['String']['input'];
  region?: InputMaybe<Scalars['String']['input']>;
}>;


export type ForgotPasswordRequestPasswordResetMutation = { requestPasswordReset: { __typename: 'ExpectedErrors', errors: Array<{ __typename: 'ExpectedError', id: string, message: string, path: string | null }> } | { __typename: 'ResetPasswordResult', activationLink: string | null } };

export type InvitationAcceptInvitationMutationVariables = Exact<{
  token: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
}>;


export type InvitationAcceptInvitationMutation = { acceptInvitation: { __typename: 'AuthResult', apiToken: string, registrationSource: RegistrationSource | null, activationLink: string | null, user: { __typename: 'User', id: string, hasVerifiedEmail: boolean, missingEversportsPolicies: Array<{ __typename: 'Policy', id: string }>, family: { __typename: 'Family', id: string } | null, corporateBenefits: { __typename: 'CorporateBenefits', corporatePartner: { __typename: 'CorporatePartner', id: string } } | null } } | { __typename: 'ExpectedErrors', errors: Array<{ __typename: 'ExpectedError', id: string, message: string, path: string | null }> } };

export type InvitationInvitationQueryVariables = Exact<{
  token: Scalars['ID']['input'];
}>;


export type InvitationInvitationQuery = { invitation: { __typename: 'Invitation', isEversportsUser: boolean, showAppPromotion: boolean, state: InvitationState, customers: Array<{ __typename: 'Customer', id: string, isSubscribedToNewsletter: boolean, customerGroup: { __typename: 'CustomerGroup', id: string, company: { __typename: 'Company', id: string, name: string, latestPolicies: Array<{ __typename: 'Policy', id: string, type: PolicyType, url: string }> } } }>, invitedBy: { __typename: 'User', id: string, firstName: string, lastName: string } | { __typename: 'Venue', id: string, name: string }, user: { __typename: 'User', id: string, firstName: string, email: string } } };

export type InvitationSuccessInvitationQueryVariables = Exact<{
  token: Scalars['ID']['input'];
}>;


export type InvitationSuccessInvitationQuery = { invitation: { __typename: 'Invitation', state: InvitationState, customers: Array<{ __typename: 'Customer', id: string, isSubscribedToNewsletter: boolean }>, invitedBy: { __typename: 'User', id: string } | { __typename: 'Venue', id: string, name: string, company: { __typename: 'Company', name: string, venues: Array<{ __typename: 'Venue', id: string, name: string, shortId: string, logo: { __typename: 'Image', large: string } | null, images: Array<{ __typename: 'Image', large: string }> }> } } } };

export type InvitationSuccessSubscribeToNewsletterMutationVariables = Exact<{
  customerId: Scalars['ID']['input'];
}>;


export type InvitationSuccessSubscribeToNewsletterMutation = { subscribeToNewsletter: { __typename: 'Customer', id: string } };

export type LoginCredentialLoginMutationVariables = Exact<{
  params: AuthParamsInput;
  credentials: CredentialLoginInput;
}>;


export type LoginCredentialLoginMutation = { credentialLogin: { __typename: 'AuthResult', apiToken: string, registrationSource: RegistrationSource | null, activationLink: string | null, user: { __typename: 'User', id: string, hasVerifiedEmail: boolean, missingEversportsPolicies: Array<{ __typename: 'Policy', id: string }>, family: { __typename: 'Family', id: string } | null, corporateBenefits: { __typename: 'CorporateBenefits', corporatePartner: { __typename: 'CorporatePartner', id: string } } | null } } | { __typename: 'ExpectedErrors', errors: Array<{ __typename: 'ExpectedError', id: string, message: string, path: string | null }> } };

export type LoginRequestVerificationEmailMutationMutationVariables = Exact<{ [key: string]: never; }>;


export type LoginRequestVerificationEmailMutationMutation = { requestVerificationEmail: { __typename: 'ExpectedErrors', errors: Array<{ __typename: 'ExpectedError', id: string, path: string | null, message: string }> } | { __typename: 'RequestVerificationResult', activationLink: string | null } };

export type PasswordResetResetPasswordMutationVariables = Exact<{
  token: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type PasswordResetResetPasswordMutation = { resetPassword: { __typename: 'AuthResult', apiToken: string, registrationSource: RegistrationSource | null, activationLink: string | null, user: { __typename: 'User', id: string, hasVerifiedEmail: boolean, missingEversportsPolicies: Array<{ __typename: 'Policy', id: string }>, family: { __typename: 'Family', id: string } | null, corporateBenefits: { __typename: 'CorporateBenefits', corporatePartner: { __typename: 'CorporatePartner', id: string } } | null } } | { __typename: 'ExpectedErrors', errors: Array<{ __typename: 'ExpectedError', id: string, message: string, path: string | null }> } };

export type PolicyWrapperCurrentPoliciesQueryVariables = Exact<{ [key: string]: never; }>;


export type PolicyWrapperCurrentPoliciesQuery = { latestEversportsPolicies: Array<{ __typename: 'Policy', id: string, type: PolicyType, url: string }> };

export type RedirectAppTextWrapperVenueQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RedirectAppTextWrapperVenueQuery = { venue: { __typename: 'Venue', id: string, name: string } };

export type RegistrationCredentialRegistrationMutationVariables = Exact<{
  params: AuthParamsInput;
  profile: RegistrationProfileInput;
  settings: RegistrationSettingsInput;
}>;


export type RegistrationCredentialRegistrationMutation = { credentialRegistration: { __typename: 'AuthResult', apiToken: string, registrationSource: RegistrationSource | null, activationLink: string | null, user: { __typename: 'User', id: string, hasVerifiedEmail: boolean, missingEversportsPolicies: Array<{ __typename: 'Policy', id: string }>, family: { __typename: 'Family', id: string } | null, corporateBenefits: { __typename: 'CorporateBenefits', corporatePartner: { __typename: 'CorporatePartner', id: string } } | null } } | { __typename: 'ExpectedErrors', errors: Array<{ __typename: 'ExpectedError', id: string, message: string, path: string | null }> } };

export type RegistrationVenueQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RegistrationVenueQuery = { venue: { __typename: 'Venue', id: string, hideNewsletterOnRegistration: boolean } };

export type SelfMeQueryVariables = Exact<{ [key: string]: never; }>;


export type SelfMeQuery = { me: { __typename: 'User', id: string, email: string, firstName: string, lastName: string } | null };

export type UseCorporateInvitationQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
}>;


export type UseCorporateInvitationQuery = { corporateInvitation: { __typename: 'CorporateInvitationData', hasSpecialPriceAccess: boolean, corporatePartner: { __typename: 'CorporatePartner', id: string, name: string, description: string, additionalDescription: string | null, logo: { __typename: 'Image', large: string } | null }, corporateBudget: { __typename: 'CorporateBudget', type: BudgetType, expiry: string | null, interval: BudgetInterval | null, employeeLimit: { __typename: 'Limited', displayCapped: boolean, limit: { __typename: 'MonetaryValue', value: number, currency: string } } | { __typename: 'Unlimited' }, totalLimit: { __typename: 'Limited', displayCapped: boolean, limit: { __typename: 'MonetaryValue', value: number, currency: string } } | { __typename: 'Unlimited' } } | null } };

export type UseCorporateUserMeQueryVariables = Exact<{ [key: string]: never; }>;


export type UseCorporateUserMeQuery = { me: { __typename: 'User', id: string, corporateBenefits: { __typename: 'CorporateBenefits', corporatePartner: { __typename: 'CorporatePartner', id: string, name: string } } | null } | null };

export type UseFamilyLoginFamilyLoginMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type UseFamilyLoginFamilyLoginMutation = { familyLogin: { __typename: 'AuthResult', apiToken: string, user: { __typename: 'User', id: string } } | { __typename: 'ExpectedErrors', errors: Array<{ __typename: 'ExpectedError', id: string, message: string, path: string | null }> } };

export type UseVenueQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UseVenueQuery = { venue: { __typename: 'Venue', id: string, name: string, logo: { __typename: 'Image', large: string } | null, images: Array<{ __typename: 'Image', large: string }> } };

export type UseSocialLoginExternalLoginMutationVariables = Exact<{
  params: AuthParamsInput;
  credentials: ExternalLoginInput;
}>;


export type UseSocialLoginExternalLoginMutation = { externalLogin: { __typename: 'AuthResult', apiToken: string, registrationSource: RegistrationSource | null, activationLink: string | null, user: { __typename: 'User', id: string, hasVerifiedEmail: boolean, missingEversportsPolicies: Array<{ __typename: 'Policy', id: string }>, family: { __typename: 'Family', id: string } | null, corporateBenefits: { __typename: 'CorporateBenefits', corporatePartner: { __typename: 'CorporatePartner', id: string } } | null } } | { __typename: 'ExpectedErrors', errors: Array<{ __typename: 'ExpectedError', id: string, message: string, path: string | null }> } };

export const UserAttributesFragmentDoc = gql`
    fragment userAttributes on User {
  id
  firstName
  lastName
  email
  profilePicture {
    small
  }
}
    `;
export const AccountActivationVerifyEmailDocument = gql`
    mutation AccountActivationVerifyEmail($token: String!) {
  verifyEmail(token: $token) {
    ... on AuthResult {
      apiToken
      user {
        id
        hasVerifiedEmail
        missingEversportsPolicies {
          id
        }
        family {
          id
        }
        corporateBenefits {
          corporatePartner {
            id
          }
        }
      }
      registrationSource
      activationLink
    }
    ... on ExpectedErrors {
      errors {
        id
        message
        path
      }
    }
  }
}
    `;
export type AccountActivationVerifyEmailMutationFn = Apollo.MutationFunction<AccountActivationVerifyEmailMutation, AccountActivationVerifyEmailMutationVariables>;

/**
 * __useAccountActivationVerifyEmailMutation__
 *
 * To run a mutation, you first call `useAccountActivationVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountActivationVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountActivationVerifyEmailMutation, { data, loading, error }] = useAccountActivationVerifyEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAccountActivationVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<AccountActivationVerifyEmailMutation, AccountActivationVerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AccountActivationVerifyEmailMutation, AccountActivationVerifyEmailMutationVariables>(AccountActivationVerifyEmailDocument, options);
      }
export type AccountActivationVerifyEmailMutationHookResult = ReturnType<typeof useAccountActivationVerifyEmailMutation>;
export type AccountActivationVerifyEmailMutationResult = Apollo.MutationResult<AccountActivationVerifyEmailMutation>;
export type AccountActivationVerifyEmailMutationOptions = Apollo.BaseMutationOptions<AccountActivationVerifyEmailMutation, AccountActivationVerifyEmailMutationVariables>;
export const ConsentScreenAcceptEversportsPoliciesDocument = gql`
    mutation ConsentScreenAcceptEversportsPolicies {
  acceptEversportsPolicies {
    ... on AuthResult {
      apiToken
      user {
        id
        hasVerifiedEmail
        missingEversportsPolicies {
          id
        }
        family {
          id
        }
        corporateBenefits {
          corporatePartner {
            id
          }
        }
      }
      registrationSource
      activationLink
    }
    ... on ExpectedErrors {
      errors {
        id
        message
        path
      }
    }
  }
}
    `;
export type ConsentScreenAcceptEversportsPoliciesMutationFn = Apollo.MutationFunction<ConsentScreenAcceptEversportsPoliciesMutation, ConsentScreenAcceptEversportsPoliciesMutationVariables>;

/**
 * __useConsentScreenAcceptEversportsPoliciesMutation__
 *
 * To run a mutation, you first call `useConsentScreenAcceptEversportsPoliciesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsentScreenAcceptEversportsPoliciesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consentScreenAcceptEversportsPoliciesMutation, { data, loading, error }] = useConsentScreenAcceptEversportsPoliciesMutation({
 *   variables: {
 *   },
 * });
 */
export function useConsentScreenAcceptEversportsPoliciesMutation(baseOptions?: Apollo.MutationHookOptions<ConsentScreenAcceptEversportsPoliciesMutation, ConsentScreenAcceptEversportsPoliciesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConsentScreenAcceptEversportsPoliciesMutation, ConsentScreenAcceptEversportsPoliciesMutationVariables>(ConsentScreenAcceptEversportsPoliciesDocument, options);
      }
export type ConsentScreenAcceptEversportsPoliciesMutationHookResult = ReturnType<typeof useConsentScreenAcceptEversportsPoliciesMutation>;
export type ConsentScreenAcceptEversportsPoliciesMutationResult = Apollo.MutationResult<ConsentScreenAcceptEversportsPoliciesMutation>;
export type ConsentScreenAcceptEversportsPoliciesMutationOptions = Apollo.BaseMutationOptions<ConsentScreenAcceptEversportsPoliciesMutation, ConsentScreenAcceptEversportsPoliciesMutationVariables>;
export const ConsentScreenAcceptEversportsAndVenuePoliciesDocument = gql`
    mutation ConsentScreenAcceptEversportsAndVenuePolicies($venueId: ID!) {
  acceptEversportsPolicies {
    ... on AuthResult {
      apiToken
      user {
        id
        hasVerifiedEmail
        missingEversportsPolicies {
          id
        }
        corporateBenefits {
          corporatePartner {
            id
          }
        }
        family {
          id
        }
      }
      registrationSource
      activationLink
    }
    ... on ExpectedErrors {
      errors {
        id
        message
        path
      }
    }
  }
  acceptVenuePolicies(venueId: $venueId) {
    ... on AuthResult {
      apiToken
      user {
        id
        hasVerifiedEmail
        missingEversportsPolicies {
          id
        }
        family {
          id
        }
      }
      registrationSource
      activationLink
    }
    ... on ExpectedErrors {
      errors {
        id
        message
        path
      }
    }
  }
}
    `;
export type ConsentScreenAcceptEversportsAndVenuePoliciesMutationFn = Apollo.MutationFunction<ConsentScreenAcceptEversportsAndVenuePoliciesMutation, ConsentScreenAcceptEversportsAndVenuePoliciesMutationVariables>;

/**
 * __useConsentScreenAcceptEversportsAndVenuePoliciesMutation__
 *
 * To run a mutation, you first call `useConsentScreenAcceptEversportsAndVenuePoliciesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsentScreenAcceptEversportsAndVenuePoliciesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consentScreenAcceptEversportsAndVenuePoliciesMutation, { data, loading, error }] = useConsentScreenAcceptEversportsAndVenuePoliciesMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useConsentScreenAcceptEversportsAndVenuePoliciesMutation(baseOptions?: Apollo.MutationHookOptions<ConsentScreenAcceptEversportsAndVenuePoliciesMutation, ConsentScreenAcceptEversportsAndVenuePoliciesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConsentScreenAcceptEversportsAndVenuePoliciesMutation, ConsentScreenAcceptEversportsAndVenuePoliciesMutationVariables>(ConsentScreenAcceptEversportsAndVenuePoliciesDocument, options);
      }
export type ConsentScreenAcceptEversportsAndVenuePoliciesMutationHookResult = ReturnType<typeof useConsentScreenAcceptEversportsAndVenuePoliciesMutation>;
export type ConsentScreenAcceptEversportsAndVenuePoliciesMutationResult = Apollo.MutationResult<ConsentScreenAcceptEversportsAndVenuePoliciesMutation>;
export type ConsentScreenAcceptEversportsAndVenuePoliciesMutationOptions = Apollo.BaseMutationOptions<ConsentScreenAcceptEversportsAndVenuePoliciesMutation, ConsentScreenAcceptEversportsAndVenuePoliciesMutationVariables>;
export const ConsentScreenCurrentPoliciesDocument = gql`
    query ConsentScreenCurrentPolicies {
  latestEversportsPolicies {
    id
    type
    url
  }
}
    `;

/**
 * __useConsentScreenCurrentPoliciesQuery__
 *
 * To run a query within a React component, call `useConsentScreenCurrentPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsentScreenCurrentPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsentScreenCurrentPoliciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useConsentScreenCurrentPoliciesQuery(baseOptions?: Apollo.QueryHookOptions<ConsentScreenCurrentPoliciesQuery, ConsentScreenCurrentPoliciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsentScreenCurrentPoliciesQuery, ConsentScreenCurrentPoliciesQueryVariables>(ConsentScreenCurrentPoliciesDocument, options);
      }
export function useConsentScreenCurrentPoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsentScreenCurrentPoliciesQuery, ConsentScreenCurrentPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsentScreenCurrentPoliciesQuery, ConsentScreenCurrentPoliciesQueryVariables>(ConsentScreenCurrentPoliciesDocument, options);
        }
export function useConsentScreenCurrentPoliciesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ConsentScreenCurrentPoliciesQuery, ConsentScreenCurrentPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConsentScreenCurrentPoliciesQuery, ConsentScreenCurrentPoliciesQueryVariables>(ConsentScreenCurrentPoliciesDocument, options);
        }
export type ConsentScreenCurrentPoliciesQueryHookResult = ReturnType<typeof useConsentScreenCurrentPoliciesQuery>;
export type ConsentScreenCurrentPoliciesLazyQueryHookResult = ReturnType<typeof useConsentScreenCurrentPoliciesLazyQuery>;
export type ConsentScreenCurrentPoliciesSuspenseQueryHookResult = ReturnType<typeof useConsentScreenCurrentPoliciesSuspenseQuery>;
export type ConsentScreenCurrentPoliciesQueryResult = Apollo.QueryResult<ConsentScreenCurrentPoliciesQuery, ConsentScreenCurrentPoliciesQueryVariables>;
export const ConsentScreenEversportsAndVenueCurrentPoliciesDocument = gql`
    query ConsentScreenEversportsAndVenueCurrentPolicies($venueId: ID!) {
  latestEversportsPolicies {
    id
    type
    url
  }
  venue(venueId: $venueId) {
    id
    name
    company {
      id
      latestPolicies {
        id
        type
        url
      }
    }
  }
}
    `;

/**
 * __useConsentScreenEversportsAndVenueCurrentPoliciesQuery__
 *
 * To run a query within a React component, call `useConsentScreenEversportsAndVenueCurrentPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsentScreenEversportsAndVenueCurrentPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsentScreenEversportsAndVenueCurrentPoliciesQuery({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useConsentScreenEversportsAndVenueCurrentPoliciesQuery(baseOptions: Apollo.QueryHookOptions<ConsentScreenEversportsAndVenueCurrentPoliciesQuery, ConsentScreenEversportsAndVenueCurrentPoliciesQueryVariables> & ({ variables: ConsentScreenEversportsAndVenueCurrentPoliciesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsentScreenEversportsAndVenueCurrentPoliciesQuery, ConsentScreenEversportsAndVenueCurrentPoliciesQueryVariables>(ConsentScreenEversportsAndVenueCurrentPoliciesDocument, options);
      }
export function useConsentScreenEversportsAndVenueCurrentPoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsentScreenEversportsAndVenueCurrentPoliciesQuery, ConsentScreenEversportsAndVenueCurrentPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsentScreenEversportsAndVenueCurrentPoliciesQuery, ConsentScreenEversportsAndVenueCurrentPoliciesQueryVariables>(ConsentScreenEversportsAndVenueCurrentPoliciesDocument, options);
        }
export function useConsentScreenEversportsAndVenueCurrentPoliciesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ConsentScreenEversportsAndVenueCurrentPoliciesQuery, ConsentScreenEversportsAndVenueCurrentPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConsentScreenEversportsAndVenueCurrentPoliciesQuery, ConsentScreenEversportsAndVenueCurrentPoliciesQueryVariables>(ConsentScreenEversportsAndVenueCurrentPoliciesDocument, options);
        }
export type ConsentScreenEversportsAndVenueCurrentPoliciesQueryHookResult = ReturnType<typeof useConsentScreenEversportsAndVenueCurrentPoliciesQuery>;
export type ConsentScreenEversportsAndVenueCurrentPoliciesLazyQueryHookResult = ReturnType<typeof useConsentScreenEversportsAndVenueCurrentPoliciesLazyQuery>;
export type ConsentScreenEversportsAndVenueCurrentPoliciesSuspenseQueryHookResult = ReturnType<typeof useConsentScreenEversportsAndVenueCurrentPoliciesSuspenseQuery>;
export type ConsentScreenEversportsAndVenueCurrentPoliciesQueryResult = Apollo.QueryResult<ConsentScreenEversportsAndVenueCurrentPoliciesQuery, ConsentScreenEversportsAndVenueCurrentPoliciesQueryVariables>;
export const CurrentPolicyWrapperEversportsCurrentPoliciesDocument = gql`
    query CurrentPolicyWrapperEversportsCurrentPolicies {
  latestEversportsPolicies {
    id
    type
    url
  }
}
    `;

/**
 * __useCurrentPolicyWrapperEversportsCurrentPoliciesQuery__
 *
 * To run a query within a React component, call `useCurrentPolicyWrapperEversportsCurrentPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentPolicyWrapperEversportsCurrentPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentPolicyWrapperEversportsCurrentPoliciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentPolicyWrapperEversportsCurrentPoliciesQuery(baseOptions?: Apollo.QueryHookOptions<CurrentPolicyWrapperEversportsCurrentPoliciesQuery, CurrentPolicyWrapperEversportsCurrentPoliciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentPolicyWrapperEversportsCurrentPoliciesQuery, CurrentPolicyWrapperEversportsCurrentPoliciesQueryVariables>(CurrentPolicyWrapperEversportsCurrentPoliciesDocument, options);
      }
export function useCurrentPolicyWrapperEversportsCurrentPoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentPolicyWrapperEversportsCurrentPoliciesQuery, CurrentPolicyWrapperEversportsCurrentPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentPolicyWrapperEversportsCurrentPoliciesQuery, CurrentPolicyWrapperEversportsCurrentPoliciesQueryVariables>(CurrentPolicyWrapperEversportsCurrentPoliciesDocument, options);
        }
export function useCurrentPolicyWrapperEversportsCurrentPoliciesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CurrentPolicyWrapperEversportsCurrentPoliciesQuery, CurrentPolicyWrapperEversportsCurrentPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentPolicyWrapperEversportsCurrentPoliciesQuery, CurrentPolicyWrapperEversportsCurrentPoliciesQueryVariables>(CurrentPolicyWrapperEversportsCurrentPoliciesDocument, options);
        }
export type CurrentPolicyWrapperEversportsCurrentPoliciesQueryHookResult = ReturnType<typeof useCurrentPolicyWrapperEversportsCurrentPoliciesQuery>;
export type CurrentPolicyWrapperEversportsCurrentPoliciesLazyQueryHookResult = ReturnType<typeof useCurrentPolicyWrapperEversportsCurrentPoliciesLazyQuery>;
export type CurrentPolicyWrapperEversportsCurrentPoliciesSuspenseQueryHookResult = ReturnType<typeof useCurrentPolicyWrapperEversportsCurrentPoliciesSuspenseQuery>;
export type CurrentPolicyWrapperEversportsCurrentPoliciesQueryResult = Apollo.QueryResult<CurrentPolicyWrapperEversportsCurrentPoliciesQuery, CurrentPolicyWrapperEversportsCurrentPoliciesQueryVariables>;
export const FamilyAccountCreationCreateFamilyDocument = gql`
    mutation FamilyAccountCreationCreateFamily($members: [Member!]!) {
  createFamily(members: $members) {
    ... on Family {
      id
      owner {
        id
      }
      members {
        id
        firstName
        lastName
      }
    }
    ... on ExpectedErrors {
      errors {
        id
        message
        path
      }
    }
  }
}
    `;
export type FamilyAccountCreationCreateFamilyMutationFn = Apollo.MutationFunction<FamilyAccountCreationCreateFamilyMutation, FamilyAccountCreationCreateFamilyMutationVariables>;

/**
 * __useFamilyAccountCreationCreateFamilyMutation__
 *
 * To run a mutation, you first call `useFamilyAccountCreationCreateFamilyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFamilyAccountCreationCreateFamilyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [familyAccountCreationCreateFamilyMutation, { data, loading, error }] = useFamilyAccountCreationCreateFamilyMutation({
 *   variables: {
 *      members: // value for 'members'
 *   },
 * });
 */
export function useFamilyAccountCreationCreateFamilyMutation(baseOptions?: Apollo.MutationHookOptions<FamilyAccountCreationCreateFamilyMutation, FamilyAccountCreationCreateFamilyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FamilyAccountCreationCreateFamilyMutation, FamilyAccountCreationCreateFamilyMutationVariables>(FamilyAccountCreationCreateFamilyDocument, options);
      }
export type FamilyAccountCreationCreateFamilyMutationHookResult = ReturnType<typeof useFamilyAccountCreationCreateFamilyMutation>;
export type FamilyAccountCreationCreateFamilyMutationResult = Apollo.MutationResult<FamilyAccountCreationCreateFamilyMutation>;
export type FamilyAccountCreationCreateFamilyMutationOptions = Apollo.BaseMutationOptions<FamilyAccountCreationCreateFamilyMutation, FamilyAccountCreationCreateFamilyMutationVariables>;
export const FamilyAccountDisclaimerMeDocument = gql`
    query FamilyAccountDisclaimerMe {
  me {
    id
    family {
      id
    }
  }
}
    `;

/**
 * __useFamilyAccountDisclaimerMeQuery__
 *
 * To run a query within a React component, call `useFamilyAccountDisclaimerMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFamilyAccountDisclaimerMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFamilyAccountDisclaimerMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useFamilyAccountDisclaimerMeQuery(baseOptions?: Apollo.QueryHookOptions<FamilyAccountDisclaimerMeQuery, FamilyAccountDisclaimerMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FamilyAccountDisclaimerMeQuery, FamilyAccountDisclaimerMeQueryVariables>(FamilyAccountDisclaimerMeDocument, options);
      }
export function useFamilyAccountDisclaimerMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FamilyAccountDisclaimerMeQuery, FamilyAccountDisclaimerMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FamilyAccountDisclaimerMeQuery, FamilyAccountDisclaimerMeQueryVariables>(FamilyAccountDisclaimerMeDocument, options);
        }
export function useFamilyAccountDisclaimerMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FamilyAccountDisclaimerMeQuery, FamilyAccountDisclaimerMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FamilyAccountDisclaimerMeQuery, FamilyAccountDisclaimerMeQueryVariables>(FamilyAccountDisclaimerMeDocument, options);
        }
export type FamilyAccountDisclaimerMeQueryHookResult = ReturnType<typeof useFamilyAccountDisclaimerMeQuery>;
export type FamilyAccountDisclaimerMeLazyQueryHookResult = ReturnType<typeof useFamilyAccountDisclaimerMeLazyQuery>;
export type FamilyAccountDisclaimerMeSuspenseQueryHookResult = ReturnType<typeof useFamilyAccountDisclaimerMeSuspenseQuery>;
export type FamilyAccountDisclaimerMeQueryResult = Apollo.QueryResult<FamilyAccountDisclaimerMeQuery, FamilyAccountDisclaimerMeQueryVariables>;
export const FamilyAccountLoginMeDocument = gql`
    query FamilyAccountLoginMe {
  me {
    ...userAttributes
    family {
      id
      owner {
        ...userAttributes
      }
      members {
        ...userAttributes
      }
    }
  }
}
    ${UserAttributesFragmentDoc}`;

/**
 * __useFamilyAccountLoginMeQuery__
 *
 * To run a query within a React component, call `useFamilyAccountLoginMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFamilyAccountLoginMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFamilyAccountLoginMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useFamilyAccountLoginMeQuery(baseOptions?: Apollo.QueryHookOptions<FamilyAccountLoginMeQuery, FamilyAccountLoginMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FamilyAccountLoginMeQuery, FamilyAccountLoginMeQueryVariables>(FamilyAccountLoginMeDocument, options);
      }
export function useFamilyAccountLoginMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FamilyAccountLoginMeQuery, FamilyAccountLoginMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FamilyAccountLoginMeQuery, FamilyAccountLoginMeQueryVariables>(FamilyAccountLoginMeDocument, options);
        }
export function useFamilyAccountLoginMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FamilyAccountLoginMeQuery, FamilyAccountLoginMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FamilyAccountLoginMeQuery, FamilyAccountLoginMeQueryVariables>(FamilyAccountLoginMeDocument, options);
        }
export type FamilyAccountLoginMeQueryHookResult = ReturnType<typeof useFamilyAccountLoginMeQuery>;
export type FamilyAccountLoginMeLazyQueryHookResult = ReturnType<typeof useFamilyAccountLoginMeLazyQuery>;
export type FamilyAccountLoginMeSuspenseQueryHookResult = ReturnType<typeof useFamilyAccountLoginMeSuspenseQuery>;
export type FamilyAccountLoginMeQueryResult = Apollo.QueryResult<FamilyAccountLoginMeQuery, FamilyAccountLoginMeQueryVariables>;
export const FamilyAccountMemberCreationAddFamilyMemberDocument = gql`
    mutation FamilyAccountMemberCreationAddFamilyMember($member: Member!) {
  addFamilyMember(member: $member) {
    ... on User {
      id
      family {
        id
        members {
          id
          firstName
          lastName
          email
          profilePicture {
            small
          }
        }
      }
    }
    ... on ExpectedErrors {
      errors {
        id
        message
        path
      }
    }
  }
}
    `;
export type FamilyAccountMemberCreationAddFamilyMemberMutationFn = Apollo.MutationFunction<FamilyAccountMemberCreationAddFamilyMemberMutation, FamilyAccountMemberCreationAddFamilyMemberMutationVariables>;

/**
 * __useFamilyAccountMemberCreationAddFamilyMemberMutation__
 *
 * To run a mutation, you first call `useFamilyAccountMemberCreationAddFamilyMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFamilyAccountMemberCreationAddFamilyMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [familyAccountMemberCreationAddFamilyMemberMutation, { data, loading, error }] = useFamilyAccountMemberCreationAddFamilyMemberMutation({
 *   variables: {
 *      member: // value for 'member'
 *   },
 * });
 */
export function useFamilyAccountMemberCreationAddFamilyMemberMutation(baseOptions?: Apollo.MutationHookOptions<FamilyAccountMemberCreationAddFamilyMemberMutation, FamilyAccountMemberCreationAddFamilyMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FamilyAccountMemberCreationAddFamilyMemberMutation, FamilyAccountMemberCreationAddFamilyMemberMutationVariables>(FamilyAccountMemberCreationAddFamilyMemberDocument, options);
      }
export type FamilyAccountMemberCreationAddFamilyMemberMutationHookResult = ReturnType<typeof useFamilyAccountMemberCreationAddFamilyMemberMutation>;
export type FamilyAccountMemberCreationAddFamilyMemberMutationResult = Apollo.MutationResult<FamilyAccountMemberCreationAddFamilyMemberMutation>;
export type FamilyAccountMemberCreationAddFamilyMemberMutationOptions = Apollo.BaseMutationOptions<FamilyAccountMemberCreationAddFamilyMemberMutation, FamilyAccountMemberCreationAddFamilyMemberMutationVariables>;
export const ForgotPasswordRequestPasswordResetDocument = gql`
    mutation ForgotPasswordRequestPasswordReset($email: String!, $queryString: String!, $region: String) {
  requestPasswordReset(email: $email, queryString: $queryString, region: $region) {
    ... on ResetPasswordResult {
      activationLink
    }
    ... on ExpectedErrors {
      errors {
        id
        message
        path
      }
    }
  }
}
    `;
export type ForgotPasswordRequestPasswordResetMutationFn = Apollo.MutationFunction<ForgotPasswordRequestPasswordResetMutation, ForgotPasswordRequestPasswordResetMutationVariables>;

/**
 * __useForgotPasswordRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useForgotPasswordRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordRequestPasswordResetMutation, { data, loading, error }] = useForgotPasswordRequestPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *      queryString: // value for 'queryString'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useForgotPasswordRequestPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordRequestPasswordResetMutation, ForgotPasswordRequestPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordRequestPasswordResetMutation, ForgotPasswordRequestPasswordResetMutationVariables>(ForgotPasswordRequestPasswordResetDocument, options);
      }
export type ForgotPasswordRequestPasswordResetMutationHookResult = ReturnType<typeof useForgotPasswordRequestPasswordResetMutation>;
export type ForgotPasswordRequestPasswordResetMutationResult = Apollo.MutationResult<ForgotPasswordRequestPasswordResetMutation>;
export type ForgotPasswordRequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordRequestPasswordResetMutation, ForgotPasswordRequestPasswordResetMutationVariables>;
export const InvitationAcceptInvitationDocument = gql`
    mutation InvitationAcceptInvitation($token: String!, $password: String) {
  acceptInvitation(token: $token, password: $password) {
    ... on AuthResult {
      apiToken
      user {
        id
        hasVerifiedEmail
        missingEversportsPolicies {
          id
        }
        family {
          id
        }
        corporateBenefits {
          corporatePartner {
            id
          }
        }
      }
      registrationSource
      activationLink
    }
    ... on ExpectedErrors {
      errors {
        id
        message
        path
      }
    }
  }
}
    `;
export type InvitationAcceptInvitationMutationFn = Apollo.MutationFunction<InvitationAcceptInvitationMutation, InvitationAcceptInvitationMutationVariables>;

/**
 * __useInvitationAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useInvitationAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvitationAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invitationAcceptInvitationMutation, { data, loading, error }] = useInvitationAcceptInvitationMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useInvitationAcceptInvitationMutation(baseOptions?: Apollo.MutationHookOptions<InvitationAcceptInvitationMutation, InvitationAcceptInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvitationAcceptInvitationMutation, InvitationAcceptInvitationMutationVariables>(InvitationAcceptInvitationDocument, options);
      }
export type InvitationAcceptInvitationMutationHookResult = ReturnType<typeof useInvitationAcceptInvitationMutation>;
export type InvitationAcceptInvitationMutationResult = Apollo.MutationResult<InvitationAcceptInvitationMutation>;
export type InvitationAcceptInvitationMutationOptions = Apollo.BaseMutationOptions<InvitationAcceptInvitationMutation, InvitationAcceptInvitationMutationVariables>;
export const InvitationInvitationDocument = gql`
    query InvitationInvitation($token: ID!) {
  invitation(invitationId: $token) {
    customers {
      id
      isSubscribedToNewsletter
      customerGroup {
        id
        company {
          id
          name
          latestPolicies {
            id
            type
            url
          }
        }
      }
    }
    invitedBy {
      ... on Venue {
        id
        name
      }
      ... on User {
        id
        firstName
        lastName
      }
    }
    user {
      id
      firstName
      email
    }
    isEversportsUser
    showAppPromotion
    state
  }
}
    `;

/**
 * __useInvitationInvitationQuery__
 *
 * To run a query within a React component, call `useInvitationInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationInvitationQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useInvitationInvitationQuery(baseOptions: Apollo.QueryHookOptions<InvitationInvitationQuery, InvitationInvitationQueryVariables> & ({ variables: InvitationInvitationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvitationInvitationQuery, InvitationInvitationQueryVariables>(InvitationInvitationDocument, options);
      }
export function useInvitationInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvitationInvitationQuery, InvitationInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvitationInvitationQuery, InvitationInvitationQueryVariables>(InvitationInvitationDocument, options);
        }
export function useInvitationInvitationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InvitationInvitationQuery, InvitationInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InvitationInvitationQuery, InvitationInvitationQueryVariables>(InvitationInvitationDocument, options);
        }
export type InvitationInvitationQueryHookResult = ReturnType<typeof useInvitationInvitationQuery>;
export type InvitationInvitationLazyQueryHookResult = ReturnType<typeof useInvitationInvitationLazyQuery>;
export type InvitationInvitationSuspenseQueryHookResult = ReturnType<typeof useInvitationInvitationSuspenseQuery>;
export type InvitationInvitationQueryResult = Apollo.QueryResult<InvitationInvitationQuery, InvitationInvitationQueryVariables>;
export const InvitationSuccessInvitationDocument = gql`
    query InvitationSuccessInvitation($token: ID!) {
  invitation(invitationId: $token) {
    state
    customers {
      id
      isSubscribedToNewsletter
    }
    invitedBy {
      ... on Venue {
        id
        name
        company {
          name
          venues {
            id
            name
            shortId
            logo {
              large
            }
            images {
              large
            }
          }
        }
      }
      ... on User {
        id
      }
    }
  }
}
    `;

/**
 * __useInvitationSuccessInvitationQuery__
 *
 * To run a query within a React component, call `useInvitationSuccessInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationSuccessInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationSuccessInvitationQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useInvitationSuccessInvitationQuery(baseOptions: Apollo.QueryHookOptions<InvitationSuccessInvitationQuery, InvitationSuccessInvitationQueryVariables> & ({ variables: InvitationSuccessInvitationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvitationSuccessInvitationQuery, InvitationSuccessInvitationQueryVariables>(InvitationSuccessInvitationDocument, options);
      }
export function useInvitationSuccessInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvitationSuccessInvitationQuery, InvitationSuccessInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvitationSuccessInvitationQuery, InvitationSuccessInvitationQueryVariables>(InvitationSuccessInvitationDocument, options);
        }
export function useInvitationSuccessInvitationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InvitationSuccessInvitationQuery, InvitationSuccessInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InvitationSuccessInvitationQuery, InvitationSuccessInvitationQueryVariables>(InvitationSuccessInvitationDocument, options);
        }
export type InvitationSuccessInvitationQueryHookResult = ReturnType<typeof useInvitationSuccessInvitationQuery>;
export type InvitationSuccessInvitationLazyQueryHookResult = ReturnType<typeof useInvitationSuccessInvitationLazyQuery>;
export type InvitationSuccessInvitationSuspenseQueryHookResult = ReturnType<typeof useInvitationSuccessInvitationSuspenseQuery>;
export type InvitationSuccessInvitationQueryResult = Apollo.QueryResult<InvitationSuccessInvitationQuery, InvitationSuccessInvitationQueryVariables>;
export const InvitationSuccessSubscribeToNewsletterDocument = gql`
    mutation InvitationSuccessSubscribeToNewsletter($customerId: ID!) {
  subscribeToNewsletter(customerId: $customerId) {
    ... on Customer {
      id
    }
  }
}
    `;
export type InvitationSuccessSubscribeToNewsletterMutationFn = Apollo.MutationFunction<InvitationSuccessSubscribeToNewsletterMutation, InvitationSuccessSubscribeToNewsletterMutationVariables>;

/**
 * __useInvitationSuccessSubscribeToNewsletterMutation__
 *
 * To run a mutation, you first call `useInvitationSuccessSubscribeToNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvitationSuccessSubscribeToNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invitationSuccessSubscribeToNewsletterMutation, { data, loading, error }] = useInvitationSuccessSubscribeToNewsletterMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useInvitationSuccessSubscribeToNewsletterMutation(baseOptions?: Apollo.MutationHookOptions<InvitationSuccessSubscribeToNewsletterMutation, InvitationSuccessSubscribeToNewsletterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvitationSuccessSubscribeToNewsletterMutation, InvitationSuccessSubscribeToNewsletterMutationVariables>(InvitationSuccessSubscribeToNewsletterDocument, options);
      }
export type InvitationSuccessSubscribeToNewsletterMutationHookResult = ReturnType<typeof useInvitationSuccessSubscribeToNewsletterMutation>;
export type InvitationSuccessSubscribeToNewsletterMutationResult = Apollo.MutationResult<InvitationSuccessSubscribeToNewsletterMutation>;
export type InvitationSuccessSubscribeToNewsletterMutationOptions = Apollo.BaseMutationOptions<InvitationSuccessSubscribeToNewsletterMutation, InvitationSuccessSubscribeToNewsletterMutationVariables>;
export const LoginCredentialLoginDocument = gql`
    mutation LoginCredentialLogin($params: AuthParamsInput!, $credentials: CredentialLoginInput!) {
  credentialLogin(params: $params, credentials: $credentials) {
    ... on AuthResult {
      apiToken
      user {
        id
        hasVerifiedEmail
        missingEversportsPolicies {
          id
        }
        family {
          id
        }
        corporateBenefits {
          corporatePartner {
            id
          }
        }
      }
      registrationSource
      activationLink
    }
    ... on ExpectedErrors {
      errors {
        id
        message
        path
      }
    }
  }
}
    `;
export type LoginCredentialLoginMutationFn = Apollo.MutationFunction<LoginCredentialLoginMutation, LoginCredentialLoginMutationVariables>;

/**
 * __useLoginCredentialLoginMutation__
 *
 * To run a mutation, you first call `useLoginCredentialLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginCredentialLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginCredentialLoginMutation, { data, loading, error }] = useLoginCredentialLoginMutation({
 *   variables: {
 *      params: // value for 'params'
 *      credentials: // value for 'credentials'
 *   },
 * });
 */
export function useLoginCredentialLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginCredentialLoginMutation, LoginCredentialLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginCredentialLoginMutation, LoginCredentialLoginMutationVariables>(LoginCredentialLoginDocument, options);
      }
export type LoginCredentialLoginMutationHookResult = ReturnType<typeof useLoginCredentialLoginMutation>;
export type LoginCredentialLoginMutationResult = Apollo.MutationResult<LoginCredentialLoginMutation>;
export type LoginCredentialLoginMutationOptions = Apollo.BaseMutationOptions<LoginCredentialLoginMutation, LoginCredentialLoginMutationVariables>;
export const LoginRequestVerificationEmailMutationDocument = gql`
    mutation LoginRequestVerificationEmailMutation {
  requestVerificationEmail {
    ... on ExpectedErrors {
      errors {
        id
        path
        message
      }
    }
    ... on RequestVerificationResult {
      activationLink
    }
  }
}
    `;
export type LoginRequestVerificationEmailMutationMutationFn = Apollo.MutationFunction<LoginRequestVerificationEmailMutationMutation, LoginRequestVerificationEmailMutationMutationVariables>;

/**
 * __useLoginRequestVerificationEmailMutationMutation__
 *
 * To run a mutation, you first call `useLoginRequestVerificationEmailMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginRequestVerificationEmailMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginRequestVerificationEmailMutationMutation, { data, loading, error }] = useLoginRequestVerificationEmailMutationMutation({
 *   variables: {
 *   },
 * });
 */
export function useLoginRequestVerificationEmailMutationMutation(baseOptions?: Apollo.MutationHookOptions<LoginRequestVerificationEmailMutationMutation, LoginRequestVerificationEmailMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginRequestVerificationEmailMutationMutation, LoginRequestVerificationEmailMutationMutationVariables>(LoginRequestVerificationEmailMutationDocument, options);
      }
export type LoginRequestVerificationEmailMutationMutationHookResult = ReturnType<typeof useLoginRequestVerificationEmailMutationMutation>;
export type LoginRequestVerificationEmailMutationMutationResult = Apollo.MutationResult<LoginRequestVerificationEmailMutationMutation>;
export type LoginRequestVerificationEmailMutationMutationOptions = Apollo.BaseMutationOptions<LoginRequestVerificationEmailMutationMutation, LoginRequestVerificationEmailMutationMutationVariables>;
export const PasswordResetResetPasswordDocument = gql`
    mutation PasswordResetResetPassword($token: String!, $password: String!) {
  resetPassword(token: $token, password: $password) {
    ... on AuthResult {
      apiToken
      user {
        id
        hasVerifiedEmail
        missingEversportsPolicies {
          id
        }
        family {
          id
        }
        corporateBenefits {
          corporatePartner {
            id
          }
        }
      }
      registrationSource
      activationLink
    }
    ... on ExpectedErrors {
      errors {
        id
        message
        path
      }
    }
  }
}
    `;
export type PasswordResetResetPasswordMutationFn = Apollo.MutationFunction<PasswordResetResetPasswordMutation, PasswordResetResetPasswordMutationVariables>;

/**
 * __usePasswordResetResetPasswordMutation__
 *
 * To run a mutation, you first call `usePasswordResetResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetResetPasswordMutation, { data, loading, error }] = usePasswordResetResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function usePasswordResetResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<PasswordResetResetPasswordMutation, PasswordResetResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordResetResetPasswordMutation, PasswordResetResetPasswordMutationVariables>(PasswordResetResetPasswordDocument, options);
      }
export type PasswordResetResetPasswordMutationHookResult = ReturnType<typeof usePasswordResetResetPasswordMutation>;
export type PasswordResetResetPasswordMutationResult = Apollo.MutationResult<PasswordResetResetPasswordMutation>;
export type PasswordResetResetPasswordMutationOptions = Apollo.BaseMutationOptions<PasswordResetResetPasswordMutation, PasswordResetResetPasswordMutationVariables>;
export const PolicyWrapperCurrentPoliciesDocument = gql`
    query PolicyWrapperCurrentPolicies {
  latestEversportsPolicies {
    id
    type
    url
  }
}
    `;

/**
 * __usePolicyWrapperCurrentPoliciesQuery__
 *
 * To run a query within a React component, call `usePolicyWrapperCurrentPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePolicyWrapperCurrentPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePolicyWrapperCurrentPoliciesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePolicyWrapperCurrentPoliciesQuery(baseOptions?: Apollo.QueryHookOptions<PolicyWrapperCurrentPoliciesQuery, PolicyWrapperCurrentPoliciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PolicyWrapperCurrentPoliciesQuery, PolicyWrapperCurrentPoliciesQueryVariables>(PolicyWrapperCurrentPoliciesDocument, options);
      }
export function usePolicyWrapperCurrentPoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PolicyWrapperCurrentPoliciesQuery, PolicyWrapperCurrentPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PolicyWrapperCurrentPoliciesQuery, PolicyWrapperCurrentPoliciesQueryVariables>(PolicyWrapperCurrentPoliciesDocument, options);
        }
export function usePolicyWrapperCurrentPoliciesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PolicyWrapperCurrentPoliciesQuery, PolicyWrapperCurrentPoliciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PolicyWrapperCurrentPoliciesQuery, PolicyWrapperCurrentPoliciesQueryVariables>(PolicyWrapperCurrentPoliciesDocument, options);
        }
export type PolicyWrapperCurrentPoliciesQueryHookResult = ReturnType<typeof usePolicyWrapperCurrentPoliciesQuery>;
export type PolicyWrapperCurrentPoliciesLazyQueryHookResult = ReturnType<typeof usePolicyWrapperCurrentPoliciesLazyQuery>;
export type PolicyWrapperCurrentPoliciesSuspenseQueryHookResult = ReturnType<typeof usePolicyWrapperCurrentPoliciesSuspenseQuery>;
export type PolicyWrapperCurrentPoliciesQueryResult = Apollo.QueryResult<PolicyWrapperCurrentPoliciesQuery, PolicyWrapperCurrentPoliciesQueryVariables>;
export const RedirectAppTextWrapperVenueDocument = gql`
    query RedirectAppTextWrapperVenue($id: ID!) {
  venue(venueId: $id) {
    id
    name
  }
}
    `;

/**
 * __useRedirectAppTextWrapperVenueQuery__
 *
 * To run a query within a React component, call `useRedirectAppTextWrapperVenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useRedirectAppTextWrapperVenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRedirectAppTextWrapperVenueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRedirectAppTextWrapperVenueQuery(baseOptions: Apollo.QueryHookOptions<RedirectAppTextWrapperVenueQuery, RedirectAppTextWrapperVenueQueryVariables> & ({ variables: RedirectAppTextWrapperVenueQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RedirectAppTextWrapperVenueQuery, RedirectAppTextWrapperVenueQueryVariables>(RedirectAppTextWrapperVenueDocument, options);
      }
export function useRedirectAppTextWrapperVenueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RedirectAppTextWrapperVenueQuery, RedirectAppTextWrapperVenueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RedirectAppTextWrapperVenueQuery, RedirectAppTextWrapperVenueQueryVariables>(RedirectAppTextWrapperVenueDocument, options);
        }
export function useRedirectAppTextWrapperVenueSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RedirectAppTextWrapperVenueQuery, RedirectAppTextWrapperVenueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RedirectAppTextWrapperVenueQuery, RedirectAppTextWrapperVenueQueryVariables>(RedirectAppTextWrapperVenueDocument, options);
        }
export type RedirectAppTextWrapperVenueQueryHookResult = ReturnType<typeof useRedirectAppTextWrapperVenueQuery>;
export type RedirectAppTextWrapperVenueLazyQueryHookResult = ReturnType<typeof useRedirectAppTextWrapperVenueLazyQuery>;
export type RedirectAppTextWrapperVenueSuspenseQueryHookResult = ReturnType<typeof useRedirectAppTextWrapperVenueSuspenseQuery>;
export type RedirectAppTextWrapperVenueQueryResult = Apollo.QueryResult<RedirectAppTextWrapperVenueQuery, RedirectAppTextWrapperVenueQueryVariables>;
export const RegistrationCredentialRegistrationDocument = gql`
    mutation RegistrationCredentialRegistration($params: AuthParamsInput!, $profile: RegistrationProfileInput!, $settings: RegistrationSettingsInput!) {
  credentialRegistration(params: $params, profile: $profile, settings: $settings) {
    ... on AuthResult {
      apiToken
      user {
        id
        hasVerifiedEmail
        missingEversportsPolicies {
          id
        }
        family {
          id
        }
        corporateBenefits {
          corporatePartner {
            id
          }
        }
      }
      registrationSource
      activationLink
    }
    ... on ExpectedErrors {
      errors {
        id
        message
        path
      }
    }
  }
}
    `;
export type RegistrationCredentialRegistrationMutationFn = Apollo.MutationFunction<RegistrationCredentialRegistrationMutation, RegistrationCredentialRegistrationMutationVariables>;

/**
 * __useRegistrationCredentialRegistrationMutation__
 *
 * To run a mutation, you first call `useRegistrationCredentialRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationCredentialRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationCredentialRegistrationMutation, { data, loading, error }] = useRegistrationCredentialRegistrationMutation({
 *   variables: {
 *      params: // value for 'params'
 *      profile: // value for 'profile'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useRegistrationCredentialRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<RegistrationCredentialRegistrationMutation, RegistrationCredentialRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegistrationCredentialRegistrationMutation, RegistrationCredentialRegistrationMutationVariables>(RegistrationCredentialRegistrationDocument, options);
      }
export type RegistrationCredentialRegistrationMutationHookResult = ReturnType<typeof useRegistrationCredentialRegistrationMutation>;
export type RegistrationCredentialRegistrationMutationResult = Apollo.MutationResult<RegistrationCredentialRegistrationMutation>;
export type RegistrationCredentialRegistrationMutationOptions = Apollo.BaseMutationOptions<RegistrationCredentialRegistrationMutation, RegistrationCredentialRegistrationMutationVariables>;
export const RegistrationVenueDocument = gql`
    query RegistrationVenue($id: ID!) {
  venue(venueId: $id) {
    id
    hideNewsletterOnRegistration
  }
}
    `;

/**
 * __useRegistrationVenueQuery__
 *
 * To run a query within a React component, call `useRegistrationVenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationVenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationVenueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegistrationVenueQuery(baseOptions: Apollo.QueryHookOptions<RegistrationVenueQuery, RegistrationVenueQueryVariables> & ({ variables: RegistrationVenueQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegistrationVenueQuery, RegistrationVenueQueryVariables>(RegistrationVenueDocument, options);
      }
export function useRegistrationVenueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegistrationVenueQuery, RegistrationVenueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegistrationVenueQuery, RegistrationVenueQueryVariables>(RegistrationVenueDocument, options);
        }
export function useRegistrationVenueSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RegistrationVenueQuery, RegistrationVenueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RegistrationVenueQuery, RegistrationVenueQueryVariables>(RegistrationVenueDocument, options);
        }
export type RegistrationVenueQueryHookResult = ReturnType<typeof useRegistrationVenueQuery>;
export type RegistrationVenueLazyQueryHookResult = ReturnType<typeof useRegistrationVenueLazyQuery>;
export type RegistrationVenueSuspenseQueryHookResult = ReturnType<typeof useRegistrationVenueSuspenseQuery>;
export type RegistrationVenueQueryResult = Apollo.QueryResult<RegistrationVenueQuery, RegistrationVenueQueryVariables>;
export const SelfMeDocument = gql`
    query SelfMe {
  me {
    id
    email
    firstName
    lastName
  }
}
    `;

/**
 * __useSelfMeQuery__
 *
 * To run a query within a React component, call `useSelfMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfMeQuery(baseOptions?: Apollo.QueryHookOptions<SelfMeQuery, SelfMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelfMeQuery, SelfMeQueryVariables>(SelfMeDocument, options);
      }
export function useSelfMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelfMeQuery, SelfMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelfMeQuery, SelfMeQueryVariables>(SelfMeDocument, options);
        }
export function useSelfMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SelfMeQuery, SelfMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SelfMeQuery, SelfMeQueryVariables>(SelfMeDocument, options);
        }
export type SelfMeQueryHookResult = ReturnType<typeof useSelfMeQuery>;
export type SelfMeLazyQueryHookResult = ReturnType<typeof useSelfMeLazyQuery>;
export type SelfMeSuspenseQueryHookResult = ReturnType<typeof useSelfMeSuspenseQuery>;
export type SelfMeQueryResult = Apollo.QueryResult<SelfMeQuery, SelfMeQueryVariables>;
export const UseCorporateInvitationDocument = gql`
    query UseCorporateInvitation($id: ID, $token: String) {
  corporateInvitation(corporatePartnerId: $id, token: $token) {
    corporatePartner {
      id
      name
      logo {
        large
      }
      description
      additionalDescription
    }
    hasSpecialPriceAccess
    corporateBudget {
      type
      employeeLimit {
        __typename
        ... on Limited {
          limit {
            value
            currency
          }
          displayCapped
        }
      }
      totalLimit {
        __typename
        ... on Limited {
          limit {
            value
            currency
          }
          displayCapped
        }
      }
      expiry
      interval: intervalV2
    }
  }
}
    `;

/**
 * __useUseCorporateInvitationQuery__
 *
 * To run a query within a React component, call `useUseCorporateInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseCorporateInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseCorporateInvitationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUseCorporateInvitationQuery(baseOptions?: Apollo.QueryHookOptions<UseCorporateInvitationQuery, UseCorporateInvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseCorporateInvitationQuery, UseCorporateInvitationQueryVariables>(UseCorporateInvitationDocument, options);
      }
export function useUseCorporateInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseCorporateInvitationQuery, UseCorporateInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseCorporateInvitationQuery, UseCorporateInvitationQueryVariables>(UseCorporateInvitationDocument, options);
        }
export function useUseCorporateInvitationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UseCorporateInvitationQuery, UseCorporateInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UseCorporateInvitationQuery, UseCorporateInvitationQueryVariables>(UseCorporateInvitationDocument, options);
        }
export type UseCorporateInvitationQueryHookResult = ReturnType<typeof useUseCorporateInvitationQuery>;
export type UseCorporateInvitationLazyQueryHookResult = ReturnType<typeof useUseCorporateInvitationLazyQuery>;
export type UseCorporateInvitationSuspenseQueryHookResult = ReturnType<typeof useUseCorporateInvitationSuspenseQuery>;
export type UseCorporateInvitationQueryResult = Apollo.QueryResult<UseCorporateInvitationQuery, UseCorporateInvitationQueryVariables>;
export const UseCorporateUserMeDocument = gql`
    query UseCorporateUserMe {
  me {
    id
    corporateBenefits {
      corporatePartner {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useUseCorporateUserMeQuery__
 *
 * To run a query within a React component, call `useUseCorporateUserMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseCorporateUserMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseCorporateUserMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseCorporateUserMeQuery(baseOptions?: Apollo.QueryHookOptions<UseCorporateUserMeQuery, UseCorporateUserMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseCorporateUserMeQuery, UseCorporateUserMeQueryVariables>(UseCorporateUserMeDocument, options);
      }
export function useUseCorporateUserMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseCorporateUserMeQuery, UseCorporateUserMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseCorporateUserMeQuery, UseCorporateUserMeQueryVariables>(UseCorporateUserMeDocument, options);
        }
export function useUseCorporateUserMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UseCorporateUserMeQuery, UseCorporateUserMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UseCorporateUserMeQuery, UseCorporateUserMeQueryVariables>(UseCorporateUserMeDocument, options);
        }
export type UseCorporateUserMeQueryHookResult = ReturnType<typeof useUseCorporateUserMeQuery>;
export type UseCorporateUserMeLazyQueryHookResult = ReturnType<typeof useUseCorporateUserMeLazyQuery>;
export type UseCorporateUserMeSuspenseQueryHookResult = ReturnType<typeof useUseCorporateUserMeSuspenseQuery>;
export type UseCorporateUserMeQueryResult = Apollo.QueryResult<UseCorporateUserMeQuery, UseCorporateUserMeQueryVariables>;
export const UseFamilyLoginFamilyLoginDocument = gql`
    mutation UseFamilyLoginFamilyLogin($userId: ID!) {
  familyLogin(userId: $userId) {
    ... on AuthResult {
      apiToken
      user {
        id
      }
    }
    ... on ExpectedErrors {
      errors {
        id
        message
        path
      }
    }
  }
}
    `;
export type UseFamilyLoginFamilyLoginMutationFn = Apollo.MutationFunction<UseFamilyLoginFamilyLoginMutation, UseFamilyLoginFamilyLoginMutationVariables>;

/**
 * __useUseFamilyLoginFamilyLoginMutation__
 *
 * To run a mutation, you first call `useUseFamilyLoginFamilyLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUseFamilyLoginFamilyLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [useFamilyLoginFamilyLoginMutation, { data, loading, error }] = useUseFamilyLoginFamilyLoginMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUseFamilyLoginFamilyLoginMutation(baseOptions?: Apollo.MutationHookOptions<UseFamilyLoginFamilyLoginMutation, UseFamilyLoginFamilyLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UseFamilyLoginFamilyLoginMutation, UseFamilyLoginFamilyLoginMutationVariables>(UseFamilyLoginFamilyLoginDocument, options);
      }
export type UseFamilyLoginFamilyLoginMutationHookResult = ReturnType<typeof useUseFamilyLoginFamilyLoginMutation>;
export type UseFamilyLoginFamilyLoginMutationResult = Apollo.MutationResult<UseFamilyLoginFamilyLoginMutation>;
export type UseFamilyLoginFamilyLoginMutationOptions = Apollo.BaseMutationOptions<UseFamilyLoginFamilyLoginMutation, UseFamilyLoginFamilyLoginMutationVariables>;
export const UseVenueDocument = gql`
    query UseVenue($id: ID!) {
  venue(venueId: $id) {
    id
    name
    logo {
      large
    }
    images {
      large
    }
  }
}
    `;

/**
 * __useUseVenueQuery__
 *
 * To run a query within a React component, call `useUseVenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseVenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseVenueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUseVenueQuery(baseOptions: Apollo.QueryHookOptions<UseVenueQuery, UseVenueQueryVariables> & ({ variables: UseVenueQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseVenueQuery, UseVenueQueryVariables>(UseVenueDocument, options);
      }
export function useUseVenueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseVenueQuery, UseVenueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseVenueQuery, UseVenueQueryVariables>(UseVenueDocument, options);
        }
export function useUseVenueSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UseVenueQuery, UseVenueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UseVenueQuery, UseVenueQueryVariables>(UseVenueDocument, options);
        }
export type UseVenueQueryHookResult = ReturnType<typeof useUseVenueQuery>;
export type UseVenueLazyQueryHookResult = ReturnType<typeof useUseVenueLazyQuery>;
export type UseVenueSuspenseQueryHookResult = ReturnType<typeof useUseVenueSuspenseQuery>;
export type UseVenueQueryResult = Apollo.QueryResult<UseVenueQuery, UseVenueQueryVariables>;
export const UseSocialLoginExternalLoginDocument = gql`
    mutation UseSocialLoginExternalLogin($params: AuthParamsInput!, $credentials: ExternalLoginInput!) {
  externalLogin(params: $params, credentials: $credentials) {
    ... on AuthResult {
      apiToken
      user {
        id
        hasVerifiedEmail
        missingEversportsPolicies {
          id
        }
        family {
          id
        }
        corporateBenefits {
          corporatePartner {
            id
          }
        }
      }
      registrationSource
      activationLink
    }
    ... on ExpectedErrors {
      errors {
        id
        message
        path
      }
    }
  }
}
    `;
export type UseSocialLoginExternalLoginMutationFn = Apollo.MutationFunction<UseSocialLoginExternalLoginMutation, UseSocialLoginExternalLoginMutationVariables>;

/**
 * __useUseSocialLoginExternalLoginMutation__
 *
 * To run a mutation, you first call `useUseSocialLoginExternalLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUseSocialLoginExternalLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [useSocialLoginExternalLoginMutation, { data, loading, error }] = useUseSocialLoginExternalLoginMutation({
 *   variables: {
 *      params: // value for 'params'
 *      credentials: // value for 'credentials'
 *   },
 * });
 */
export function useUseSocialLoginExternalLoginMutation(baseOptions?: Apollo.MutationHookOptions<UseSocialLoginExternalLoginMutation, UseSocialLoginExternalLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UseSocialLoginExternalLoginMutation, UseSocialLoginExternalLoginMutationVariables>(UseSocialLoginExternalLoginDocument, options);
      }
export type UseSocialLoginExternalLoginMutationHookResult = ReturnType<typeof useUseSocialLoginExternalLoginMutation>;
export type UseSocialLoginExternalLoginMutationResult = Apollo.MutationResult<UseSocialLoginExternalLoginMutation>;
export type UseSocialLoginExternalLoginMutationOptions = Apollo.BaseMutationOptions<UseSocialLoginExternalLoginMutation, UseSocialLoginExternalLoginMutationVariables>;