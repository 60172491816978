import { useState } from 'react'
import ClientLogger from '@eversports/react-components/utilities/client-logger'
import { getUniversalEnvVariable } from '@eversports/react-app-base/environmentCheck'

const useFacebookSDK = () => {
  // eslint-disable-next-line no-undef
  const [sdk, setSDK] = useState<FacebookSDK | null>(null)
  const [isInitialized, setIsInitialized] = useState(false)
  const [isSDKLoaded, setIsSDKLoaded] = useState(false)
  const appId = getUniversalEnvVariable('FACEBOOK_CLIENT_ID')

  const initialize = () => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId,
        cookie: true,
        xfbml: true,
        version: 'v13.0',
      })
      setSDK(window.FB)
    }
  }

  const loadSDK = () => {
    const d = document
    const s = 'script' as const
    const id = 'facebook-jssdk'

    const fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) return
    const js = d.createElement(s)
    js.id = id

    js.src = `https://connect.facebook.net/en_US/sdk.js`
    fjs.parentNode!.insertBefore(js, fjs)
  }

  const loadFacebookSDK = () => {
    if (!appId) {
      ClientLogger.info('Facebook SDK is not loaded because no appId is set')
      return
    }

    if (!isInitialized) {
      initialize()
      setIsInitialized(true)
    }

    if (!isSDKLoaded) {
      loadSDK()
      setIsSDKLoaded(true)
    }
  }

  return { loadFacebookSDK, sdk }
}

export default useFacebookSDK
