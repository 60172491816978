import { parse } from 'query-string'
import { useLocation } from 'react-router-dom'
import { useUseVenueQuery, UseVenueQuery } from '../graphql'

import createSimpleQueryResult, { SimpleQueryResult } from '../utils/create-simple-query-result'

function useVenue(): SimpleQueryResult<UseVenueQuery> {
  const { search } = useLocation()
  const { venueId } = parse(search)
  const skip = !venueId
  const queryResult = useUseVenueQuery({
    variables: {
      id: String(venueId),
    },
    skip,
  })
  return createSimpleQueryResult({
    queryResult,
    skip,
  })
}

export default useVenue
