import * as React from 'react'

import { Localized } from '../../localization/react'
import ErrorFormatter from '../../primitives/ErrorFormatter'
import Heading from '../../primitives/Heading'
import ActionOutcome from '../ActionOutcome'

import { ClientError } from '@eversports/react-components/client-error'

interface Props extends React.PropsWithChildren<unknown> {
  error?: ClientError
}

class ErrorPage extends React.PureComponent<Props> {
  public render() {
    const { error, children } = this.props
    return (
      <ActionOutcome type="error" data-testid="error">
        {error && (
          <Heading textAlign="center" is="h2">
            <ErrorFormatter error={error} />
          </Heading>
        )}
        {children && <>{children}</>}
        {!error && !children && (
          <Heading textAlign="center" is="h2">
            <Localized id="default-error-message" />
          </Heading>
        )}
      </ActionOutcome>
    )
  }
}

export default ErrorPage
