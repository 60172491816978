export enum TrackingType {
  ANALYTICS = 'ANALYTICS',
  ADVERTISING = 'ADVERTISING',
}

export const DO_NOT_TRACK_PROPERTY_KEY = 'dnt'
export const DO_NOT_TRACK_ADVERTISING_PROPERTY_KEY = 'dnt_a'

const TRACKING_PROPERTIES = {
  [TrackingType.ANALYTICS]: DO_NOT_TRACK_PROPERTY_KEY,
  [TrackingType.ADVERTISING]: DO_NOT_TRACK_ADVERTISING_PROPERTY_KEY,
}

export function setUserTrackingPreferences(updatedPreferences: { [key in TrackingType]: boolean }) {
  if (typeof window === 'undefined') return

  const updatedPreferenceTypes = Object.keys(updatedPreferences) as Array<keyof typeof TrackingType>

  updatedPreferenceTypes.forEach((type) => {
    const updatedTrackingValue = updatedPreferences[type] ? '0' : '1'
    window.localStorage.setItem(TRACKING_PROPERTIES[type], updatedTrackingValue)
  })
}

export function getUserTrackingPreferences(): { [key in TrackingType]: boolean | null } | undefined {
  if (typeof window === 'undefined') return

  const doNotTrackValue = window.localStorage.getItem(DO_NOT_TRACK_PROPERTY_KEY)
  const doNotTrackAdvertisingValue = window.localStorage.getItem(DO_NOT_TRACK_ADVERTISING_PROPERTY_KEY)

  return {
    [TrackingType.ANALYTICS]: doNotTrackValue ? doNotTrackValue === '0' : null,
    [TrackingType.ADVERTISING]: doNotTrackAdvertisingValue ? doNotTrackAdvertisingValue === '0' : null,
  }
}
