import Link from '@eversports/react-components/primitives/Link'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'
import { ConsentScreenEversportsAndVenueCurrentPoliciesQuery, PolicyType } from '../../graphql'

import { Localized } from '../../localization/react'

interface Props {
  policies:
    | ConsentScreenEversportsAndVenueCurrentPoliciesQuery['latestEversportsPolicies']
    | ConsentScreenEversportsAndVenueCurrentPoliciesQuery['venue']['company']['latestPolicies']
  venueName: string
  showHeader?: boolean
}

const VenueConsentSection: React.FC<React.PropsWithChildren<Props>> = ({ policies, venueName, showHeader }: Props) => {
  const privacyPolicy = policies.find((policy) => policy.type === PolicyType.POLICY_TYPE_PRIVACY)
  const termsOfServicePolicy = policies.find((policy) => policy.type === PolicyType.POLICY_TYPE_TERMS_OF_SERVICE)

  return (
    <>
      {showHeader && (
        <Text fontWeight="bold">
          <Localized id="eversports-and-venue-consent-form-consent-agreement-header" params={{ venueName }} />
        </Text>
      )}
      <Text>
        <Localized
          id="eversports-and-venue-consent-form-consent-agreement-text"
          params={{
            venueName,
            policies: (
              <Link to={privacyPolicy ? privacyPolicy.url : ''} external blank>
                <Localized id="eversports-consent-form-privacy-policies" />
              </Link>
            ),
            terms: (
              <Link to={termsOfServicePolicy ? termsOfServicePolicy.url : ''} external blank>
                <Localized id="eversports-consent-form-terms-of-service" />
              </Link>
            ),
          }}
        />
      </Text>
    </>
  )
}

export default VenueConsentSection
