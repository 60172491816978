/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

// DO NOT MODIFY
// FILE GENERATED BY `intl-codegen@2.2.1`
// https://github.com/eversport/intl-codegen
// DO NOT MODIFY

import { defineLoader } from "intl-codegen/runtime";

export const loadLanguage = defineLoader([
  "appleIncompleteProfile",
  "bookingCancellationNotAllowed",
  "dateSpan",
  "daysFromUntil",
  "Discount",
  "discountAndSpecialOfferNotAllowed",
  "discountExpired",
  "discountMarketingDiscountNotAllowedInWidget",
  "discountMarketingWithoutOnlineBooking",
  "discountMaxPerSystem",
  "discountMaxPerUser",
  "discountMinPriceNotReached",
  "discountMobileDiscountOnly",
  "discountNewToEversportsOnly",
  "discountNewToFacilityOnly",
  "discountOncePerDeviceOnly",
  "discountWrongCourt",
  "discountWrongCurrency",
  "discountWrongDate",
  "discountWrongItemType",
  "discountWrongPeriod",
  "discountWrongSport",
  "discountWrongUser",
  "discountWrongVenue",
  "emailProviderBlocked",
  "eventInThePast",
  "eventMaxBookingsOfCustomergroupReached",
  "eventMaxParticipantsReached",
  "eventNotBookable",
  "eventRegistrationNotOpen",
  "eventRestrictedForUserClubgroup",
  "eventUserAlreadyRegistered",
  "expiredToken",
  "familyAccountMemberHasActiveMembershipsWithOnlinePayment",
  "fieldnameEmail",
  "fieldnameFirstName",
  "fieldnameLastName",
  "fieldnamePassword",
  "internalServerError",
  "invalidArray",
  "invalidCredentials",
  "invalidDate",
  "invalidEmail",
  "invalidFiscalCode",
  "invalidIban",
  "invalidId",
  "invalidMaxArrayLength",
  "invalidMaxLength",
  "invalidMaxValue",
  "invalidMinArrayLength",
  "invalidMinLength",
  "invalidMinValue",
  "invalidNumber",
  "invalidOfferForEvent",
  "invalidPassword",
  "invalidPhone",
  "invalidToken",
  "matchAlreadyCancelled",
  "matchHasNoOpenSpotsLeft",
  "matchInvalidComment",
  "matchLongComment",
  "matchLongNote",
  "matchMaxParticipantsMustBeBiggerThanOrEqualToExistingParticipants",
  "matchMaxParticipantsMustBePositive",
  "matchMinParticipantsMustBePositive",
  "matchMissingFields",
  "matchNoFieldsToUpdate",
  "minAge14Years",
  "onlineBookingOrPurchaseNotEnabled",
  "productInvalidEvent",
  "productNewCustomersOnly",
  "productNoAggregatorSpots",
  "productNoTrialSpots",
  "productNotSoldOnline",
  "productNotValidAtFacility",
  "productOncePerCustomersOnly",
  "productRecordingAlreadySold",
  "productSelectedCustomerGroupsOnly",
  "productValidityExpired",
  "productValidityExpiresBeforeEvent",
  "productValidityFailedPayments",
  "productValidityInvalidEvent",
  "productValidityInvalidRecording",
  "productValidityMaxCycleExhausted",
  "productValidityMembershipPaused",
  "productValidityNotYetValid",
  "productValidityTotalLimitExhausted",
  "required",
  "ThisVoucherCodeIsNotValidAtEversports",
  "ticketEventNoTicketsAvailable",
  "userWithEmailExists",
  "voucherWithoutProductNotAllowed",
], {
  de: () => import("./locales/de.js"),
  en: () => import("./locales/en.js"),
  es: () => import("./locales/es.js"),
  nl: () => import("./locales/nl.js"),
  fr: () => import("./locales/fr.js"),
  it: () => import("./locales/it.js"),
}, "en");
