import { ApolloError } from '@apollo/client'
import { parseApolloError } from '@eversports/react-components/logical/parse-apollo-error'
import { ClientError } from '@eversports/react-components/client-error'
import { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  ExternalLoginProvider,
  UseSocialLoginExternalLoginMutation,
  UseSocialLoginExternalLoginMutationVariables,
  useUseSocialLoginExternalLoginMutation,
} from '../graphql'
import { GoogleLoginReturnType } from '../components/GoogleLoginButton/GoogleLoginButton.types'
import { getAuthParams } from './get-auth-params'

interface Result {
  data?: UseSocialLoginExternalLoginMutation | null
  loading: boolean
  error?: ClientError
  // eslint-disable-next-line no-undef
  handleFacebookLogin: (response: FacebookSDKResponse) => Promise<void>
  handleGoogleLogin: (response: GoogleLoginReturnType) => Promise<void>
}

const useSocialLogin = (): Result => {
  const location = useLocation()
  const [error, setError] = useState<ClientError | undefined>(undefined)

  const handleLoginError = (e: ApolloError) => {
    setError(parseApolloError(e))
  }

  const [socialMutation, { loading, error: mutationError, data }] = useUseSocialLoginExternalLoginMutation({
    onError: handleLoginError,
  })

  const handleSocialLogin = async (credentials: UseSocialLoginExternalLoginMutationVariables['credentials']) => {
    const authParams = getAuthParams(location.search)
    await socialMutation({
      variables: {
        params: authParams,
        credentials,
      },
    })
  }

  const handleFacebookLogin = useCallback(
    // eslint-disable-next-line no-undef
    async (response: FacebookSDKResponse) => {
      if (response.status !== 'connected') {
        setError({ type: 'localized', localizedId: 'authentication-facebook-login-error' })
        return
      }

      await handleSocialLogin({
        provider: ExternalLoginProvider.EXTERNAL_LOGIN_PROVIDER_FACEBOOK,
        accessToken: response.authResponse.accessToken,
      })
    },
    [ExternalLoginProvider, location],
  )

  const handleGoogleLogin = useCallback(
    async (response: GoogleLoginReturnType) => {
      if (response.status !== 'success') {
        setError({ type: 'localized', localizedId: 'authentication-google-login-error' })
        return
      }

      await handleSocialLogin({
        provider: ExternalLoginProvider.EXTERNAL_LOGIN_PROVIDER_GOOGLE,
        accessToken: response.accessToken,
      })
    },
    [ExternalLoginProvider, location],
  )

  return { handleFacebookLogin, handleGoogleLogin, error: parseApolloError(mutationError) || error, loading, data }
}

export default useSocialLogin
