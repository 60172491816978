import { ApolloError } from '@apollo/client'
import { parseApolloError } from '@eversports/react-components/logical/parse-apollo-error'
import parseExpectedError from '@eversports/react-components/logical/parse-expected-error'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import { ClientError } from '@eversports/react-components/client-error'
import { parse } from 'query-string'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Origin,
  RegistrationCredentialRegistrationMutation,
  useRegistrationCredentialRegistrationMutation,
  useRegistrationVenueQuery,
  UseSocialLoginExternalLoginMutation,
} from '../graphql'

import RegistrationForm, { RegistrationValues } from '../components/RegistrationForm'

import getAmplitudeLoginData from '../utils/get-amplitude-login-data'
import { getAuthParams } from '../utils/get-auth-params'
import handleAuthResult from '../utils/handle-auth-result'
import useSocialLogin from '../utils/useSocialLogin'
import LoginRegisterToggle from './LoginRegisterToggle'
import CookiesBanner from './CookieBanner'

const Registration = () => {
  const location = useLocation()
  const history = useHistory()
  const params = getAuthParams(location.search)
  const { venueId } = parse(location.search)
  const hasVenueAndWidgetOrigin = venueId && params.origin === Origin.ORIGIN_WIDGET

  const { amplitude } = useAmplitude()
  const [error, setError] = useState<ClientError | undefined>(undefined)
  const {
    handleFacebookLogin,
    handleGoogleLogin,
    loading: socialLoading,
    data: externalLoginData,
    error: socialError,
  } = useSocialLogin()

  const handleError = (e: ApolloError) => {
    setError(parseApolloError(e))
  }

  const handleCompleted = async ({ credentialRegistration: result }: RegistrationCredentialRegistrationMutation) => {
    if (result.__typename === 'ExpectedErrors') {
      return setError(parseExpectedError(result.errors))
    }
    handleAuthResult({ result, history })
  }

  const { data, loading } = useRegistrationVenueQuery({
    variables: { id: venueId as string },
    skip: !hasVenueAndWidgetOrigin,
  })

  const [startRegistration, { loading: mutationLoading, error: mutationError }] =
    useRegistrationCredentialRegistrationMutation({
      onError: handleError,
      onCompleted: handleCompleted,
    })

  const handleRegistration = async (values: RegistrationValues) => {
    const { newsletter, ...profile } = values
    const settings = {
      subscribeToEversportsNewsletter: Boolean(newsletter.length),
    }

    const from = getAmplitudeLoginData(history.location.search)

    if (amplitude) {
      amplitude.logEvent('Registered', {
        type: 'credentials',
        hasSubscribedNewsletter: Boolean(newsletter.length),
        from,
      })
    }

    await startRegistration({
      variables: { params, profile, settings },
    })
  }

  const handleSocialRegistration = async (result: UseSocialLoginExternalLoginMutation['externalLogin']) => {
    if (result.__typename === 'ExpectedErrors') {
      return setError(parseExpectedError(result.errors))
    }

    handleAuthResult({ result, history })
  }

  useEffect(() => {
    if (!externalLoginData) return

    const from = getAmplitudeLoginData(history.location.search)

    if (amplitude) {
      amplitude.logEvent('Registered', {
        type: 'social',
        hasSubscribedNewsletter: null,
        from,
      })
    }

    // eslint-disable-next-line  @typescript-eslint/no-floating-promises
    handleSocialRegistration(externalLoginData.externalLogin)
  }, [externalLoginData])

  const hideNewsletterOnRegistration = (data && data.venue && data.venue.hideNewsletterOnRegistration) || false

  return (
    <>
      <CookiesBanner />
      <LoginRegisterToggle />
      <RegistrationForm
        loading={mutationLoading || socialLoading || loading}
        error={parseApolloError(mutationError) || socialError || error}
        onRegistration={handleRegistration}
        onFacebookLogin={handleFacebookLogin}
        onGoogleLogin={handleGoogleLogin}
        hideNewsletterOnRegistration={hideNewsletterOnRegistration}
      />
    </>
  )
}

export default Registration
