import React from 'react'
import Link from '@eversports/react-components/primitives/Link'
import Text from '@eversports/react-components/primitives/Text'
import { Theme } from '@eversports/react-components/design-tokens/theme'
import Icon from '@eversports/react-components/primitives/Icon'

import { SimpleFlex } from '@eversports/react-components/primitives/Flex'
import { makeStyles } from '@mui/styles'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import Flex from '@eversports/react-components/primitives/Flex/Flex'
import { Localized } from '../localization/react'
import iOSIcon from '../icons/iOSIcon'
import AndroidIcon from '../icons/AndroidIcon'
import useIsWidget from '../hooks/use-is-widget'

const getLocalizedAppleStoreHref = (countryCode?: string) =>
  `https://itunes.apple.com/${countryCode ? `${countryCode}/` : ''}app/eversport-now/id946460861`

const getLocalizedPlayStoreHref = (language: string) =>
  `https://play.google.com/store/apps/details?id=at.eversport&hl=${language}`

const useStyles = makeStyles((theme: Theme) => ({
  container: ({ isWidget }: { isWidget: boolean }) => ({
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    borderRadius: '4px',
    padding: isWidget ? '0px 4px' : '8px 16px',
    backgroundColor: 'white',
  }),
}))

const DownloadLinks = () => {
  const { language, tld } = useSettingsContext()
  const isWidget = useIsWidget()
  const classes = useStyles({ isWidget })
  return (
    <Flex horizontalSpacing="dense">
      <Link to={getLocalizedAppleStoreHref(tld)} external blank>
        <SimpleFlex alignItems="center" className={classes.container}>
          <Icon icon={iOSIcon} size="x-small" marginRight="dense" />
          <Text color="subtleText" size="x-small" fontWeight="bold">
            <Localized id="iOS-label" />
          </Text>
        </SimpleFlex>
      </Link>
      <Link to={getLocalizedPlayStoreHref(language)} external blank>
        <SimpleFlex alignItems="center" className={classes.container}>
          <Icon icon={AndroidIcon} size="x-small" marginRight="dense" />
          <Text color="subtleText" size="x-small" fontWeight="bold">
            <Localized id="android-label" />
          </Text>
        </SimpleFlex>
      </Link>
    </Flex>
  )
}

export default DownloadLinks
