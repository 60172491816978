import { motion } from '@eversports/react-components/design-tokens/motion'
import { getParsedApolloErrorFormFields } from '@eversports/react-components/logical/parse-apollo-error'
import Button from '@eversports/react-components/primitives/Button'
import ErrorBox from '@eversports/react-components/primitives/ErrorBox'
import Flex from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Link from '@eversports/react-components/primitives/Link'
import Processing from '@eversports/react-components/primitives/Processing/Processing'
import Text from '@eversports/react-components/primitives/Text'
import TextField from '@eversports/react-components/primitives/TextField'
import { ClientError } from '@eversports/react-components/client-error'
import { makeStyles } from '@mui/styles'
import PyroForm, { Form } from 'pyro-form'
import React from 'react'

import { Localized } from '../localization/react'

interface ResetPasswordFormProps {
  loginHref?: string
  loading?: boolean
  error?: ClientError
  onRequestPasswordReset: (values: ForgotPasswordValues) => void
}

export interface ForgotPasswordValues {
  email: string
}

const initialValues: ForgotPasswordValues = { email: '' }

const useStyles = makeStyles({
  form: {
    width: '100%',
    maxWidth: 350,
  },
})

const ForgotPasswordForm: React.FC<React.PropsWithChildren<ResetPasswordFormProps>> = ({
  onRequestPasswordReset,
  loginHref = '',
  loading,
  error,
}) => {
  const classes = useStyles()
  return (
    <motion.div exit={{ opacity: 0 }} transition={{ ease: 'easeInOut' }}>
      <PyroForm
        initialValues={initialValues}
        errors={getParsedApolloErrorFormFields(error)}
        onSubmit={onRequestPasswordReset}
      >
        {({ hasErrors }) => (
          <Form className={classes.form}>
            <Processing isLoading={loading}>
              <Flex flexDirection="column" verticalSpacing="wide">
                <Heading is="h1">
                  <Localized id="authentication-forgot-password-heading" />
                </Heading>
                <Text>
                  <Localized id="authentication-forgot-password-description" />
                </Text>
                <TextField
                  name="email"
                  label={<Localized id="authentication-forgot-password-email-label" />}
                  required
                  fullWidth
                  type="email"
                />
                {error && <ErrorBox error={error} />}
                <Button data-testid="forgot-password" type="submit" variant="primary" fullWidth disabled={hasErrors}>
                  <Localized id="authentication-forgot-password-action" />
                </Button>
                <Link to={loginHref} size="small">
                  <Localized id="authentication-forgot-password-back-to-login" />
                </Link>
              </Flex>
            </Processing>
          </Form>
        )}
      </PyroForm>
    </motion.div>
  )
}

export default ForgotPasswordForm
