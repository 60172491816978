import ActionOutcome from '@eversports/react-components/patterns/ActionOutcome'
import Heading from '@eversports/react-components/primitives/Heading'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'

import { Localized } from '../localization/react'

const AccountActivationFailure = () => (
  <ActionOutcome type="error">
    <Heading is="h2">
      <Localized id="email-verification-header-error" />
    </Heading>
    <Text textAlign="center">
      <Localized id="email-verification-text-error" />
    </Text>
  </ActionOutcome>
)

export default AccountActivationFailure
