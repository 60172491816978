import { makeStyles } from '@mui/styles'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'

import { Localized } from '../../localization/react'
import Button from '../../primitives/Button'
import Flex from '../../primitives/Flex'
import Icon, { Icons } from '../../primitives/Icon'
import Processing from '../../primitives/Processing'
import Text from '../../primitives/Text'

const useStyles = makeStyles({
  button: {
    width: 'fit-content',
  },
})

interface Props {
  onClick: () => void
  variant?: 'primary' | 'secondary'
}

const SubscribeToNewsletterButton: React.FC<React.PropsWithChildren<Props>> = ({ onClick, variant }) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [wasSuccessful, setWasSuccessful] = useState(false)
  const timer = useRef<ReturnType<typeof setTimeout>>()
  const color = variant === 'primary' ? 'white' : 'primary.main'

  useEffect(
    () => () => {
      clearTimeout(timer.current)
    },
    [],
  )

  const handleButtonClick = () => {
    if (isLoading || wasSuccessful) return

    setIsLoading(true)
    onClick()

    timer.current = setTimeout(() => {
      setWasSuccessful(true)
      setIsLoading(false)
    }, 500)
  }

  return (
    <Button
      variant={variant!}
      disabled={isLoading || wasSuccessful}
      onClick={handleButtonClick}
      className={classes.button}
    >
      <Processing isLoading={isLoading}>
        {wasSuccessful ? (
          <Flex alignItems="center">
            <Icon icon={Icons.CHECK} marginRight="dense" size="large" color={color} />
            <Text size="x-small" textTransform="uppercase" color={color} fontWeight="bold">
              <Localized id="newsletter-banner-action-success" />
            </Text>
          </Flex>
        ) : (
          <Text size="x-small" textTransform="uppercase" color={color} fontWeight="bold">
            <Localized id="newsletter-banner-action" />
          </Text>
        )}
      </Processing>
    </Button>
  )
}

SubscribeToNewsletterButton.defaultProps = {
  variant: 'secondary',
}

export default SubscribeToNewsletterButton
