import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as React from 'react'

import { Theme } from '../../design-tokens/theme'
import useSpacing, { UseSpacingProps } from '../../design-tokens/useSpacing'
import ErrorFormatter from '../ErrorFormatter'
import Flex from '../Flex'
import Icon, { Icons } from '../Icon'
import Text from '../Text'

import { ClientError } from '@eversports/react-components/client-error'

interface Props extends MarginProps {
  error?: ClientError
  children?: React.ReactNode
}

type ErrorBoxRef = HTMLElementTagNameMap['div']

const useStyles = makeStyles(({ palette, borderRadii, layoutSpacing }: Theme) => ({
  root: {
    border: `2px solid ${palette.danger}`,
    borderRadius: borderRadii.default,
    padding: layoutSpacing.default,
  },
}))

type MarginProps = Pick<UseSpacingProps, 'margin' | 'marginLeft' | 'marginRight' | 'marginBottom' | 'marginTop'>

// eslint-disable-next-line no-shadow
export const ErrorBox = React.forwardRef<ErrorBoxRef, Props>(function ErrorBox({ children, error, ...rest }, ref) {
  const classes = useStyles()
  const { spacingClass } = useSpacing(rest)

  return (
    <div data-testid="error" className={classNames(classes.root, spacingClass)} ref={ref}>
      <Flex horizontalSpacing="default">
        <Icon icon={Icons.WARNING} color="danger" size="large" />
        {error ? (
          <Text size="small" color="danger">
            <ErrorFormatter error={error} />
          </Text>
        ) : (
          <div>{children}</div>
        )}
      </Flex>
    </div>
  )
})

export default ErrorBox
