import Button from '@eversports/react-components/primitives/Button'
import Flex from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Text from '@eversports/react-components/primitives/Text'
import safeRedirect from '@eversports/react-components/utilities/safe-redirect'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { AuthRoutes } from '../auth-routes'
import { Localized } from '../localization/react'
import { useFamilyAccountDisclaimerMeQuery } from '../graphql'

const useStyles = makeStyles({
  container: {
    maxWidth: 520,
  },
})

const FamilyAccountDisclaimer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const history = useHistory()
  const classes = useStyles()

  const { data, loading, error } = useFamilyAccountDisclaimerMeQuery()

  if (error) {
    throw new Error(error.message)
  }

  if (!data || loading) {
    return null
  }

  const isUserAlreadyPartOfAFamily = data.me && data.me.family

  return (
    <Flex flexDirection="column" verticalSpacing="default" className={classes.container}>
      <Heading is="h1">
        <Localized id="family-account-disclaimer-title" />
      </Heading>
      <Text>
        <Localized
          id="family-account-disclaimer-text"
          params={{
            manageTheirBookings: (
              <Text fontWeight="bold" is="span">
                <Localized id="family-account-disclaimer-manage-their-bookings" />
              </Text>
            ),
            allPurchases: (
              <Text fontWeight="bold" is="span">
                <Localized id="family-account-disclaimer-all-purchases" />
              </Text>
            ),
            billedToAccountOwner: (
              <Text fontWeight="bold" is="span">
                <Localized id="family-account-disclaimer-billed-to-account-owner" />
              </Text>
            ),
          }}
        />
      </Text>
      {!isUserAlreadyPartOfAFamily ? (
        <Button variant="primary" onClick={() => safeRedirect(history, AuthRoutes.FAMILY_ACCOUNT_CREATION)}>
          <Localized id="create-family-account" />
        </Button>
      ) : (
        <Button variant="primary" disabled>
          <Localized id="family-account-already-exists" />
        </Button>
      )}
      <Text color="secondary.main" textAlign="left" showPointer onClick={() => history.goBack()}>
        <Localized id="family-account-disclaimer-go-back" />
      </Text>
    </Flex>
  )
}

export default FamilyAccountDisclaimer
