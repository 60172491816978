import { parse } from 'query-string'
import { useLocation } from 'react-router-dom'
import { UseCorporateInvitationQuery, useUseCorporateInvitationQuery } from '../graphql'

import createSimpleQueryResult, { SimpleQueryResult } from '../utils/create-simple-query-result'

function useCorporateInvitation(): SimpleQueryResult<UseCorporateInvitationQuery> {
  const { search } = useLocation()
  const { corporatePartnerId, invite } = parse(search)
  const skip = !corporatePartnerId && !invite
  const queryResult = useUseCorporateInvitationQuery({
    variables: {
      id: corporatePartnerId ? String(corporatePartnerId) : undefined,
      token: invite ? String(invite) : undefined,
    },
    skip,
  })
  return createSimpleQueryResult({
    queryResult,
    skip,
  })
}

export default useCorporateInvitation
