import { default as MuiRadio } from '@mui/material/Radio'
import { makeStyles } from '@mui/styles'
import { PyroFieldInnerRenderProps } from 'pyro-form'
import * as React from 'react'

import { SimpleFlex } from '../Flex'
import Text from '../Text'

import { RadioButtonProps } from './RadioButton'

const useStyles = makeStyles({
  label: {
    width: '100%',
  },
})

// The default element type of Flex is 'div' and we don't allow the change of this prop
export type RadioButtonRef = HTMLElementTagNameMap['label']

const RadioButtonWrapper = React.forwardRef<RadioButtonRef, PyroFieldInnerRenderProps & RadioButtonProps>(
  // eslint-disable-next-line no-shadow
  function RadioButtonWrapper({ label, value, core: { onChange, value: selectedValue }, className, ...props }, ref) {
    const classes = useStyles()

    const handleUpdateValue = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value)
    }

    return (
      <SimpleFlex alignItems="center" ref={ref} as="label">
        <MuiRadio
          {...props}
          value={value}
          color="primary"
          disableRipple
          onChange={handleUpdateValue}
          className={className}
          checked={selectedValue === String(value)}
        />
        {label && (
          <Text is="div" className={classes.label}>
            {label}
          </Text>
        )}
      </SimpleFlex>
    )
  },
)

export default RadioButtonWrapper
