import * as React from 'react'

import Flex, { FlexProps } from '../../primitives/Flex'
import Icon, { Icons } from '../../primitives/Icon'

type ActionOutcomeType = 'success' | 'error'
type HTMLElementTagName = keyof HTMLElementTagNameMap

interface Props<T extends HTMLElementTagName> extends FlexProps<T> {
  type: ActionOutcomeType
}

const ActionOutcome: React.FC<React.PropsWithChildren<Props<HTMLElementTagName>>> = ({ type, children, ...rest }) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    verticalSpacing="default"
    marginTop="default"
    maxWidth="512px"
    {...rest}
  >
    {type === 'success' && <Icon icon={Icons.SUCCESS} color="primary.main" size="xxx-large" marginBottom="wide" />}
    {type === 'error' && <Icon icon={Icons.ERROR} color="danger" size="xxx-large" marginBottom="wide" />}
    {children}
  </Flex>
)

export default ActionOutcome
