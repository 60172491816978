import * as React from 'react'
import Typography, { TypographyProps } from '@mui/material/Typography'

const sizes = {
  'x-small': {
    fontSize: '12px',
  },
  small: {
    fontSize: '13px',
  },
  regular: {
    fontSize: '14px',
  },
  large: {
    fontSize: '16px',
  },
}

type Size = keyof typeof sizes

interface Props extends TypographyProps {
  size?: Size
}

const SimpleText = ({ size = 'regular', sx, ...rest }: Props) => {
  const { fontSize } = sizes[size]
  return <Typography {...rest} sx={{ ...sx, lineHeight: '150%', fontSize }} />
}

export default SimpleText
