import { styled } from '@eversports/react-components/design-tokens/styled-components'
import { HorizontalFlex } from '@eversports/react-components/primitives/Flex'
import Logo from '@eversports/react-components/primitives/Logo'
import Text from '@eversports/react-components/primitives/Text'
import { ClientError } from '@eversports/react-components/client-error'
import React from 'react'

interface Props {
  logo?: {
    large: string
  } | null
  error?: ClientError
}

const Image = styled.img`
  height: 64px;
`

const CorporatePartnerLogo: React.FC<React.PropsWithChildren<Props>> = ({ logo, error }) => {
  if (error) {
    return <Logo type="horizontal" height="32px" />
  }
  return logo ? (
    <HorizontalFlex horizontalSpacing="default" alignItems="center">
      <Logo type="vertical" height="64px" />
      <Text color="primary.main">+</Text>
      <Image src={logo.large} />
    </HorizontalFlex>
  ) : null
}

export default CorporatePartnerLogo
