import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@eversports/react-components/primitives/SvgIcon'

const AndroidIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 12 12" fill="none" {...props}>
    <path
      id="Subtract"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.9354 3.17069L2.79873 0.191752C2.59574 0.0701685 2.35882 0 2.1053 0C1.67888 0 1.29437 0.210609 1.04731 0.528458L1.13059 0.611716L5.81278 5.2929L7.9354 3.17069ZM0.763062 1.65833V10.3416L5.1056 5.99994L0.763062 1.65833ZM1.04733 11.4715C1.29443 11.7894 1.67896 12 2.1053 12C2.35336 12 2.58627 11.9329 2.786 11.8152L2.80186 11.8059L7.94422 8.83794L5.81279 6.70697L1.13059 11.3883L1.04733 11.4715ZM8.84093 8.3204L10.7932 7.19364C11.2141 6.96638 11.4999 6.5226 11.4999 6.01084C11.4999 5.50277 11.218 5.06118 10.8012 4.83318L10.7964 4.82991L8.83053 3.6898L6.51996 5.99993L8.84093 8.3204Z"
      fill="#666666"
    />
  </SvgIcon>
)

export default AndroidIcon
