import { Language } from '@eversports/language'

const GOOGLE_IDENTITY_LIBRARY = 'https://accounts.google.com/gsi/client'

const loadSDK = async ({ locale }: { locale: Language }) =>
  new Promise<void>((resolve, reject) => {
    const d = document
    const s = 'script' as const
    const id = 'google-client-sdk'

    if (d.getElementById(id)) return

    const js = d.createElement(s)
    js.id = id
    js.src = `${GOOGLE_IDENTITY_LIBRARY}?hl=${locale}`

    const fjs = d.getElementsByTagName('script')[0]
    js.onload = () => resolve()
    js.onerror = (err) => reject(err)
    fjs.parentNode!.insertBefore(js, fjs)
  })

export default loadSDK
