import { Theme } from '@eversports/react-components/design-tokens/theme'
import { SimpleFlex } from '@eversports/react-components/primitives/Flex'
import Text from '@eversports/react-components/primitives/Text'
import { makeStyles } from '@mui/styles'
import React from 'react'

type BackgroundSizeType = 'auto' | 'contain' | 'cover' | 'inherit' | 'initial' | 'unset'

interface Props {
  backgroundUrl: string
  backgroundSize?: BackgroundSizeType
  width?: string | number
  height?: string | number
  withOverlay?: boolean
  venueName?: string
}

const useStyles = makeStyles(({ palette }: Theme) => ({
  root: ({ height, width, backgroundUrl, backgroundSize, withOverlay }: Props) => ({
    width,
    height,
    backgroundImage: backgroundUrl && `url(${backgroundUrl})`,
    backgroundSize: `${backgroundSize}`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    position: 'relative',
    '&:before': {
      content: "''",
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      background: palette.text.primary,
      opacity: withOverlay ? 0.5 : 0,
    },
    '&:hover:before': {
      background: palette.black,
    },
  }),
  venueNameContainer: {
    height: '100%',
    position: 'relative',
  },
  venueNameContent: {
    maxHeight: '100%',
    wordBreak: 'break-word',
    padding: '0.5rem',
    overflow: 'hidden',
    hyphens: 'auto',
    textAlign: 'center',
    textTransform: 'none',
  },
}))

const VenueImage: React.FC<React.PropsWithChildren<Props>> = ({ venueName, ...props }) => {
  const classes = useStyles(props)
  return (
    <div className={classes.root}>
      {venueName && (
        <SimpleFlex alignItems="center" justifyContent="center" className={classes.venueNameContainer}>
          <Text color="white" textTransform="uppercase" fontWeight="bold" className={classes.venueNameContent}>
            {venueName}
          </Text>
        </SimpleFlex>
      )}
    </div>
  )
}

VenueImage.defaultProps = {
  width: 'auto',
  height: 'auto',
  backgroundSize: 'contain',
  withOverlay: false,
}

export default VenueImage
