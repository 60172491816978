import Heading from '@eversports/react-components/primitives/Heading'
import Logo from '@eversports/react-components/primitives/Logo'
import { ClientError } from '@eversports/react-components/client-error'
import React from 'react'

import VenueImage from './VenueImage'

interface LogoWrapperProps {
  name?: string
  logo?: { large: string } | null
  error?: ClientError
}

const VenueLogo: React.FC<React.PropsWithChildren<LogoWrapperProps>> = ({ name, logo, error }) => (
  <>
    {!error && logo ? (
      <VenueImage backgroundUrl={logo.large} width="170px" height="170px" />
    ) : (
      <Heading is="h1">{name}</Heading>
    )}
    {error && <Logo type="horizontal" height="32px" />}
  </>
)

export default VenueLogo
