import { motion } from '@eversports/react-components/design-tokens/motion'
import { parseApolloError } from '@eversports/react-components/logical/parse-apollo-error'
import { SimpleFlex } from '@eversports/react-components/primitives/Flex'
import Logo from '@eversports/react-components/primitives/Logo'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { parse } from 'query-string'

import { useLocation } from 'react-router-dom'
import CorporatePartnerLogo from '../components/CorporatePartnerLogo'
import VenueLogo from '../components/VenueLogo'
import useCorporateInvitation from '../data/use-corporate-invitation'
import useVenue from '../data/use-venue'
import { getAuthParams } from '../utils/get-auth-params'
import { Origin } from '../graphql'

const useStyles = makeStyles({
  wrapper: {
    height: 170,
    width: 170,
    marginBottom: 32,
    marginTop: 32,
    textAlign: 'center',
    '& *': {
      maxHeight: '100%',
    },
  },
})

const PageLogoWrapperContent: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    data: corporatePartnerData,
    error: corporatePartnerError,
    active: hasCorporatePartner,
  } = useCorporateInvitation()
  const { data: venueData, error: venueError } = useVenue()
  const location = useLocation()
  const params = getAuthParams(location.search)
  const { venueId } = parse(location.search)
  const hasVenueAndWidgetOrigin = venueId && params.origin === Origin.ORIGIN_WIDGET

  if (hasVenueAndWidgetOrigin) {
    return <VenueLogo {...venueData} error={parseApolloError(venueError)} />
  }

  if (hasCorporatePartner) {
    return (
      <CorporatePartnerLogo
        logo={corporatePartnerData?.corporatePartner.logo}
        error={parseApolloError(corporatePartnerError)}
      />
    )
  }

  return <Logo type="horizontal" height="32px" />
}

const PageLogoWrapper: React.FC<React.PropsWithChildren<unknown>> = () => {
  const classes = useStyles()

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <SimpleFlex alignItems="center" justifyContent="center" className={classes.wrapper}>
        <PageLogoWrapperContent />
      </SimpleFlex>
    </motion.div>
  )
}

export default PageLogoWrapper
