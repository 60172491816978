import { default as MuiDialogTitle } from '@mui/material/DialogTitle'
import * as React from 'react'

import Heading from '../Heading'
import { HeadingProps } from '../Heading/Heading'

const DialogTitle: React.FC<React.PropsWithChildren<Partial<HeadingProps>>> = ({ children, ...props }) => (
  <MuiDialogTitle>
    <Heading is="h2" {...props}>
      {children}
    </Heading>
  </MuiDialogTitle>
)

export default DialogTitle
