import CircularProgress from '@eversports/react-components/primitives/CircularProgress'
import Flex from '@eversports/react-components/primitives/Flex'
import ClientLogger from '@eversports/react-components/utilities/client-logger'
import safeRedirect from '@eversports/react-components/utilities/safe-redirect'
import { parse } from 'query-string'
import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { ClientError } from '@eversports/react-components/client-error'
import parseExpectedError from '@eversports/react-components/logical/parse-expected-error'
import ErrorBox from '@eversports/react-components/primitives/ErrorBox'
import { AuthRoutes } from '../auth-routes'
import handleAuthResult from '../utils/handle-auth-result'
import { useAccountActivationVerifyEmailMutation } from '../graphql'

const AccountActivation = () => {
  const history = useHistory()
  const { token } = parse(history.location.search)
  const [error, setError] = useState<ClientError | undefined>()

  const [activate] = useAccountActivationVerifyEmailMutation({
    variables: { token: token as string },
    onCompleted: ({ verifyEmail: result }) => {
      if (result.__typename === 'ExpectedErrors') {
        return setError(parseExpectedError(result.errors))
      }
      handleAuthResult({
        result,
        history,
        nextLocation: AuthRoutes.ACCOUNT_ACTIVATION_SUCCESS,
        params: { apiToken: result.apiToken },
      })
    },
    onError: (error) => {
      ClientLogger.error('User email verification failed', error)
      safeRedirect(history, AuthRoutes.ACCOUNT_ACTIVATION_FAILURE)
    },
  })

  useEffect(() => {
    activate().catch((error: Error) => ClientLogger.error(error))
  }, [])

  return (
    <Flex flexDirection="column" alignItems="center" verticalSpacing="default" marginTop="default" maxWidth="512px">
      {error ? <ErrorBox error={error} /> : <CircularProgress />}
    </Flex>
  )
}

export default AccountActivation
