import Flex from '@eversports/react-components/primitives/Flex'
import { makeStyles } from '@mui/styles'
import React from 'react'

import { Theme } from '@eversports/react-components/design-tokens/theme'
import DownloadLinks from '../components/DownloadLinks'
import WeUseEversports from '../components/WeUseEversports'
import PolicyWrapper from '../container/PolicyWrapper'
import useIsWidget from '../hooks/use-is-widget'

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    width: '100%',
    backgroundColor: theme.palette.grey[100],
  },
}))

const Footer = () => {
  const classes = useStyles()
  const isWidget = useIsWidget()
  return (
    <Flex
      is="footer"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      verticalSpacing="default"
      padding="default"
      className={classes.footer}
    >
      {isWidget && <WeUseEversports />}
      <Flex
        horizontalSpacing="dense"
        verticalSpacing="dense"
        justifyContent="center"
        flexWrap="wrap"
        flexDirection={isWidget ? 'row' : 'column'}
      >
        <DownloadLinks />
        <PolicyWrapper />
      </Flex>
    </Flex>
  )
}

export default Footer
