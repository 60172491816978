import { motion } from '@eversports/react-components/design-tokens/motion'
import Button from '@eversports/react-components/primitives/Button'
import ErrorBox from '@eversports/react-components/primitives/ErrorBox'
import Flex from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Link from '@eversports/react-components/primitives/Link'
import Processing from '@eversports/react-components/primitives/Processing'
import Text from '@eversports/react-components/primitives/Text'
import { ClientError } from '@eversports/react-components/client-error'
import React from 'react'

import { Localized } from '../../localization/react'

import { ConsentScreenEversportsAndVenueCurrentPoliciesQuery } from '../../graphql'
import ConsentFormModalButton from './ConsentFormModalButton'

export interface EversportsConsentFormProps {
  policies: ConsentScreenEversportsAndVenueCurrentPoliciesQuery['latestEversportsPolicies']
  loading?: boolean
  onConsentAcceptance: () => void
  onConsentRejection: () => void
  error?: ClientError
  errorUrl: string
}

const EversportsConsentForm = ({
  policies,
  loading,
  onConsentAcceptance,
  onConsentRejection,
  error,
  errorUrl,
}: EversportsConsentFormProps) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ ease: 'easeInOut' }}
  >
    <Processing isLoading={loading}>
      <Flex flexDirection="column" maxWidth="368px" verticalSpacing="default">
        <Heading is="h1" data-testid="eversports-consent-form-heading">
          <Localized id="eversports-consent-form-heading" />
        </Heading>
        <Text>
          <Localized id="eversports-consent-form-intro" />
        </Text>
        <Text>
          <Localized
            id="eversports-consent-form-consent-text"
            params={{
              terms: (
                <Link to={policies[0] ? policies[0].url : ''} external blank>
                  <Localized id="eversports-consent-form-terms-of-service" />
                </Link>
              ),
              policies: (
                <Link to={policies[1] ? policies[1].url : ''} external blank>
                  <Localized id="eversports-consent-form-privacy-policies" />
                </Link>
              ),
            }}
          />
        </Text>
        {error && (
          <ErrorBox>
            <Text color="danger" size="small" marginBottom="dense">
              <Localized id="consent-form-token-invalid-error" />
            </Text>
            <Link size="small" color="secondary" to={errorUrl}>
              <Localized id="consent-form-token-invalid-error-link" />
            </Link>
          </ErrorBox>
        )}
        <Flex horizontalSpacing="default" marginTop="wide">
          <ConsentFormModalButton onConsentAcceptance={onConsentAcceptance} onConsentRejection={onConsentRejection} />
          <Button data-testid="confirm-consent" variant="primary" fullWidth onClick={onConsentAcceptance}>
            <Localized id="eversports-consent-form-confirm" />
          </Button>
        </Flex>
      </Flex>
    </Processing>
  </motion.div>
)

export default EversportsConsentForm
