import React, { useEffect, useState } from 'react'
import { Language } from '@eversports/language'
import ClientLogger from '@eversports/react-components/utilities/client-logger'
import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import { getUniversalEnvVariable } from '@eversports/react-app-base/environmentCheck'
import { MAX_BUTTON_WIDTH } from '../LoginForm'
import { GoogleLoginReturnType, GoogleCredentialResponse } from './GoogleLoginButton.types'
import loadSDK from './helpers/loadSDK'

const GOOGLE_BUTTON_ID = 'buttonDiv'

const renderButton = ({ locale }: { locale: Language }) => {
  try {
    const buttonEl = document.getElementById(GOOGLE_BUTTON_ID)
    if (!buttonEl) {
      throw new Error('Cannot find the Google button element on page')
    }
    window.google.accounts.id.renderButton(buttonEl, {
      type: 'standard',
      theme: 'outline',
      size: 'large',
      text: 'continue_with',
      width: `${MAX_BUTTON_WIDTH}px`,
      locale,
    })
  } catch (e: any) {
    ClientLogger.error('Google login button could not be rendered', e)
  }
}

interface Props {
  onLogin?: (response: GoogleLoginReturnType) => void
  scope?: string
}

// From Google: "Using your own button is not supported, since there is no API to initiate the button flow when your button is clicked."
const GoogleLoginButton = ({ onLogin }: Props) => {
  const [isSDKLoaded, setIsSDKLoaded] = useState(false)
  const { language } = useSettingsContext()
  const clientId = getUniversalEnvVariable('OAUTH_GOOGLE_CLIENT_ID')

  const handleLogin = (response: GoogleCredentialResponse) => {
    if (!onLogin) return
    if (response && response.credential) {
      onLogin({
        status: 'success',
        accessToken: response.credential,
      })
    } else {
      onLogin({
        status: 'failure',
      })
    }
  }

  // check if window.google is already defined
  // we may have loaded it on the register or login page
  const loadGoogleSDK = async () => {
    if (!window.google) {
      await loadSDK({ locale: language })
    }
    setIsSDKLoaded(true)
  }

  useEffect(() => {
    if (clientId) {
      loadGoogleSDK()
    } else {
      ClientLogger.info('Google Auth API is not loaded because no clientID is set')
    }
  }, [clientId])

  useEffect(() => {
    if (isSDKLoaded && window.google) {
      try {
        window.google.accounts.id.initialize({
          client_id: clientId,
          callback: handleLogin,
        })
        renderButton({ locale: language })
      } catch (e: any) {
        ClientLogger.info('Could not initialize and render Google Login Button', e)
      }
    }
  }, [isSDKLoaded])

  return <div id={GOOGLE_BUTTON_ID} style={{ height: '44px' }} />
}

export default GoogleLoginButton
