// the key is the corporatePartnerId
// the value is some custom landing page url to redirect to
const CUSTOM_CORPORATE_REDIRECT_TO: { [key in string]: string } = {
  '08745158-27b4-47b9-94bf-4e096c80563e': 'https://www.eversportscorporate.com/de/dhl-eversports-hansefit/',
}

interface Props {
  corporateBenefits: {
    corporatePartner: {
      id: string
    }
  } | null
}

export default function getCustomCorporateRedirectPath({ corporateBenefits }: Props) {
  const corporatePartnerId = corporateBenefits?.corporatePartner.id
  return corporatePartnerId ? CUSTOM_CORPORATE_REDIRECT_TO[corporateBenefits.corporatePartner.id] : undefined
}
