import { ApolloError } from '@apollo/client'
import { parseApolloError } from '@eversports/react-components/logical/parse-apollo-error'
import parseExpectedError from '@eversports/react-components/logical/parse-expected-error'
import ErrorPage from '@eversports/react-components/patterns/ErrorPage'
import safeRedirect from '@eversports/react-components/utilities/safe-redirect'
import { ClientError } from '@eversports/react-components/client-error'
import { parse, stringify } from 'query-string'
import React, { useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { AuthRoutes } from '../auth-routes'
import InvitationForm, { InvitationFormValues } from '../components/InvitationForm'
import handleAuthResult from '../utils/handle-auth-result'
import {
  InvitationAcceptInvitationMutation,
  InvitationInvitationQuery,
  useInvitationAcceptInvitationMutation,
  useInvitationInvitationQuery,
} from '../graphql'

const Invitation = () => {
  const history = useHistory()
  const { search } = useLocation()
  const { token } = parse(search)
  const [error, setError] = useState<ClientError<InvitationFormValues>>()

  const handleQueryCompleted = (result?: InvitationInvitationQuery): void => {
    if (!result || !result.invitation) {
      return
    }

    const { invitation } = result

    const searchParams = parse(search)
    const newSearchParams = {
      ...searchParams,
      showAppPromotion: invitation.showAppPromotion,
    }

    if (stringify(searchParams) === stringify(newSearchParams)) return

    history.replace({
      search: stringify(newSearchParams),
    })
  }

  const handleMutationCompleted = ({ acceptInvitation: result }: InvitationAcceptInvitationMutation) => {
    if (result.__typename === 'ExpectedErrors') {
      return setError(parseExpectedError(result.errors))
    }

    return handleAuthResult({ result, history, nextLocation: AuthRoutes.INVITATION_SUCCESS })
  }

  const handleMutationError = (e: ApolloError) => {
    setError(parseApolloError(e))
  }

  const {
    data: invitationData,
    loading: invitationLoading,
    error: invitationError,
  } = useInvitationInvitationQuery({
    variables: { token: token as string },
    onCompleted: handleQueryCompleted,
    ssr: false,
  })
  const [acceptInvitation, { loading: acceptInvitationLoading, error: acceptInvitationError }] =
    useInvitationAcceptInvitationMutation({ onCompleted: handleMutationCompleted, onError: handleMutationError })

  const handleAcceptInvitation = ({ password }: InvitationFormValues) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    acceptInvitation({
      variables: { password, token: token as string },
    })
  }

  const handleRejectInvitation = () => {
    safeRedirect(history, AuthRoutes.REGISTER)
  }

  return token ? (
    <InvitationForm
      handleAcceptInvitation={handleAcceptInvitation}
      handleRejectInvitation={handleRejectInvitation}
      isFetching={invitationLoading}
      isSubmitting={acceptInvitationLoading}
      fetchError={parseApolloError(invitationError)}
      submissionError={parseApolloError(acceptInvitationError) || error}
      invitation={invitationData && invitationData.invitation}
    />
  ) : (
    <ErrorPage />
  )
}

export default Invitation
