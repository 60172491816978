import { useLocation } from 'react-router-dom'
import { getAuthParams } from '../utils/get-auth-params'
import { Origin } from '../graphql'

const useIsWidget = () => {
  const location = useLocation()
  const params = getAuthParams(location.search)
  return params.origin === Origin.ORIGIN_WIDGET
}

export default useIsWidget
