import getRegionFromHostname from '@eversports/react-components/utilities/get-region-from-hostname'
import { parse } from 'query-string'
import { AuthParamsInput, Origin } from '../graphql'

export { AuthParamsInput }

function mapStringToOrigin(input?: string): Origin {
  if (!input) return Origin.ORIGIN_MARKETPLACE

  switch (input.toLowerCase()) {
    case 'android':
      return Origin.ORIGIN_ANDROID
    case 'ios':
      return Origin.ORIGIN_IOS
    case 'eversports':
      return Origin.ORIGIN_MARKETPLACE
    case 'widget':
      return Origin.ORIGIN_WIDGET
    case 'provider-api':
      return Origin.ORIGIN_PROVIDER_API
    default:
      return Origin.ORIGIN_MARKETPLACE
  }
}

/*
// This would be a smooth implementation but sadly these regex types are not yet fully supported by our current chrome
// electron version
const REGION_REGEX_DEV = /^www-dev-(?<tld>.*)\.eversports\.local$/
const REGION_REGEX_TEST = /^www-test-(?<tld>.*)\.eversports\.dev$/
const REGION_REGEX_PRODUCTION = /^www\.eversports\.(?<tld>.*)$/

export function getRegionFromHostname(hostname: string): string | undefined {
  const match =
    REGION_REGEX_DEV.exec(hostname) || REGION_REGEX_TEST.exec(hostname) || REGION_REGEX_PRODUCTION.exec(hostname)
  return match && match.groups && match.groups.tld && match.groups.tld !== 'com'
    ? match.groups.tld.toUpperCase()
    : undefined
}
*/

export function getAuthParams(queryString: string): AuthParamsInput {
  const query = parse(queryString)
  const origin = mapStringToOrigin(query.origin as string)
  const corporatePartner = typeof query.corporatePartnerId === 'string' ? query.corporatePartnerId : null
  const corporateInvitationToken = typeof query.invite === 'string' ? query.invite : null
  const region = typeof window !== 'undefined' ? getRegionFromHostname(window.location.hostname) : null
  return {
    origin,
    corporatePartner,
    corporateInvitationToken,
    queryString,
    region,
  }
}
