/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

// DO NOT MODIFY
// FILE GENERATED BY `intl-codegen@2.2.1`
// https://github.com/eversport/intl-codegen
// DO NOT MODIFY

import { defineLoader } from "intl-codegen/runtime";

export const loadLanguage = defineLoader([
  "acceptedInvitationToken",
  "androidLabel",
  "appPromotionMessage",
  "appPromotionSubtext",
  "authenticationAccountAlreadyExists",
  "authenticationCreateAnAccountMessage",
  "authenticationFacebookPopUpBlockerDisclaimer",
  "authenticationForgotPasswordAction",
  "authenticationForgotPasswordBackToLogin",
  "authenticationForgotPasswordDescription",
  "authenticationForgotPasswordEmailLabel",
  "authenticationForgotPasswordHeading",
  "authenticationLoginAction",
  "authenticationLoginEmailLabel",
  "authenticationLoginPasswordLabel",
  "authenticationLoginSeparatorText",
  "authenticationLoginWelcomeMessage",
  "authenticationLoginWithFacebookAction",
  "authenticationLoginWithGoogleAction",
  "authenticationNoAccountYet",
  "authenticationRegistrationAction",
  "authenticationRegistrationContinueWithEmail",
  "authenticationRegistrationEmailLabel",
  "authenticationRegistrationFirstnameLabel",
  "authenticationRegistrationLastnameLabel",
  "authenticationRegistrationPasswordLabel",
  "authenticationRegistrationToggleSignUp",
  "back",
  "companyDetailsConfirmation",
  "companyDetailsHeader",
  "companyDetailsSubheader",
  "companyDetailsText",
  "companyNewsletterSubscriptionText",
  "companyNewsletterSubscriptionTitle",
  "consentFormTokenInvalidError",
  "consentFormTokenInvalidErrorLink",
  "cookieBannerAcceptAll",
  "cookieBannerConfirm",
  "cookieBannerCookiePreferences",
  "cookieBannerDescriptionLong",
  "cookieBannerDescriptionShort",
  "cookieBannerFurtherInformation",
  "cookieBannerLabelAdvertising",
  "cookieBannerLabelAdvertisingDescription",
  "cookieBannerLabelAnalytics",
  "cookieBannerLabelAnalyticsDescription",
  "cookieBannerLabelNecessary",
  "cookieBannerLabelNecessaryDescription",
  "cookieBannerPrivacyPolicy",
  "cookieBannerTechnicalNotes",
  "cookieBannerTitle",
  "corporateBudgetDisclaimer",
  "corporateBudgetSharedInformationAmount",
  "corporateBudgetSharedInformationDataProtectionLink",
  "corporateBudgetSharedInformationDate",
  "corporateBudgetSharedInformationEmail",
  "corporateBudgetSharedInformationHeader",
  "corporateBudgetSharedInformationLegalBasis",
  "corporateBudgetSharedInformationName",
  "corporateBudgetSharedInformationPurpose",
  "corporateInvitationTextBudget",
  "corporateInvitationTextBudgetExpiry",
  "corporateInvitationTextBudgetGeneral",
  "corporateInvitationTextBudgetOnce",
  "corporateInvitationTextDiscounts",
  "corporateInvitationTextIntro",
  "corporatePartnerTitle",
  "corporatePartnerVoucherCodeLabel",
  "createFamilyAccount",
  "dateUnitDay",
  "dateUnitDayPlural",
  "dateUnitGame",
  "dateUnitGamePlural",
  "dateUnitHour",
  "dateUnitHourPlural",
  "dateUnitMinute",
  "dateUnitMinutePlural",
  "dateUnitMonth",
  "dateUnitMonthPlural",
  "dateUnitSeason",
  "dateUnitSeasonPlural",
  "dateUnitUnit",
  "dateUnitUnitPlural",
  "dateUnitWeek",
  "dateUnitWeekPlural",
  "dateUnitWeekend",
  "dateUnitWeekendPlural",
  "dateUnitYear",
  "dateUnitYearPlural",
  "emailVerificationHeaderError",
  "emailVerificationHeaderSuccess",
  "emailVerificationTextError",
  "emailVerificationTextSuccess",
  "emailVerificationTextSuccessAction",
  "emailVerificationTextSuccessActionCheckout",
  "emailVerificationTextSuccessActionCorporate",
  "emailVerificationTextSuccessActionWidget",
  "eversports",
  "eversportsAndVenueConsentFormConsentAgreementHeader",
  "eversportsAndVenueConsentFormConsentAgreementText",
  "eversportsAndVenueConsentFormEnding",
  "eversportsAndVenueConsentFormIntro",
  "eversportsConsentFormConfirm",
  "eversportsConsentFormConsentText",
  "eversportsConsentFormDecline",
  "eversportsConsentFormHeading",
  "eversportsConsentFormIntro",
  "eversportsConsentFormModalHeading",
  "eversportsConsentFormModalText",
  "eversportsConsentFormPrivacyPolicies",
  "eversportsConsentFormTermsOfService",
  "eversportsDataProtectionDeclaration",
  "eversportsNewsletter",
  "expiredInvitationToken",
  "familyAccountAlreadyExists",
  "familyAccountDisclaimerAllPurchases",
  "familyAccountDisclaimerBilledToAccountOwner",
  "familyAccountDisclaimerGoBack",
  "familyAccountDisclaimerManageTheirBookings",
  "familyAccountDisclaimerText",
  "familyAccountDisclaimerTitle",
  "familyAccountDividerText",
  "familyInvitationEversportsInfo",
  "familyInvitationGoBack",
  "familyInvitationSharedInfo",
  "familyInvitationVenueInfo",
  "forgotPasswordTitle",
  "generalError",
  "invalidCorporateInvitation",
  "invalidInvitationToken",
  "invitationListedVenues",
  "invitationModalHeading",
  "invitationModalText",
  "invitationSuccessButton",
  "invitationSuccessHeader",
  "invitationSuccessText",
  "iOSLabel",
  "loginMetaDescription",
  "loginMetaTitle",
  "loginTitle",
  "month",
  "next",
  "no",
  "passwordResetButton",
  "passwordResetHeader",
  "passwordResetRequestedHeader",
  "passwordResetRequestedText",
  "passwordResetSuccessButton",
  "passwordResetSuccessHeader",
  "passwordResetSuccessText",
  "paymentUnitEuro",
  "privacyPolicy",
  "registrationMetaDescription",
  "registrationMetaTitle",
  "registrationSuccessAction",
  "registrationSuccessHeader",
  "registrationSuccessText",
  "registrationSuccessTitle",
  "registrationTermsAndConditions",
  "registrationTitle",
  "signinOr",
  "startNow",
  "tldCountryName",
  "venueInvitationConfirmation",
  "venueInvitationEversportsInfo",
  "venueInvitationGreeting",
  "venueInvitationHeading",
  "venueInvitationPasswordLabel",
  "venueInvitationRejection",
  "venueInvitationSetPassword",
  "venueInvitationSharedInfo",
  "venueInvitationVenueInfo",
  "weUse",
  "week",
  "year",
  "yes",
], {
  de: () => import("./locales/de.js"),
  en: () => import("./locales/en.js"),
  es: () => import("./locales/es.js"),
  nl: () => import("./locales/nl.js"),
  fr: () => import("./locales/fr.js"),
  it: () => import("./locales/it.js"),
}, "en");
