import Button from '@eversports/react-components/primitives/Button'
import Dialog, { DialogActions, DialogContent, DialogTitle } from '@eversports/react-components/primitives/Dialog'
import Text from '@eversports/react-components/primitives/Text'
import React, { useState } from 'react'

import { Localized } from '../../localization/react'

interface Props {
  onConsentRejection: () => void
  onConsentAcceptance: () => void
}

const ConsentFormModalButton = ({ onConsentRejection, onConsentAcceptance }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button variant="secondary" fullWidth onClick={() => setIsOpen(true)}>
        <Localized id="eversports-consent-form-decline" />
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>
          <Localized id="eversports-consent-form-modal-heading" />
        </DialogTitle>
        <DialogContent>
          <Text>
            <Localized id="eversports-consent-form-modal-text" />
          </Text>
        </DialogContent>
        <DialogActions>
          <Button variant="danger" onClick={onConsentRejection}>
            <Localized id="eversports-consent-form-decline" />
          </Button>
          <Button variant="primary" onClick={onConsentAcceptance}>
            <Localized id="eversports-consent-form-confirm" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ConsentFormModalButton
