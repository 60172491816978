import type { ApolloError } from '@apollo/client'
import type { GraphQLError } from 'graphql'

import { ClientError, Details, DetailsTypes } from '../client-error'

interface CustomApolloError<T> extends GraphQLError {
  message: string
  details: Details<T>
}

export interface ParsedError<T> extends ApolloError {
  graphQLErrors: Array<CustomApolloError<T>>
}

export const parseApolloError = <T>(error?: ApolloError): ClientError<T> | undefined => {
  if (!error) return undefined

  if (error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
    const parsedError = error as ParsedError<T>
    let message = parsedError.graphQLErrors[0].message as string | { message: string }

    if (typeof message === 'object') {
      message = message.message
    }

    return { type: 'message', message, details: parseApolloErrorDetails(parsedError) }
  }

  // TODO: Differentiate between network error types
  if (error.networkError) {
    return { type: 'localized', localizedId: 'network-error-message' }
  }

  return { type: 'localized', localizedId: 'unexpected-error-message' }
}

export const parseApolloErrorDetails = <T>(error: ParsedError<T>): Details<T> | undefined => {
  if (error.graphQLErrors[0] && error.graphQLErrors[0].details) {
    return error.graphQLErrors[0].details
  }

  return undefined
}

export const getParsedApolloErrorFormFields = <T>(
  error: ClientError<T> | undefined,
): Details<T>[DetailsTypes.VALIDATION] | undefined => {
  if (error && error.details && error.details.formFields) {
    return error.details.formFields
  }

  return undefined
}
