import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { Localized } from '../../localization/react'
import Heading from '../../primitives/Heading'
import Text from '../../primitives/Text'
import ClientLogger from '../../utilities/client-logger'
import ErrorPage from '../ErrorPage'

export class NotFound extends React.PureComponent<RouteComponentProps> {
  public componentDidMount() {
    const { pathname: pathName } = this.props.location
    ClientLogger.error({ title: '404 Error', pathName })
  }

  public render() {
    if (this.props.staticContext) {
      this.props.staticContext.statusCode = 404
    }

    return (
      <ErrorPage>
        <Heading data-testid="not-found" is="h1">
          <Localized id="not-found-title" />
        </Heading>
        <Text>
          <Localized id="not-found-message" />
        </Text>
      </ErrorPage>
    )
  }
}

const NotFoundWithRouter = withRouter(NotFound) as any
export default NotFoundWithRouter
