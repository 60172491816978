import { parseApolloError } from '@eversports/react-components/logical/parse-apollo-error'
import parseExpectedError from '@eversports/react-components/logical/parse-expected-error'
import { FamilyAccountMemberCreation as FamilyAccountMemberCreationComponent } from '@eversports/react-components/patterns/FamilyAccount'
import Processing from '@eversports/react-components/primitives/Processing'
import { ClientError } from '@eversports/react-components/client-error'
import React, { useEffect, useState } from 'react'

import { AuthRoutes } from '../auth-routes'
import useFamilyLogin from '../data/use-family-login'
import { useFamilyAccountMemberCreationAddFamilyMemberMutation } from '../graphql'

interface Member {
  firstname: string
  lastname: string
}

const FamilyAccountMemberCreation: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [error, setError] = useState<ClientError>()

  const [addFamilyMember, { loading: addFamilyMemberLoading, data: addFamilyMemberData }] =
    useFamilyAccountMemberCreationAddFamilyMemberMutation({ onError: (e) => setError(parseApolloError(e)) })
  const { familyLogin, isLoading } = useFamilyLogin(AuthRoutes.FAMILY_ACCOUNT_LOGIN)

  const handleSubmit = async (member: Member) => {
    await addFamilyMember({ variables: { member } })
  }

  useEffect(() => {
    if (!addFamilyMemberData) return

    if (addFamilyMemberData.addFamilyMember.__typename === 'ExpectedErrors') {
      setError(parseExpectedError(addFamilyMemberData.addFamilyMember.errors))
      return
    }

    // eslint-disable-next-line  @typescript-eslint/no-floating-promises
    familyLogin(addFamilyMemberData.addFamilyMember.id)
  }, [addFamilyMemberData])

  return (
    <Processing isLoading={addFamilyMemberLoading || isLoading}>
      <FamilyAccountMemberCreationComponent onSubmit={handleSubmit} error={error} />
    </Processing>
  )
}

export default FamilyAccountMemberCreation
