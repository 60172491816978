import { ParsedQuery } from 'query-string'

export const GOOGLE_TRACKING_SEARCH_PARAM = '_gl'

function getGoogleSearchParam(currentSearch: ParsedQuery<string>) {
  const _glValue = currentSearch._gl

  if (_glValue !== 'false' && typeof _glValue === 'string') {
    return `${GOOGLE_TRACKING_SEARCH_PARAM}=${_glValue}`
  }

  return ''
}

export default getGoogleSearchParam
