import React from 'react'
import Flex, { SimpleFlex } from '@eversports/react-components/primitives/Flex'

import Link from '@eversports/react-components/primitives/Link'
import { useLocation } from 'react-router-dom'
import Text from '@eversports/react-components/primitives/Text'
import { makeStyles } from '@mui/styles'
import Icon from '@eversports/react-components/primitives/Icon'
import { Theme } from '@eversports/react-components/design-tokens/theme'
import { Localized } from '../localization/react'
import { AuthRoutes } from '../auth-routes'
import LoginIcon from '../icons/LoginIcon'
import RegisterIcon from '../icons/RegisterIcon'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
    maxWidth: '350px',
    marginBottom: '32px',
  },
  selectedToggle: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '4px 4px 0px 0px',
  },
  toggle: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}))

const LoginRegisterToggle = () => {
  const classes = useStyles()
  const { pathname } = useLocation()

  const isRegisterPage = pathname === AuthRoutes.REGISTER

  return (
    <SimpleFlex flexDirection="row" alignItems="flex-end" className={classes.wrapper}>
      <Link to={AuthRoutes.LOGIN} fullWidth>
        <Flex
          horizontalSpacing="default"
          alignItems="center"
          justifyContent="center"
          className={!isRegisterPage ? classes.selectedToggle : classes.toggle}
        >
          <Icon icon={LoginIcon} size="large" color={isRegisterPage ? 'textGray' : 'primary.main'} />
          <Text
            textAlign="center"
            size="normal"
            padding="dense"
            color={!isRegisterPage ? 'primary.main' : 'darkGray'}
            fontWeight={!isRegisterPage ? 'bold' : 'normal'}
          >
            <Localized id="authentication-login-action" />
          </Text>
        </Flex>
      </Link>
      <Link to={AuthRoutes.REGISTER} data-testid="to-registration" fullWidth>
        <Flex
          horizontalSpacing="default"
          alignItems="center"
          justifyContent="center"
          className={isRegisterPage ? classes.selectedToggle : classes.toggle}
        >
          <Icon
            icon={RegisterIcon}
            size="large"
            color={isRegisterPage ? 'primary.main' : 'textGray'}
            marginRight="none"
          />

          <Text
            textAlign="center"
            size="normal"
            padding="dense"
            color={isRegisterPage ? 'primary.main' : 'darkGray'}
            fontWeight={isRegisterPage ? 'bold' : 'normal'}
          >
            <Localized id="authentication-registration-toggle-sign-up" />
          </Text>
        </Flex>
      </Link>
    </SimpleFlex>
  )
}

export default LoginRegisterToggle
