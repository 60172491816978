import { domAnimation, LazyMotion } from '@eversports/react-components/design-tokens/motion'
import PreserveQueryParam from '@eversports/react-components/logical/PreserveQueryParam'
import React from 'react'

import Layout from './Layout'

const App = () => (
  <>
    <PreserveQueryParam
      preserveParam={['redirectApp', 'redirectPath', 'origin', 'venueId', 'token', 'corporatePartnerId', 'invite']}
    />
    <LazyMotion features={domAnimation}>
      <Layout />
    </LazyMotion>
  </>
)

export default App
