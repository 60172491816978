export default function getRegionFromHostname(hostname: string): string | undefined {
  // Our internal hostnames always look like `www-<stage>-<TLD>-`
  for (const stage of ['dev', 'test', 'production']) {
    // Check if the hostname starts with the stage prefix
    const prefix = `www-${stage}-`
    if (hostname.startsWith(prefix)) {
      // Take the next two characters after the prefix
      return hostname.slice(prefix.length, prefix.length + 2).toUpperCase()
    }
  }

  // Our external production hostnames look like `www.eversports.<TLD>`
  const tld = hostname.replace('www.eversports.', '').toUpperCase()
  return tld !== 'com' ? tld.toUpperCase() : undefined
}
