import { Location } from 'history'
import { parse } from 'query-string'

export type RedirectApp = 'checkout' | 'marketplace' | 'widget' | 'phoenix'

function mapStringToRedirectApp(redirectApp?: string): RedirectApp {
  if (!redirectApp) return 'marketplace'

  switch (redirectApp.toLowerCase()) {
    case 'checkout':
      return 'checkout'
    case 'phoenix':
      return 'phoenix'
    case 'widget':
      return 'widget'
    default:
      return 'marketplace'
  }
}

export default function getRedirectApp(location: Location) {
  const query = parse(location.search)
  return mapStringToRedirectApp(query.redirectApp as string)
}
