import { motion } from '@eversports/react-components/design-tokens/motion'
import Button from '@eversports/react-components/primitives/Button'
import Flex from '@eversports/react-components/primitives/Flex'
import Link from '@eversports/react-components/primitives/Link'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'

import { useLocation } from 'react-router-dom'
import { Localized } from '../localization/react'
import getRedirectTo from '../utils/get-redirect-to'

const container = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeInOut',
      delay: 0.3,
      duration: 0.5,
    },
  },
}

const button = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeInOut',
      delay: 0.1,
      duration: 0.5,
    },
  },
}
const RegistrationSuccess = () => {
  const location = useLocation()
  const redirectTo = getRedirectTo(location)

  return (
    <motion.div variants={container} initial="hidden" animate="visible">
      <Flex flexDirection="column" verticalSpacing="default">
        <Text>
          <Localized id="registration-success-title" />
        </Text>
        <motion.span variants={button}>
          <Link to={redirectTo} external>
            <Button fullWidth variant="primary">
              <Localized id="registration-success-title" />
            </Button>
          </Link>
        </motion.span>
      </Flex>
    </motion.div>
  )
}

export default RegistrationSuccess
