import { makeStyles } from '@mui/styles'
import * as React from 'react'

import { Theme } from '../../design-tokens/theme'
import { Localized } from '../../localization/react'
import BaseLink from '../../primitives/BaseLink'
import Flex from '../../primitives/Flex'
import Icon, { Icons } from '../../primitives/Icon'
import Text from '../../primitives/Text'

import { User } from './types'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
  },
  changeUser: {
    textDecoration: 'underline',
  },
}))

interface Props {
  owner: User
  loggedInUser: User
  returnUrl: string
}

const FamilyAccountSelectedAccountBanner: React.FC<React.PropsWithChildren<Props>> = ({
  owner,
  loggedInUser,
  returnUrl,
}) => {
  const classes = useStyles()
  const isOwnerLoggedIn = owner.email === loggedInUser.email
  const loggedInUserFullName = `${loggedInUser.firstName} ${loggedInUser.lastName}`
  const ownerName = `${owner.firstName} ${owner.lastName}`

  return (
    <Flex
      flexDirection={['column', 'row', 'row']}
      padding="default"
      justifyContent="center"
      alignItems="center"
      horizontalSpacing="dense"
      className={classes.container}
      fullWidth
    >
      <Text color="white">
        <Localized
          id="family-account-banner-currently-booking-for"
          params={{
            loggedInUserName: (
              <Text fontWeight="bold" color="white" is="span">
                {loggedInUserFullName}
              </Text>
            ),
          }}
        />
      </Text>
      {!isOwnerLoggedIn && (
        <Text color="white">
          <Localized id="family-account-banner-owner-disclaimer" params={{ ownerName }} />
        </Text>
      )}
      <Flex justifyContent="space-between">
        <Icon icon={Icons.EDIT} color="white" />
        <BaseLink to={returnUrl} external>
          <Text color="white" className={classes.changeUser}>
            <Localized id="family-account-banner-change-user" />
          </Text>
        </BaseLink>
      </Flex>
    </Flex>
  )
}

export default FamilyAccountSelectedAccountBanner
