import { motion } from '@eversports/react-components/design-tokens/motion'
import { getParsedApolloErrorFormFields } from '@eversports/react-components/logical/parse-apollo-error'
import Button from '@eversports/react-components/primitives/Button'
import ErrorBox from '@eversports/react-components/primitives/ErrorBox'
import Flex from '@eversports/react-components/primitives/Flex/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Processing from '@eversports/react-components/primitives/Processing/Processing'
import TextField from '@eversports/react-components/primitives/TextField'
import { ClientError } from '@eversports/react-components/client-error'
import { makeStyles } from '@mui/styles'
import PyroForm, { Form } from 'pyro-form'
import React from 'react'

import { Localized } from '../localization/react'

interface Props {
  onResetPassword?: (values: PasswordResetFormValues) => void
  loading: boolean
  error?: ClientError
}

export interface PasswordResetFormValues {
  password: string
}

const initialValues: PasswordResetFormValues = { password: '' }

const useStyles = makeStyles({
  form: {
    width: '100%',
    maxWidth: 350,
  },
})

const ForgotPasswordForm: React.FC<React.PropsWithChildren<Props>> = ({ onResetPassword, loading, error }) => {
  const classes = useStyles()
  return (
    <PyroForm initialValues={initialValues} errors={getParsedApolloErrorFormFields(error)} onSubmit={onResetPassword}>
      {({ hasErrors }) => (
        <Form className={classes.form}>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ ease: 'easeInOut' }}>
            <Processing isLoading={loading}>
              <Heading is="h1" marginBottom="wide">
                <Localized id="password-reset-header" />
              </Heading>
              <Flex flexDirection="column" verticalSpacing="default">
                <TextField name="password" label="Password" required fullWidth type="password" />
                {error && <ErrorBox error={error} />}
                <Button data-testid="password-reset" type="submit" variant="primary" fullWidth disabled={hasErrors}>
                  <Localized id="password-reset-button" />
                </Button>
              </Flex>
            </Processing>
          </motion.div>
        </Form>
      )}
    </PyroForm>
  )
}

export default ForgotPasswordForm
