import React from 'react'

import { Localized } from '../localization/react'
import { RedirectApp } from '../utils/get-redirect-app'

interface Props {
  redirectApp: RedirectApp
  venueName?: string
  hasCustomRedirect: boolean
}

const RedirectAppText = ({ redirectApp, venueName, hasCustomRedirect }: Props) => {
  if (hasCustomRedirect) {
    return <Localized id="email-verification-text-success-action-corporate" />
  }
  switch (redirectApp) {
    case 'phoenix':
    case 'checkout':
      return <Localized id="email-verification-text-success-action-checkout" />
    case 'widget':
      return <Localized id="email-verification-text-success-action-widget" params={{ venueName: venueName! }} />
    default:
      return <Localized id="email-verification-text-success-action" />
  }
}

export default RedirectAppText
