import safeRedirect from '@eversports/react-components/utilities/safe-redirect'
import { History, Path } from 'history'
import { stringify } from 'query-string'
import { decorateLinkWithGAParameters } from '../utils/decorate-link-with-g-a-parameters'
import { RegistrationSource } from '../graphql'

import { AuthRoutes } from '../auth-routes'

import getRedirectTo from './get-redirect-to'
import getCustomCorporateRedirectPath from './get-custom-corporate-redirect-path'

export interface AuthResult {
  apiToken: string
  user: {
    hasVerifiedEmail: boolean
    missingEversportsPolicies: Array<{ id: string }>
    family: { id: string } | null
    corporateBenefits: {
      corporatePartner: {
        id: string
      }
    } | null
  }
  registrationSource?: RegistrationSource | null
  activationLink?: string | null
}

export interface Options {
  result: AuthResult
  history: History
  userHasFamilyAccount?: boolean
  nextLocation?: Path
  params?: { [key: string]: string }
}

function handleAuthResult({ result, history, nextLocation, params }: Options) {
  const { user } = result
  const activationLink = result.activationLink || undefined

  // Check if the consent still needs to be accepted
  if (user.missingEversportsPolicies.length > 0) {
    return safeRedirect(history, {
      pathname: AuthRoutes.CONSENT,
      search: stringify({ activationLink, n: nextLocation, ...params }),
    })
  }

  // Check if the email still needs to be verified
  if (!user.hasVerifiedEmail) {
    return safeRedirect(history, {
      pathname: AuthRoutes.ACCOUNT_ACTIVATION_PENDING,
      search: stringify({ activationLink, n: nextLocation, ...params }),
    })
  }

  // Checks if the user is a complete user and has an associated family
  if (user && user.family) {
    return safeRedirect(history, {
      pathname: AuthRoutes.FAMILY_ACCOUNT_LOGIN,
      search: stringify({ activationLink, n: nextLocation, ...params }),
    })
  }

  // Check if user is part of a corporate program with a custom redirect after login (DHL)
  if (user && user.corporateBenefits) {
    const customRedirect = getCustomCorporateRedirectPath({
      corporateBenefits: user.corporateBenefits,
    })
    if (customRedirect) {
      return (window.location.href = decorateLinkWithGAParameters(customRedirect))
    }
  }

  if (nextLocation) {
    // Redirect user internally
    safeRedirect(history, { pathname: nextLocation, search: stringify({ ...params }) })
  } else {
    // Redirect user back to the other applications
    window.location.href = decorateLinkWithGAParameters(getRedirectTo(history.location))
  }
}

export default handleAuthResult
