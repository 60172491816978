import { parse, StringifiableRecord, stringify } from 'query-string'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import getGoogleSearchParam, { GOOGLE_TRACKING_SEARCH_PARAM } from './get-google-search-param-from-returnTo'

interface PreserveQueryParamProps<T extends Record<string, string | undefined> = Record<string, string | undefined>>
  extends RouteComponentProps<T> {
  preserveParam: string | Array<string>
}

class PreserveQueryParamRaw extends React.Component<PreserveQueryParamProps & React.PropsWithChildren<unknown>> {
  public shouldComponentUpdate(nextProps: PreserveQueryParamProps) {
    if (this.props.location.search === nextProps.location.search) return false

    // Get all params that should be preserved
    const preserveParams = Array.isArray(nextProps.preserveParam) ? nextProps.preserveParam : [nextProps.preserveParam]
    const diff: StringifiableRecord = {}
    const currentSearch = parse(this.props.location.search)
    const nextSearch = parse(nextProps.location.search)

    // If preserved params are currently specified but not mentioned on the new query string they are added to the diff
    for (const preserveParam of preserveParams) {
      if (
        preserveParam !== GOOGLE_TRACKING_SEARCH_PARAM &&
        nextSearch[preserveParam] === undefined &&
        currentSearch[preserveParam] !== undefined
      ) {
        diff[preserveParam] = currentSearch[preserveParam]
      }
    }

    const googleSearchParam = getGoogleSearchParam(currentSearch)

    // If there is no diff to add to the new query string return
    if (Object.keys(diff).length === 0) return false

    const search = stringify({ ...nextSearch, ...diff }) + (googleSearchParam && `&${googleSearchParam}`)
    // Add necessary preservations to the query string
    this.props.history.replace({
      ...nextProps.location,
      search,
    })

    return true
  }

  public render() {
    return this.props.children || null
  }
}

const PreserveQueryParam = withRouter(PreserveQueryParamRaw) as any
export default PreserveQueryParam
