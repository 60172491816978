import hydrateReactClient from '@eversports/react-app-base/hydrate-react-client'

import fragmentTypes from './graphql-checkout-possible-types'

import App from './container/App'
import getLocalizationProvider from './LocalizationProvider'

hydrateReactClient({
  basename: '/auth',
  App,
  getLocalizationProvider,
  apolloOptions: { possibleTypes: fragmentTypes.possibleTypes, uri: '/api/checkout' },
}).catch((error) => console.error(error))
