import * as React from 'react'

import { assertNever } from '../../utilities/assertNever'
import { Localized } from '../../localization/react'

import { ClientError } from '@eversports/react-components/client-error'

interface Props {
  error: ClientError
}

class ErrorFormatter extends React.PureComponent<Props> {
  public render() {
    const { error } = this.props
    switch (error.type) {
      case 'localized':
        return <Localized id={error.localizedId as any} />
      case 'message':
        return <>{error.message}</>
      default:
        return assertNever(error)
    }
  }
}

export default ErrorFormatter
