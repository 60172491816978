import { motion } from '@eversports/react-components/design-tokens/motion'
import { parseApolloError } from '@eversports/react-components/logical/parse-apollo-error'
import { SimpleFlex } from '@eversports/react-components/primitives/Flex'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import { makeStyles } from '@mui/styles'
import { parse } from 'query-string'
import React from 'react'

import { useLocation } from 'react-router-dom'
import CompanyDetails from '../components/CompanyDetails'
import { useInvitationSuccessInvitationQuery, useInvitationSuccessSubscribeToNewsletterMutation } from '../graphql'

const useStyles = makeStyles({
  container: {
    '& > *': {
      maxHeight: '100%',
    },
  },
})
const InvitationSuccess = () => {
  const { search } = useLocation()
  const classes = useStyles()
  const { amplitude } = useAmplitude()
  const { token } = parse(search)

  const {
    data: invitationData,
    loading: invitationLoading,
    error: invitationError,
  } = useInvitationSuccessInvitationQuery({
    variables: { token: token as string },
    ssr: false,
  })

  // subscribe to newsletter will only happen when we have a venue invitation
  // with one customer
  const [subscribeToNewsletter] = useInvitationSuccessSubscribeToNewsletterMutation()

  if (!invitationData || invitationLoading) {
    return null
  }

  const { invitedBy, customers } = invitationData.invitation
  const showVenueNewsletterSubscription =
    invitedBy.__typename === 'Venue' && customers.length === 1 && customers[0].isSubscribedToNewsletter
  const venues = invitedBy.__typename === 'Venue' ? invitedBy.company.venues : null

  const handleSubscribeToNewsletter = () => {
    if (amplitude) {
      amplitude.logEvent('Subscribed to Venue Newsletter', {
        venue: {
          name: venues?.length ? venues[0].name : null,
        },
        where: 'invitation',
      })
    }
    // This handler will only be called when an invitation is done by a venue
    // and only one customer exists
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    subscribeToNewsletter({ variables: { customerId: customers[0].id } })
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: 'easeInOut' }}
    >
      <SimpleFlex className={classes.container}>
        <CompanyDetails
          venues={venues}
          fetchError={parseApolloError(invitationError)}
          onSubscribeNewsletter={handleSubscribeToNewsletter}
          showVenueNewsletterSubscription={Boolean(showVenueNewsletterSubscription)}
          invitedBy={invitedBy}
        />
      </SimpleFlex>
    </motion.div>
  )
}

export default InvitationSuccess
