import Button from '@eversports/react-components/primitives/Button'
import Dialog, { DialogActions, DialogContent, DialogTitle } from '@eversports/react-components/primitives/Dialog'
import Text from '@eversports/react-components/primitives/Text'
import React, { useState } from 'react'

import { Localized } from '../localization/react'

interface Props {
  venueName: string
  onInvitationRejection: () => void
  onInvitationAcceptance: () => void
}

const InvitationFormModalButton: React.FC<React.PropsWithChildren<Props>> = ({
  onInvitationRejection,
  onInvitationAcceptance,
  venueName,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleInvitationAcceptance = () => {
    setIsOpen(false)
    onInvitationAcceptance()
  }

  return (
    <>
      <Button variant="secondary" fullWidth onClick={() => setIsOpen(true)}>
        <Localized id="venue-invitation-rejection" />
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>
          <Localized id="invitation-modal-heading" />
        </DialogTitle>
        <DialogContent>
          <Text>
            <Localized id="invitation-modal-text" params={{ venueName }} />
          </Text>
        </DialogContent>
        <DialogActions>
          <Button variant="danger" onClick={onInvitationRejection}>
            <Localized id="venue-invitation-rejection" />
          </Button>
          <Button variant="primary" onClick={handleInvitationAcceptance}>
            <Localized id="venue-invitation-confirmation" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default InvitationFormModalButton
