import { parse } from 'querystring'

enum FromType {
  WIDGET = 'widget',
  HOMEPAGE = 'homepage',
  DISCOVERY = 'discovery',
  COURT_CALENDAR = 'court',
  ACTIVITY_DETAIL = 'activity detail',
  PRODUCT_DETAIL = 'product detail',
}

const getPageTypeFromReturnTo = (returnTo: string) => {
  if (!returnTo) {
    return FromType.HOMEPAGE
  }

  if (returnTo.includes('/scl/' || '/sw/' || '/se/')) {
    return FromType.ACTIVITY_DETAIL
  }

  if (returnTo.includes('/sp/')) {
    return FromType.PRODUCT_DETAIL
  }

  if (returnTo.includes('/sb/')) {
    return FromType.COURT_CALENDAR
  }

  if (returnTo.includes('/dcl/' || '/l/' || '/dco/' || '/dwo/' || '/dev/' || '/ded/' || '/dre/')) {
    return FromType.DISCOVERY
  }

  return FromType.HOMEPAGE
}

const getAmplitudeLoginData = (search: string) => {
  const searchParams = parse(search)

  if (!searchParams) {
    return FromType.HOMEPAGE
  }

  const redirectParams = parse(searchParams.redirectPath as string)

  if (redirectParams.origin === 'widget') {
    return FromType.WIDGET
  }

  const pageType = getPageTypeFromReturnTo(Object.values(redirectParams)[0] as string)

  return pageType
}

export default getAmplitudeLoginData
