import { parseApolloError } from '@eversports/react-components/logical/parse-apollo-error'
import { FamilyAccountSelection } from '@eversports/react-components/patterns/FamilyAccount'
import CircularProgress from '@eversports/react-components/primitives/CircularProgress'
import Placeholder from '@eversports/react-components/primitives/Placeholder'
import Processing from '@eversports/react-components/primitives/Processing'
import React from 'react'

import { AuthRoutes } from '../auth-routes'
import useFamilyLogin from '../data/use-family-login'
import { useFamilyAccountLoginMeQuery } from '../graphql'

const FamilyAccountLogin: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    data: loginData,
    loading: loginLoading,
    error: loginError,
  } = useFamilyAccountLoginMeQuery({
    fetchPolicy: 'no-cache',
  })
  const { familyLogin, isLoading, error } = useFamilyLogin()

  const handleContinueClick = async (userId: string) => {
    await familyLogin(userId)
  }

  if (!loginData || loginLoading) {
    return (
      <Placeholder height={200} width="100%" center>
        <CircularProgress />
      </Placeholder>
    )
  }

  if (!(loginData.me && loginData.me.family)) {
    throw new Error('We should only allow family logins for family accounts')
  }

  return (
    <Processing isLoading={isLoading}>
      <FamilyAccountSelection
        loggedInUser={loginData.me}
        family={loginData.me.family}
        memberCreationUrl={AuthRoutes.FAMILY_ACCOUNT_MEMBER_CREATION}
        onContinueClick={handleContinueClick}
        error={error || parseApolloError(loginError)}
      />
    </Processing>
  )
}

export default FamilyAccountLogin
