import { motion } from '@eversports/react-components/design-tokens/motion'
import { Theme } from '@eversports/react-components/design-tokens/theme'
import useMediaQuery from '@eversports/react-components/design-tokens/useMediaQuery'
import useTheme from '@eversports/react-components/design-tokens/useTheme'
import ErrorPage from '@eversports/react-components/patterns/ErrorPage'
import SubscribeToNewsletterButton from '@eversports/react-components/patterns/SubscribeToNewsletterBanner/SubscribeToNewsletterButton'
import BaseLink from '@eversports/react-components/primitives/BaseLink'
import Button from '@eversports/react-components/primitives/Button'
import Flex, { SimpleFlex } from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Text from '@eversports/react-components/primitives/Text'
import { ClientError } from '@eversports/react-components/client-error'
import { makeStyles } from '@mui/styles'
import React from 'react'

import { Localized } from '../localization/react'

import { InvitationSuccessInvitationQuery } from '../graphql'
import VenueImage from './VenueImage'

type InvitedBy = InvitationSuccessInvitationQuery['invitation']['invitedBy']
type Venue = (InvitedBy & { __typename: 'Venue' })['company']['venues'][number]
interface CompanyDetailsProps {
  venues: Array<Venue> | null
  fetchError?: ClientError
  onSubscribeNewsletter: () => void
  showVenueNewsletterSubscription: boolean
  invitedBy: InvitedBy
}

interface VenueDetailsProps {
  venue: Venue
}

function venueImage(venue: Venue): Venue['images'][number] {
  return venue.images[0] || venue.logo
}

const DESKTOP_VENUE_IMAGE_WIDTH = 200
const DESKTOP_VENUE_IMAGE_HEIGHT = 150
const MOBILE_VENUE_IMAGE_WIDTH = 150
const MOBILE_VENUE_IMAGE_HEIGHT = 112.5

const useStyles = makeStyles((theme: Theme) => ({
  venue: {
    marginBottom: theme.layoutSpacing.default,
    maxWidth: DESKTOP_VENUE_IMAGE_WIDTH,
    [theme.breakpoints.down('md')]: {
      maxWidth: MOBILE_VENUE_IMAGE_WIDTH,
    },
  },
  eversportsLogo: {
    width: DESKTOP_VENUE_IMAGE_WIDTH,
    height: DESKTOP_VENUE_IMAGE_HEIGHT,
    [theme.breakpoints.down('md')]: {
      width: MOBILE_VENUE_IMAGE_WIDTH,
      height: MOBILE_VENUE_IMAGE_HEIGHT,
    },
  },
  detailsContainer: {
    maxWidth: 960,
  },
}))

const venueSection = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeInOut',
      delay: 0.2,
      duration: 0.2,
    },
  },
}

const container = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeInOut',
      delay: 0.1,
      duration: 0.2,
    },
  },
}

const VenueDetails: React.FC<React.PropsWithChildren<VenueDetailsProps>> = ({ venue }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isInMobileView = useMediaQuery(theme.breakpoints.down('md'))

  const image = venueImage(venue)
  const link = `/widget/w/${venue.shortId}`

  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      verticalSpacing="default"
      marginLeft={isInMobileView ? 'default' : 'wide'}
      marginRight={isInMobileView ? 'default' : 'wide'}
      className={classes.venue}
    >
      <BaseLink to={link} external>
        <VenueImage
          backgroundUrl={image && image.large}
          width={isInMobileView ? MOBILE_VENUE_IMAGE_WIDTH : DESKTOP_VENUE_IMAGE_WIDTH}
          height={isInMobileView ? MOBILE_VENUE_IMAGE_HEIGHT : DESKTOP_VENUE_IMAGE_HEIGHT}
          backgroundSize="cover"
          venueName={venue.name}
          withOverlay
        />
      </BaseLink>
    </Flex>
  )
}

const CompanyDetails: React.FC<React.PropsWithChildren<CompanyDetailsProps>> = ({
  venues,
  fetchError,
  onSubscribeNewsletter,
  showVenueNewsletterSubscription,
  invitedBy,
}) => {
  const classes = useStyles()

  return !fetchError ? (
    <motion.div variants={container} initial="hidden" animate="visible">
      <Flex data-testid="company-details" alignItems="center" flexDirection="column">
        <Flex flexDirection="column" className={classes.detailsContainer} verticalSpacing="hero">
          <Heading is="h1" textAlign="center">
            <Localized id="company-details-header" />
          </Heading>
          {showVenueNewsletterSubscription && (
            <Flex flexDirection="column" alignItems="center" verticalSpacing="dense">
              <div>
                <Text textAlign="center" fontWeight="bold">
                  <Localized id="company-newsletter-subscription-title" />
                </Text>
                {invitedBy.__typename === 'Venue' && (
                  <Text textAlign="center">
                    <Localized id="company-newsletter-subscription-text" params={{ venueName: invitedBy.name }} />
                  </Text>
                )}
              </div>
              <SubscribeToNewsletterButton onClick={onSubscribeNewsletter} variant="primary" />
            </Flex>
          )}
          <div>
            <Text textAlign="center" fontWeight="bold">
              <Localized id="company-details-subheader" />
            </Text>
            {invitedBy.__typename === 'Venue' && (
              <Text textAlign="center" marginBottom="wide">
                <Localized id="company-details-text" />
              </Text>
            )}
          </div>
        </Flex>
        {invitedBy.__typename === 'Venue' ? (
          <motion.div variants={venueSection}>
            <SimpleFlex justifyContent="center" flexWrap="wrap">
              {venues &&
                venues.map((venue) => (
                  <motion.span whileHover={{ scale: 1.1 }} key={venue.id}>
                    <VenueDetails key={venue.name} venue={venue} />
                  </motion.span>
                ))}
            </SimpleFlex>
          </motion.div>
        ) : (
          <BaseLink to={'/'} marginTop="default" external>
            <Button variant="primary">
              <Localized id="family-invitation-go-back" />
            </Button>
          </BaseLink>
        )}
      </Flex>
    </motion.div>
  ) : (
    <ErrorPage error={fetchError} />
  )
}

export default CompanyDetails
