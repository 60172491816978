import Button from '@eversports/react-components/primitives/Button'
import ErrorBox from '@eversports/react-components/primitives/ErrorBox'
import Flex from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Link from '@eversports/react-components/primitives/Link'
import Processing from '@eversports/react-components/primitives/Processing'
import Text from '@eversports/react-components/primitives/Text'
import { ClientError } from '@eversports/react-components/client-error'
import React from 'react'

import { Localized } from '../../localization/react'

import { ConsentScreenEversportsAndVenueCurrentPoliciesQuery } from '../../graphql'
import ConsentFormModalButton from './ConsentFormModalButton'
import VenueConsentSection from './VenueConsentSection'

export interface EversportsAndVenueConsentFormProps {
  venueName: string
  eversportsPolicies: ConsentScreenEversportsAndVenueCurrentPoliciesQuery['latestEversportsPolicies']
  companyPolicies: ConsentScreenEversportsAndVenueCurrentPoliciesQuery['venue']['company']['latestPolicies']
  loading?: boolean
  onConsentAcceptance: () => void
  onConsentRejection: () => void
  error?: ClientError
  errorUrl: string
}

const EversportsAndVenueConsentForm = ({
  venueName,
  companyPolicies,
  eversportsPolicies,
  loading,
  onConsentAcceptance,
  onConsentRejection,
  error,
  errorUrl,
}: EversportsAndVenueConsentFormProps) => (
  <Processing isLoading={loading}>
    <Flex flexDirection="column" maxWidth="368px" verticalSpacing="default">
      <Heading is="h1" data-testid="eversports-and-venue-consent-form-heading">
        <Localized id="eversports-consent-form-heading" />
      </Heading>
      <Text>
        <Localized id="eversports-and-venue-consent-form-intro" params={{ venueName }} />
      </Text>
      <VenueConsentSection venueName="Eversports" policies={eversportsPolicies} showHeader />
      <VenueConsentSection venueName={venueName} policies={companyPolicies} showHeader />
      <Text>
        <Localized id="eversports-and-venue-consent-form-ending" params={{ venueName }} />
      </Text>
      {error && (
        <ErrorBox>
          <Text color="danger" size="small" marginBottom="dense">
            <Localized id="consent-form-token-invalid-error" />
          </Text>
          <Link size="small" color="secondary" to={errorUrl}>
            <Localized id="consent-form-token-invalid-error-link" />
          </Link>
        </ErrorBox>
      )}
      <Flex horizontalSpacing="default">
        <ConsentFormModalButton onConsentAcceptance={onConsentAcceptance} onConsentRejection={onConsentRejection} />
        <Button data-testid="confirm-consent" variant="primary" fullWidth onClick={onConsentAcceptance}>
          <Localized id="eversports-consent-form-confirm" />
        </Button>
      </Flex>
    </Flex>
  </Processing>
)

export default EversportsAndVenueConsentForm
