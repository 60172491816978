import { motion } from '@eversports/react-components/design-tokens/motion'
import ActionOutcome from '@eversports/react-components/patterns/ActionOutcome'
import Button from '@eversports/react-components/primitives/Button'
import Heading from '@eversports/react-components/primitives/Heading'
import Link from '@eversports/react-components/primitives/Link'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'

import { useLocation } from 'react-router-dom'
import { Localized } from '../localization/react'
import getRedirectTo from '../utils/get-redirect-to'

const container = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeInOut',
      delay: 0.1,
      duration: 0.2,
    },
  },
}

const button = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeInOut',
      delay: 0.2,
      duration: 0.2,
    },
  },
}
const PasswordResetSuccess = () => {
  const location = useLocation()
  return (
    <motion.div variants={container} initial="hidden" animate="visible">
      <ActionOutcome type="success">
        <Heading data-testid="password-reset-success" is="h2">
          <Localized id="password-reset-success-header" />
        </Heading>
        <Text textAlign="center">
          <Localized id="password-reset-success-text" />
        </Text>
        <motion.span variants={button}>
          <Link to={getRedirectTo(location)} external>
            <Button variant="primary" fullWidth>
              <Localized id="password-reset-success-button" />
            </Button>
          </Link>
        </motion.span>
      </ActionOutcome>
    </motion.div>
  )
}

export default PasswordResetSuccess
