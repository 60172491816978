import Link from '@eversports/react-components/primitives/Link'
import React from 'react'
import { SimpleText } from '@eversports/react-components/primitives/Text'
import Flex from '@eversports/react-components/primitives/Flex/Flex'
import { PolicyType, PolicyWrapperCurrentPoliciesQuery } from '../graphql'

import { Localized } from '../localization/react'

interface Props {
  policies: PolicyWrapperCurrentPoliciesQuery['latestEversportsPolicies']
}

const Policies: React.FC<React.PropsWithChildren<Props>> = ({ policies }) => {
  const privacyPolicy = policies.find((policy) => policy.type === PolicyType.POLICY_TYPE_PRIVACY)
  const termsOfServicePolicy = policies.find((policy) => policy.type === PolicyType.POLICY_TYPE_TERMS_OF_SERVICE)

  return privacyPolicy && termsOfServicePolicy ? (
    <Flex horizontalSpacing="dense">
      <Link size="small" color="subtle" to={privacyPolicy.url} external blank>
        <SimpleText size="x-small" sx={{ textTransform: 'capitalize' }}>
          <Localized id="privacy-policy" />
        </SimpleText>
      </Link>
      <Link size="small" color="subtle" to={termsOfServicePolicy.url} external blank>
        <SimpleText size="x-small" sx={{ textTransform: 'capitalize' }}>
          <Localized id="registration-terms-and-conditions" />
        </SimpleText>
      </Link>
    </Flex>
  ) : null
}

export default Policies
