import React from 'react'
import { SimpleFlex } from '@eversports/react-components/primitives/Flex'

import Text from '@eversports/react-components/primitives/Text'
import ESLogo from '../icons/ESLogo'
import { Localized } from '../localization/react'

const WeUseEversports = () => (
  <SimpleFlex alignItems="center">
    <Text color="subtleText" size="small">
      <Localized id="we-use" />
    </Text>
    <ESLogo style={{ width: '120px', height: '24px', margin: '0px 4px' }} />
  </SimpleFlex>
)

export default WeUseEversports
