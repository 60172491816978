import {
  Intl as IntlError,
  loadLanguage as loadLanguageError,
  Provider as LocalizationProviderError,
} from '@eversports/core-io/localization/react'
import {
  Intl as IntlComponents,
  loadLanguage as loadLanguageComponents,
  Provider as LocalizationProviderComponents,
} from '@eversports/react-components/localization/react'
import React from 'react'

import {
  Intl as IntlAuthentication,
  loadLanguage as loadLanguageAuthentication,
  Locales,
  Provider as LocalizationProviderAuthentication,
} from './localization/react'

interface Props {
  intlAuthentication: IntlAuthentication
  intlError: IntlError
  intlComponents: IntlComponents
}

const AuthenticationLocalizationProvider: React.FC<React.PropsWithChildren<Props>> = ({
  intlAuthentication,
  intlError,
  intlComponents,
  children,
}) => (
  <LocalizationProviderAuthentication value={intlAuthentication}>
    <LocalizationProviderError value={intlError}>
      <LocalizationProviderComponents value={intlComponents}>{children}</LocalizationProviderComponents>
    </LocalizationProviderError>
  </LocalizationProviderAuthentication>
)

async function getLocalizationProvider(locale: Locales): Promise<React.FC<React.PropsWithChildren<unknown>>> {
  const [intlAuthentication, intlError, intlComponents] = await Promise.all([
    loadLanguageAuthentication(locale),
    loadLanguageError(locale),
    loadLanguageComponents(locale),
  ])

  // eslint-disable-next-line react/display-name
  return (props) => (
    <AuthenticationLocalizationProvider
      intlAuthentication={intlAuthentication}
      intlError={intlError}
      intlComponents={intlComponents}
      {...props}
    />
  )
}

export default getLocalizationProvider
