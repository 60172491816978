import { getParsedApolloErrorFormFields } from '@eversports/react-components/logical/parse-apollo-error'
import Button from '@eversports/react-components/primitives/Button'
import Divider from '@eversports/react-components/primitives/Divider'
import ErrorBox from '@eversports/react-components/primitives/ErrorBox'
import Flex from '@eversports/react-components/primitives/Flex'
import Processing from '@eversports/react-components/primitives/Processing'
import TextField from '@eversports/react-components/primitives/TextField'
import { ClientError } from '@eversports/react-components/client-error'
import { makeStyles } from '@mui/styles'
import PyroForm, { Form } from 'pyro-form'
import React from 'react'

import { SimpleText } from '@eversports/react-components/primitives/Text'

import { palette } from '@eversports/react-components/design-tokens/theme'
import { Link } from 'react-router-dom'
import { Localized } from '../localization/react'

import FacebookLoginButton from './FacebookLoginButton'
import GoogleLoginButton from './GoogleLoginButton'
import { GoogleLoginReturnType } from './GoogleLoginButton/GoogleLoginButton.types'

// This width is to ensure the login buttons fit a small mobile screen.
// The Google button only accepts an absolute width, not percentages.
// Anything larger would cause the Google button to overflow the screen on mobile.
export const MAX_BUTTON_WIDTH = '288'

export interface CredentialLoginParameter {
  email: string
  password: string
}

export interface LoginFormProps {
  onCredentialsLogin: (credentials: CredentialLoginParameter) => void
  // eslint-disable-next-line no-undef
  onFacebookLogin: (response: FacebookSDKResponse) => void
  onGoogleLogin: (response: GoogleLoginReturnType) => void
  loading: boolean
  error?: ClientError
  forgotPasswordHref?: string
}

interface InitialValues {
  email: string
  password: string
}

const initialValues: InitialValues = { email: '', password: '' }

const useStyles = makeStyles({
  form: {
    width: '100%',
    maxWidth: 350,
  },
  buttonContainer: {
    width: `${MAX_BUTTON_WIDTH}px`,
  },
  forgotPwd: {
    color: palette.primary,
    fontWeight: 'bold',
    textDecoration: 'underline',
    alignSelf: 'flex-start',
    fontSize: '14px',
    display: 'inline',
    margin: '8px 0px 32px',
  },
})

const LoginForm: React.FC<React.PropsWithChildren<LoginFormProps>> = ({
  onCredentialsLogin,
  onFacebookLogin,
  onGoogleLogin,
  loading,
  error,
  forgotPasswordHref = '',
}) => {
  const classes = useStyles()
  return (
    <PyroForm
      initialValues={initialValues}
      errors={getParsedApolloErrorFormFields(error)}
      onSubmit={onCredentialsLogin}
    >
      {({ hasErrors }) => (
        <Form className={classes.form}>
          <Processing isLoading={loading}>
            <Flex flexDirection="column" verticalSpacing="default" alignItems="center">
              <TextField
                name="email"
                label={<Localized id="authentication-login-email-label" />}
                fullWidth
                type="email"
                autoComplete="email"
              />
              <TextField
                name="password"
                label={<Localized id="authentication-login-password-label" />}
                fullWidth
                type="password"
                autoComplete="current-password"
              />
              {error && <ErrorBox error={error} />}
              <Link data-testid="to-forgot-password" to={forgotPasswordHref} className={classes.forgotPwd}>
                <Localized id="authentication-forgot-password-heading" />
              </Link>
              <Flex flexDirection="column" verticalSpacing="default" className={classes.buttonContainer}>
                <Button data-testid="login" variant="primary" type="submit" fullWidth disabled={hasErrors}>
                  <SimpleText sx={{ textTransform: 'none' }}>
                    <Localized id="authentication-login-action" />
                  </SimpleText>
                </Button>
                <Divider>
                  <Localized id="authentication-login-separator-text" />
                </Divider>
                <GoogleLoginButton onLogin={onGoogleLogin} />
                <FacebookLoginButton onLogin={onFacebookLogin} />
              </Flex>
            </Flex>
          </Processing>
        </Form>
      )}
    </PyroForm>
  )
}

export default LoginForm
