export enum AuthRoutes {
  LOGIN = '/',
  REGISTER = '/register',
  CONSENT = '/consent',
  REGISTRATION_SUCCESS = '/register-success',
  ACCOUNT_ACTIVATION_PENDING = '/account-activation-pending',
  ACCOUNT_ACTIVATION = '/email-verification',
  ACCOUNT_ACTIVATION_SUCCESS = '/account-activation-success',
  ACCOUNT_ACTIVATION_FAILURE = '/account-activation-failure',
  FORGOT_PASSWORD = '/forgot-password',
  PASSWORD_RESET = '/reset',
  PASSWORD_RESET_REQUESTED = '/reset-requested',
  PASSWORD_RESET_SUCCESS = '/reset-successful',
  INVITATION = '/invitation',
  INVITATION_SUCCESS = '/invitation-success',
  SELF = '/self',
  CORPORATE_PARTNER = '/corporate-partner',
  FAMILY_ACCOUNT_DISCLAIMER = '/family-account-disclaimer',
  FAMILY_ACCOUNT_CREATION = '/family-account-creation',
  FAMILY_ACCOUNT_LOGIN = '/family-account-login',
  FAMILY_ACCOUNT_MEMBER_CREATION = '/family-account-member-creation',
}
