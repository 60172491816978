import { styled } from '@eversports/react-components/design-tokens/styled-components'
import BaseLink from '@eversports/react-components/primitives/BaseLink'
import Button from '@eversports/react-components/primitives/Button'
import Divider from '@eversports/react-components/primitives/Divider'
import Flex, { VerticalFlex } from '@eversports/react-components/primitives/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Text from '@eversports/react-components/primitives/Text'
import UnorderedList from '@eversports/react-components/primitives/UnorderedList'
import React from 'react'

import Link from '@eversports/react-components/primitives/Link'
import { AuthRoutes } from '../auth-routes'
import { Localized } from '../localization/react'

interface Unlimited {
  __typename: 'Unlimited'
}

interface Limited {
  __typename: 'Limited'
  limit: {
    value: number
    currency: string
  }
  displayCapped: boolean
}

interface BudgetProps {
  type: 'ONCE' | 'RENEWING'
  interval: 'MONTH' | 'YEAR' | 'WEEK' | null
  employeeLimit: Limited | Unlimited
  totalLimit: Limited | Unlimited
  expiry: string | null
}

interface Props {
  corporatePartner: {
    name: string
    description: string
  }
  budget: null | BudgetProps
  hasSpecialPriceAccess: boolean
}

interface CorporateBudgetComponentProps {
  budget: BudgetProps
  corporatePartnerName: string
}

const Wrapper = styled(VerticalFlex)`
  width: 100%;
  max-width: 600px;
`

const GrowingLink = styled(BaseLink)`
  flex-basis: 0;
  flex-grow: 1;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  line-height: 1.4;
  gap: ${({ theme }) => theme.spacing(1)};

  & > ul {
    list-style: disc;
    margin-left: ${({ theme }) => theme.spacing(3)};
  }
`

const CorporateBudgetComponent = ({ budget, corporatePartnerName }: CorporateBudgetComponentProps) => {
  const limit = budget.employeeLimit.__typename === 'Limited' ? budget.employeeLimit.limit : null
  const interval = budget.interval

  const hasRecurringBudget = limit && interval
  const hasOneTimeBudget = limit && !interval

  return (
    <Content>
      <Text fontWeight="bold">
        {hasRecurringBudget && (
          <Localized
            id="corporate-invitation-text-budget"
            params={{
              corporatePartnerName,
              interval,
              amount: {
                value: limit.value / 100,
                currency: limit.currency,
              },
            }}
          />
        )}
        {hasOneTimeBudget && (
          <Localized
            id="corporate-invitation-text-budget-once"
            params={{
              corporatePartnerName,
              amount: {
                value: limit.value / 100,
                currency: limit.currency,
              },
            }}
          />
        )}
        {!limit && (
          <Localized
            id="corporate-invitation-text-budget-general"
            params={{
              corporatePartnerName,
            }}
          />
        )}
      </Text>
      {budget.expiry && (
        <Text>
          <Localized
            id="corporate-invitation-text-budget-expiry"
            params={{
              expiry: new Date(budget.expiry),
            }}
          />
        </Text>
      )}
    </Content>
  )
}

const CorporatePartnerComponent = ({ corporatePartner, budget, hasSpecialPriceAccess }: Props) => (
  <Wrapper verticalSpacing="default">
    <Heading is="h1">
      <Localized id="corporate-partner-title" params={{ corporatePartnerName: corporatePartner.name }} />
    </Heading>
    <Text>
      <Localized id="corporate-invitation-text-intro" params={{ corporatePartnerName: corporatePartner.name }} />
    </Text>

    {budget && <CorporateBudgetComponent budget={budget} corporatePartnerName={corporatePartner.name} />}

    {hasSpecialPriceAccess && (
      <Text>
        <Localized id="corporate-invitation-text-discounts" params={{ corporatePartnerName: corporatePartner.name }} />
      </Text>
    )}

    {corporatePartner.description && <Text>{corporatePartner.description}</Text>}

    <Divider />

    <Text>
      <Localized id="corporate-budget-disclaimer" params={{ corporatePartnerName: corporatePartner.name }} />
    </Text>
    <Flex
      horizontalSpacing={['none', 'default']}
      verticalSpacing={['default', 'none']}
      flexDirection={['column', 'row']}
    >
      <GrowingLink to={AuthRoutes.REGISTER}>
        <Button variant="primary" fullWidth>
          <Localized id="start-now" />
        </Button>
      </GrowingLink>
    </Flex>
    <Content>
      <Divider />
      <Text size="x-small">
        <Localized id="corporate-budget-shared-information-header" />
      </Text>
      <UnorderedList bulletSize="x-small">
        <Text size="x-small">
          <Localized id="corporate-budget-shared-information-email" />
        </Text>
        <Text size="x-small">
          <Localized id="corporate-budget-shared-information-name" />
        </Text>
        <Text size="x-small">
          <Localized id="corporate-budget-shared-information-date" />
        </Text>
        <Text size="x-small">
          <Localized id="corporate-budget-shared-information-amount" />
        </Text>
      </UnorderedList>
      <Text size="x-small">
        <Localized
          id="corporate-budget-shared-information-purpose"
          params={{ corporatePartnerName: corporatePartner.name }}
        />
      </Text>
      <Text size="x-small">
        <Localized id="corporate-budget-shared-information-legal-basis" />
      </Text>
      <Text size="x-small">
        <Localized
          id="corporate-budget-shared-information-data-protection-link"
          params={{
            link: (
              <Link size="x-small" to="/h/privacy" external blank>
                <Localized id="eversports-data-protection-declaration" />
              </Link>
            ),
          }}
        />
      </Text>
    </Content>
  </Wrapper>
)

export default CorporatePartnerComponent
