import * as React from 'react'
import { useState } from 'react'
import Dialog, { DialogContent, DialogTitle } from '@eversports/react-components/primitives/Dialog'
import Switch from '@eversports/react-components/primitives/Switch'
import Link from '@eversports/react-components/primitives/Link'
import Flex, { SimpleFlex } from '@eversports/react-components/primitives/Flex'
import Button from '@eversports/react-components/primitives/Button'
import Text from '@eversports/react-components/primitives/Text'
import { makeStyles } from '@mui/styles'
import { Theme } from '@eversports/react-components/design-tokens/theme'
import { Localized } from '../localization/react'
import { TrackingType } from '../utils/tracking-preferences'

const useStyles = makeStyles((theme: Theme) => ({
  cookieBannerButton: {
    borderColor: theme.palette.mediumGray,
  },
}))

interface CookieBannerComponentProps {
  isOpen: boolean
  isAnalyticsChecked: boolean
  isAdvertisingChecked: boolean
  handleOnChange: (type: TrackingType) => (event: React.ChangeEvent<HTMLInputElement>) => void
  handleClickAccept: () => void
  handleClickConfirm: () => void
}

const CookieBannerComponent = ({
  isOpen,
  isAnalyticsChecked,
  isAdvertisingChecked,
  handleOnChange,
  handleClickAccept,
  handleClickConfirm,
}: CookieBannerComponentProps) => {
  const [showCookiePreferences, setShowCookiePreferences] = useState(false)

  const classes = useStyles()

  return (
    <Dialog
      data-testid="auth-cookie-banner"
      open={isOpen}
      sx={{
        '& .MuiDialog-paper': {
          background: '#ffffff',
          border: '1px solid #F2F2F2',
          boxSizing: 'border-box',
          boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.12)',
          borderRadius: '16px',
          minWidth: { lg: '400px' },
          maxWidth: { lg: '680px' },
        },
      }}
    >
      <DialogTitle>
        <Localized id="cookie-banner-title" />
      </DialogTitle>
      <DialogContent>
        <Flex flexDirection="column" verticalSpacing="default">
          {!showCookiePreferences ? (
            <Text size="small">
              <Localized id="cookie-banner-description-short" />
            </Text>
          ) : (
            <>
              <Text size="small">
                <Localized id="cookie-banner-description-long" />
              </Text>
              <Text size="small">
                <Localized
                  id="cookie-banner-further-information"
                  params={{
                    privacyPolicy: (
                      <Link size="small" to="/h/privacy" external blank>
                        <Localized id="cookie-banner-privacy-policy" />
                      </Link>
                    ),
                    technicalNotes: (
                      <Link size="small" to="https://www.eversportsmanager.com/de/technische-hinweise" external blank>
                        <Localized id="cookie-banner-technical-notes" />
                      </Link>
                    ),
                  }}
                />
              </Text>
              <Flex flexDirection="column" verticalSpacing="wide">
                <Flex flexDirection="column" verticalSpacing="dense">
                  <SimpleFlex alignItems="center">
                    <Switch checked disabled />
                    <Text fontWeight="bold">
                      <Localized id="cookie-banner-label-necessary" />
                    </Text>
                  </SimpleFlex>
                  <Text size="x-small">
                    <Localized id="cookie-banner-label-necessary-description" />
                  </Text>
                </Flex>
                <Flex flexDirection="column" verticalSpacing="dense">
                  <SimpleFlex alignItems="center">
                    <Switch checked={isAnalyticsChecked} onChange={handleOnChange(TrackingType.ANALYTICS)} />
                    <Text fontWeight="bold">
                      <Localized id="cookie-banner-label-analytics" />
                    </Text>
                  </SimpleFlex>
                  <Text size="x-small">
                    <Localized id="cookie-banner-label-analytics-description" />
                  </Text>
                </Flex>
                <Flex flexDirection="column" verticalSpacing="dense">
                  <SimpleFlex alignItems="center">
                    <Switch checked={isAdvertisingChecked} onChange={handleOnChange(TrackingType.ADVERTISING)} />
                    <Text fontWeight="bold">
                      <Localized id="cookie-banner-label-advertising" />
                    </Text>
                  </SimpleFlex>
                  <Text size="x-small">
                    <Localized id="cookie-banner-label-advertising-description" />
                  </Text>
                </Flex>
              </Flex>
            </>
          )}
          <Flex justifyContent="space-between" flexWrap="wrap" horizontalSpacing="wide" verticalSpacing="dense">
            {showCookiePreferences ? (
              <Button onClick={handleClickConfirm} variant="secondary">
                <Text color="primary.main">
                  <Localized id="cookie-banner-confirm" />
                </Text>
              </Button>
            ) : (
              <Button
                className={classes.cookieBannerButton}
                onClick={() => setShowCookiePreferences(true)}
                variant="secondary"
              >
                <Text>
                  <Localized id="cookie-banner-cookie-preferences" />
                </Text>
              </Button>
            )}
            <Button data-testid="accept-all-cookies" onClick={handleClickAccept} variant="primary">
              <Text color="white" fontWeight="bold">
                <Localized id="cookie-banner-accept-all" />
              </Text>
            </Button>
          </Flex>
        </Flex>
      </DialogContent>
    </Dialog>
  )
}
export default CookieBannerComponent
