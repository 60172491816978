import { ApolloError } from '@apollo/client'
import { parseApolloError } from '@eversports/react-components/logical/parse-apollo-error'
import parseExpectedError from '@eversports/react-components/logical/parse-expected-error'
import getRegionFromHostname from '@eversports/react-components/utilities/get-region-from-hostname'
import safeRedirect from '@eversports/react-components/utilities/safe-redirect'
import { ClientError } from '@eversports/react-components/client-error'
import { stringify } from 'query-string'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { AuthRoutes } from '../auth-routes'
import ForgotPasswordForm from '../components/ForgotPasswordForm'
import { useForgotPasswordRequestPasswordResetMutation } from '../graphql'

const ForgotPassword = () => {
  const history = useHistory()
  const location = useLocation()
  const [error, setError] = useState<ClientError | undefined>()

  const [requestPasswordResetMutation, { loading }] = useForgotPasswordRequestPasswordResetMutation({
    onCompleted: ({ requestPasswordReset }) =>
      requestPasswordReset.__typename === 'ExpectedErrors'
        ? setError(parseExpectedError(requestPasswordReset.errors))
        : safeRedirect(history, {
            pathname: AuthRoutes.PASSWORD_RESET_REQUESTED,
            search: stringify({ activationLink: requestPasswordReset.activationLink || undefined }),
          }),
    onError: (e: ApolloError) => setError(parseApolloError(e)),
  })

  const handleRequestPasswordReset = async ({ email }: { email: string }) =>
    requestPasswordResetMutation({
      variables: {
        email,
        queryString: location.search,
        region: getRegionFromHostname(window.location.hostname),
      },
    })

  return (
    <ForgotPasswordForm
      loginHref={AuthRoutes.LOGIN}
      loading={loading}
      error={error}
      onRequestPasswordReset={handleRequestPasswordReset}
    />
  )
}

export default ForgotPassword
