import { Location } from 'history'
import { parse } from 'query-string'

export default function getRedirectTo(location: Location): string {
  const query = parse(location.search) as { [key: string]: string }
  return isValidRedirect(query.redirectPath) ? query.redirectPath : '/'
}

// this checks whether the redirect path is a relative link.
function isValidRedirect(path?: string): boolean {
  if (!path) return false
  const decoded = decodeURIComponent(path)
  // relative paths start with a "/", but not more than 1 (see tests for example)
  return Boolean(decoded.match(/^\/[^/]/))
}
