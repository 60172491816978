import { motion } from '@eversports/react-components/design-tokens/motion'
import { getParsedApolloErrorFormFields } from '@eversports/react-components/logical/parse-apollo-error'
import ErrorPage from '@eversports/react-components/patterns/ErrorPage'
import Button from '@eversports/react-components/primitives/Button'
import ErrorBox from '@eversports/react-components/primitives/ErrorBox'
import { SimpleFlex } from '@eversports/react-components/primitives/Flex'
import Flex from '@eversports/react-components/primitives/Flex/Flex'
import Heading from '@eversports/react-components/primitives/Heading'
import Processing from '@eversports/react-components/primitives/Processing'
import Text from '@eversports/react-components/primitives/Text'
import TextField from '@eversports/react-components/primitives/TextField'
import { ClientError } from '@eversports/react-components/client-error'
import { makeStyles } from '@mui/styles'
import PyroForm, { Form } from 'pyro-form'
import React from 'react'

import EversportsPolicyWrapper from '../container/EversportsPolicyWrapper'

import { Localized, useIntl } from '../localization/react'
import { normalizePolicyUrls } from '../utils/normalize-policy-urls'

import { InvitationInvitationQuery } from '../graphql'
import { VenueConsentSection } from './ConsentForms'
import InvitationFormModalButton from './InvitationFormModalButton'

export interface InvitationFormValues {
  password?: string
}

interface InitialValues {
  password?: string
}

interface InvitationFormProps {
  handleAcceptInvitation: (values: InitialValues) => void
  handleRejectInvitation: () => void
  isFetching: boolean
  isSubmitting: boolean
  fetchError?: ClientError
  submissionError?: ClientError<InvitationFormValues>
  invitation?: InvitationInvitationQuery['invitation'] | null
}

const initialValues: InitialValues = { password: '' }

const useStyles = makeStyles({
  wrapper: {
    minHeight: 710,
    '& > *': {
      maxHeight: '100%',
    },
  },
  form: {
    width: '100%',
    maxWidth: 450,
  },
})

const InvitationForm: React.FC<React.PropsWithChildren<InvitationFormProps>> = ({
  invitation,
  handleAcceptInvitation,
  handleRejectInvitation,
  isFetching,
  isSubmitting,
  fetchError,
  submissionError,
}) => {
  const classes = useStyles()
  const intl = useIntl()

  if (isFetching) {
    return null
  }

  if (
    !invitation ||
    (fetchError && fetchError.type === 'message' && fetchError.message === 'invalid-invitation-token')
  ) {
    return <ErrorPage error={{ type: 'localized', localizedId: 'invalid-token' }} />
  }

  if (invitation.state === 'INVITATION_STATE_EXPIRED') {
    return <ErrorPage error={{ type: 'message', message: intl.expiredInvitationToken() }} />
  }

  if (invitation.state === 'INVITATION_STATE_ACCEPTED') {
    return <ErrorPage error={{ type: 'message', message: intl.acceptedInvitationToken() }} />
  }

  const { isEversportsUser, user, customers, invitedBy } = invitation

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: 'easeInOut' }}
    >
      <SimpleFlex className={classes.wrapper}>
        <PyroForm
          initialValues={initialValues}
          errors={getParsedApolloErrorFormFields(submissionError)}
          onSubmit={handleAcceptInvitation}
        >
          {({ handleSubmit, hasErrors }) => (
            <Form className={classes.form}>
              <Processing isLoading={isSubmitting}>
                <Heading is="h1" marginBottom="wide" textAlign="center">
                  {invitedBy.__typename === 'Venue' ? (
                    <Localized id="venue-invitation-heading" params={{ venueName: invitedBy.name }} />
                  ) : (
                    <Localized
                      id="venue-invitation-heading"
                      params={{ venueName: `${invitedBy.firstName} ${invitedBy.lastName}` }}
                    />
                  )}
                </Heading>
                <Flex flexDirection="column" verticalSpacing="default">
                  {user && (
                    <Text fontWeight="bold">
                      <Localized id="venue-invitation-greeting" /> {isEversportsUser ? user.firstName : user.email},
                    </Text>
                  )}
                  {user && !isEversportsUser && (
                    <>
                      <Text>
                        <Localized id="venue-invitation-set-password" />
                      </Text>
                      <TextField
                        name="password"
                        required
                        label={<Localized id="venue-invitation-password-label" />}
                        fullWidth
                        type="password"
                      />
                    </>
                  )}
                  {invitedBy.__typename === 'Venue' ? (
                    <>
                      <Text>
                        <Localized id="venue-invitation-eversports-info" params={{ venueName: invitedBy.name }} />
                      </Text>
                      <Text>
                        <Localized id="venue-invitation-venue-info" params={{ venueName: invitedBy.name }} />
                      </Text>
                      <Text>
                        <Localized id="venue-invitation-shared-info" params={{ venueName: invitedBy.name }} />
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text>
                        <Localized id="family-invitation-eversports-info" />
                      </Text>
                      <Text>
                        <Localized id="family-invitation-venue-info" />
                      </Text>
                      <Text>
                        <Localized id="family-invitation-shared-info" />
                      </Text>
                    </>
                  )}
                  {customers.map((customer) => (
                    <VenueConsentSection
                      key={customer.id}
                      venueName={customer.customerGroup.company.name}
                      policies={normalizePolicyUrls(customer.customerGroup.company.latestPolicies)}
                    />
                  ))}
                  <EversportsPolicyWrapper>
                    {({ data }) =>
                      data && data.latestEversportsPolicies ? (
                        <VenueConsentSection
                          venueName="Eversports"
                          policies={normalizePolicyUrls(data.latestEversportsPolicies)}
                        />
                      ) : null
                    }
                  </EversportsPolicyWrapper>
                  {/* @ts-ignore In this case password will not be defined every time */}
                  {submissionError && <ErrorBox error={submissionError} />}
                  <Flex horizontalSpacing="wide">
                    <InvitationFormModalButton
                      venueName={invitedBy.__typename === 'Venue' ? invitedBy.name : intl.invitationListedVenues()}
                      onInvitationRejection={handleRejectInvitation}
                      onInvitationAcceptance={handleSubmit}
                    />
                    <Button
                      data-testid="accept-invitation"
                      type="submit"
                      variant="primary"
                      disabled={hasErrors || isFetching}
                      fullWidth
                    >
                      <Localized id="venue-invitation-confirmation" />
                    </Button>
                  </Flex>
                </Flex>
              </Processing>
            </Form>
          )}
        </PyroForm>
      </SimpleFlex>
    </motion.div>
  )
}

export default InvitationForm
