import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import CookieBannerComponent from '../components/CookieBannerComponent'
import { getUserTrackingPreferences, setUserTrackingPreferences, TrackingType } from '../utils/tracking-preferences'

const CookiesBanner = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [userCookieTrackingPreferences, setUserCookieTrackingPreferences] = useState<{
    [key in TrackingType]: boolean
  }>({
    [TrackingType.ANALYTICS]: false,
    [TrackingType.ADVERTISING]: false,
  })

  const { search } = useLocation()
  const { origin } = parse(search)

  const showCookieBanner = origin && origin === 'widget'

  useEffect(() => {
    if (!showCookieBanner) return

    const trackingPreferences = getUserTrackingPreferences()
    if (!trackingPreferences) return

    setUserCookieTrackingPreferences({
      [TrackingType.ANALYTICS]: Boolean(trackingPreferences.ANALYTICS),
      [TrackingType.ADVERTISING]: Boolean(trackingPreferences.ADVERTISING),
    })
  }, [])

  useEffect(() => {
    if (!showCookieBanner) return

    const trackingPreferences = getUserTrackingPreferences()
    if (!trackingPreferences) return

    // only check for advertising - we will check for analytics on other pages of the website
    const userHasSetAdvertisingTrackingPreference = trackingPreferences.ADVERTISING !== null
    const isUserAgentGoogleBot = navigator.userAgent.includes('googlebot')

    if (!userHasSetAdvertisingTrackingPreference && !isUserAgentGoogleBot) {
      setIsOpen(true)
    }
  }, [])

  if (!showCookieBanner) {
    return null
  }

  const handleOnChange = (type: TrackingType) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserCookieTrackingPreferences({ ...userCookieTrackingPreferences, [type]: event.target.checked })
  }

  const handleClickAccept = () => {
    setUserTrackingPreferences({ [TrackingType.ANALYTICS]: true, [TrackingType.ADVERTISING]: true })
    setIsOpen(false)
  }

  const handleClickConfirm = () => {
    setUserTrackingPreferences({
      [TrackingType.ANALYTICS]: userCookieTrackingPreferences.ANALYTICS,
      [TrackingType.ADVERTISING]: userCookieTrackingPreferences.ADVERTISING,
    })
    setIsOpen(false)
  }

  return (
    <CookieBannerComponent
      isOpen={isOpen}
      isAnalyticsChecked={userCookieTrackingPreferences.ANALYTICS}
      isAdvertisingChecked={userCookieTrackingPreferences.ADVERTISING}
      handleOnChange={handleOnChange}
      handleClickAccept={handleClickAccept}
      handleClickConfirm={handleClickConfirm}
    />
  )
}

export default CookiesBanner
