import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@eversports/react-components/primitives/SvgIcon'

const RegisterIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 14 16" fill="none" {...props}>
    <path d="M3.875 4C3.875 4.67969 4.22656 5.28906 4.8125 5.64062C5.375 5.96875 6.10156 5.96875 6.6875 5.64062C7.25 5.28906 7.625 4.67969 7.625 4C7.625 3.34375 7.25 2.73438 6.6875 2.38281C6.10156 2.05469 5.375 2.05469 4.8125 2.38281C4.22656 2.73438 3.875 3.34375 3.875 4ZM8.75 4C8.75 5.07812 8.16406 6.0625 7.25 6.60156C6.3125 7.14062 5.16406 7.14062 4.25 6.60156C3.3125 6.0625 2.75 5.07812 2.75 4C2.75 2.94531 3.3125 1.96094 4.25 1.42188C5.16406 0.882812 6.3125 0.882812 7.25 1.42188C8.16406 1.96094 8.75 2.94531 8.75 4ZM6.80469 9.25H4.67188C3.125 9.25 1.85938 10.3984 1.64844 11.875H9.82812C9.61719 10.3984 8.35156 9.25 6.80469 9.25ZM4.67188 8.125H5.75H6.80469C9.125 8.125 11 10 11 12.3203C11 12.6953 10.6719 13 10.2969 13H1.17969C0.804688 13 0.5 12.6953 0.5 12.3203C0.5 10 2.35156 8.125 4.67188 8.125ZM12.3125 8.3125V6.8125H10.8125C10.4844 6.8125 10.25 6.57812 10.25 6.25C10.25 5.94531 10.4844 5.6875 10.8125 5.6875H12.3125V4.1875C12.3125 3.88281 12.5469 3.625 12.875 3.625C13.1797 3.625 13.4375 3.88281 13.4375 4.1875V5.6875H14.9375C15.2422 5.6875 15.5 5.94531 15.5 6.25C15.5 6.57812 15.2422 6.8125 14.9375 6.8125H13.4375V8.3125C13.4375 8.64062 13.1797 8.875 12.875 8.875C12.5469 8.875 12.3125 8.64062 12.3125 8.3125Z" />
  </SvgIcon>
)

export default RegisterIcon
