import { RadioProps } from '@mui/material/Radio'
import { PyroFormValues, usePyroField } from 'pyro-form'
import * as React from 'react'

import RadioButtonWrapper, { RadioButtonRef } from './RadioButtonWrapper'

export interface RadioButtonProps extends Omit<RadioProps, 'onBlur' | 'onChange'> {
  label?: string | React.ReactElement
}

interface Props<Values extends PyroFormValues> extends RadioButtonProps {
  name: Extract<keyof Values, string>
}

// eslint-disable-next-line no-shadow
export const RadioButton = React.forwardRef(function RadioButton<Values extends PyroFormValues = any>(
  { name, ...muiProps }: Props<Values>,
  ref?: React.Ref<RadioButtonRef>,
) {
  const fieldProps = usePyroField<Values, Extract<keyof Values, string>>(name)
  return <RadioButtonWrapper {...fieldProps} {...muiProps} name={name} ref={ref} />
})

export default RadioButton
