import Flex from '@eversports/react-components/primitives/Flex'
import { makeStyles } from '@mui/styles'
import React from 'react'

import Footer from '../components/Footer'
import { AuthenticationRouting } from './AuthenticationRouting'
import PageLogoWrapper from './PageLogoWrapper'

const useStyles = makeStyles(() => ({
  fullScreen: {
    minHeight: '100vh',
  },
}))

const Layout = () => {
  const classes = useStyles()
  return (
    <Flex alignItems="center" justifyContent="center" flexDirection="column" className={classes.fullScreen}>
      <Flex flexDirection="column" alignItems="center" flexGrow={1} margin="default">
        <PageLogoWrapper />
        <AuthenticationRouting />
      </Flex>
      <Footer />
    </Flex>
  )
}

export default Layout
