import { parseApolloError } from '@eversports/react-components/logical/parse-apollo-error'
import parseExpectedError from '@eversports/react-components/logical/parse-expected-error'
import { ClientError } from '@eversports/react-components/client-error'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { decorateLinkWithGAParameters } from '../utils/decorate-link-with-g-a-parameters'

import getRedirectTo from '../utils/get-redirect-to'
import { UseFamilyLoginFamilyLoginMutation, useUseFamilyLoginFamilyLoginMutation } from '../graphql'

interface Result {
  familyLogin: (userId: string) => Promise<void>
  isLoading: boolean
  error?: ClientError
}

const useFamilyLogin = (goTo?: string): Result => {
  const history = useHistory()
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [error, setError] = useState<ClientError>()

  const handleComplete = (data: UseFamilyLoginFamilyLoginMutation) => {
    if (data.familyLogin.__typename === 'ExpectedErrors') {
      setError(parseExpectedError(data.familyLogin.errors))
      return
    }

    setIsRedirecting(true)

    // If we want the user to be sent to a specific place after the login
    // inside of the same app, instead of the redirecting him
    if (goTo) {
      history.push(goTo)
      return
    }

    // Redirect the user after successfully masquerading it
    window.location.href = decorateLinkWithGAParameters(getRedirectTo(history.location))
  }

  const [familyLoginMutation, { loading }] = useUseFamilyLoginFamilyLoginMutation({
    onError: (e) => {
      setError(parseApolloError(e))
    },
    onCompleted: handleComplete,
  })

  const familyLogin = async (userId: string) => {
    await familyLoginMutation({ variables: { userId } })
  }

  return { familyLogin, isLoading: loading || isRedirecting, error }
}

export default useFamilyLogin
