import { makeStyles } from '@mui/styles'
import * as React from 'react'

import Flex from '../Flex'

interface Props {
  width?: number | string
  height?: number | string
  center?: boolean
  children?: React.ReactNode
}

type PlaceholderRef = HTMLElementTagNameMap['div']

const useStyles = makeStyles({
  root: ({ width, height }: Props) => ({
    width,
    height,
  }),
})

// eslint-disable-next-line no-shadow
const Placeholder = React.forwardRef<PlaceholderRef, Props>(function Placeholder({ center, children, ...props }, ref) {
  const classes = useStyles(props)
  return center ? (
    <Flex
      alignItems="center"
      justifyContent="center"
      marginLeft="auto"
      marginRight="auto"
      ref={ref}
      className={classes.root}
    >
      {children}
    </Flex>
  ) : (
    <div className={classes.root} ref={ref}>
      {children}
    </div>
  )
})

Placeholder.defaultProps = {
  width: 'auto',
  height: 'auto',
  center: false,
}

export default Placeholder
