import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@eversports/react-components/primitives/SvgIcon'

const iOSIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 10 12" fill="none" {...props}>
    <path
      d="M8.15432 6.38156C8.16625 5.45521 8.66374 4.57869 9.45294 4.09349C8.95507 3.38244 8.12115 2.93161 7.25354 2.90447C6.32817 2.80734 5.43106 3.4582 4.9595 3.4582C4.47883 3.4582 3.75279 2.91411 2.97098 2.9302C1.95191 2.96312 1.00189 3.5425 0.506096 4.43344C-0.559668 6.27864 0.235296 8.99047 1.25621 10.482C1.76699 11.2124 2.36395 12.0282 3.14507 11.9993C3.90943 11.9675 4.1949 11.5118 5.11756 11.5118C6.03165 11.5118 6.29948 11.9993 7.09644 11.9809C7.91664 11.9675 8.4334 11.2473 8.92627 10.51C9.29326 9.98958 9.57567 9.41441 9.76302 8.8058C8.79913 8.39812 8.15544 7.42811 8.15432 6.38156Z"
      fill="#666666"
    />
    <path
      d="M6.64899 1.92357C7.09619 1.38671 7.31651 0.696677 7.26316 0C6.57993 0.0717603 5.94882 0.3983 5.49557 0.914557C5.05236 1.41897 4.8217 2.09686 4.86531 2.76691C5.54879 2.77395 6.2207 2.45626 6.64899 1.92357Z"
      fill="#666666"
    />
  </SvgIcon>
)

export default iOSIcon
