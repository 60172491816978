import * as React from 'react'
import SvgIcon, { SvgIconProps } from '@eversports/react-components/primitives/SvgIcon'

const LoginIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 14 12" fill="none" {...props}>
    <path d="M4.5 8.57812L7.07812 6L4.5 3.44531V4.6875C4.5 5.01562 4.24219 5.25 3.9375 5.25H1.125V6.75H3.9375C4.24219 6.75 4.5 7.00781 4.5 7.3125V8.57812ZM8.25 6C8.25 6.28125 8.13281 6.53906 7.94531 6.72656L5.22656 9.44531C5.01562 9.65625 4.73438 9.75 4.45312 9.75C3.84375 9.75 3.375 9.28125 3.375 8.67188V7.875H1.125C0.492188 7.875 0 7.38281 0 6.75V5.25C0 4.64062 0.492188 4.125 1.125 4.125H3.375V3.35156C3.375 2.74219 3.84375 2.25 4.45312 2.25C4.73438 2.25 5.01562 2.36719 5.22656 2.57812L7.94531 5.29688C8.13281 5.48438 8.25 5.74219 8.25 6ZM8.0625 10.125H9.9375C10.4531 10.125 10.875 9.72656 10.875 9.1875V2.8125C10.875 2.29688 10.4531 1.875 9.9375 1.875H8.0625C7.73438 1.875 7.5 1.64062 7.5 1.3125C7.5 1.00781 7.73438 0.75 8.0625 0.75H9.9375C11.0625 0.75 12 1.6875 12 2.8125V9.1875C12 10.3359 11.0625 11.25 9.9375 11.25H8.0625C7.73438 11.25 7.5 11.0156 7.5 10.6875C7.5 10.3828 7.73438 10.125 8.0625 10.125Z" />
  </SvgIcon>
)

export default LoginIcon
