import Button from '@eversports/react-components/primitives/Button'
import Flex from '@eversports/react-components/primitives/Flex'
import Icon, { Icons } from '@eversports/react-components/primitives/Icon'
import FacebookIcon from '@eversports/react-components/primitives/Icon/custom-icons/Facebook'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'

import useRenderLock from '@eversports/react-components/logical/useRenderLock'
import { SimpleText } from '@eversports/react-components/primitives/Text'
import { Localized } from '../../localization/react'

import useFacebookSDK from './useFacebookSDK'

interface Props {
  // eslint-disable-next-line no-undef
  onLogin?: (response: FacebookSDKResponse) => void
  scope?: string
}

const useStyles = makeStyles({
  button: {
    backgroundColor: '#3c66c4',
    '&:hover': {
      backgroundColor: '#3c66c4',
    },
  },
  placeholderIcon: {
    visibility: 'hidden',
  },
})

const POPUP_DISCLAIMER_DELAY = 2500

const FacebookLoginButton = ({ scope, onLogin }: Props) => {
  const classes = useStyles()
  const locked = useRenderLock()
  const [retryLogin, setRetryLogin] = useState(false)
  const [isLoadingSDK, setIsLoadingSDK] = useState(false)
  const [showPopupBlockerDisclaimer, setShowPopupBlockerDisclaimer] = useState(false)
  const { sdk, loadFacebookSDK } = useFacebookSDK()

  useEffect(() => {
    if (!sdk || !onLogin) return
    let timer: null | ReturnType<typeof setTimeout> = null

    setIsLoadingSDK(false)
    setRetryLogin(false)

    if (!showPopupBlockerDisclaimer) {
      timer = setTimeout(() => setShowPopupBlockerDisclaimer(true), POPUP_DISCLAIMER_DELAY)
    }

    const handleLogin = async () => {
      sdk.getLoginStatus((response) => {
        if (response.status === 'connected') {
          onLogin(response)
        } else {
          sdk.login(
            (response) => {
              onLogin(response)
            },
            { scope: scope! },
          )
        }
      })
    }

    handleLogin().catch(() => {
      setIsLoadingSDK(false)
    })

    return () => {
      if (!timer) return
      clearTimeout(timer)
    }
  }, [sdk, retryLogin])

  const handleClick = (): void => {
    if (isLoadingSDK) return

    if (!sdk) {
      setIsLoadingSDK(true)
      loadFacebookSDK()
    } else if (sdk) {
      setRetryLogin(true)
    }
  }

  return (
    <>
      <Button variant="primary" customColor="white" onClick={handleClick} className={classes.button} disabled={locked}>
        <Flex justifyContent="center" fullWidth>
          <Icon icon={FacebookIcon} color="white" size="large" marginRight="dense" />
          <Flex flexGrow={1} justifyContent="center">
            <SimpleText sx={{ textTransform: 'none' }}>
              <Localized id="authentication-login-with-facebook-action" />
            </SimpleText>
          </Flex>
          <Icon className={classes.placeholderIcon} icon={FacebookIcon} size="large" />
        </Flex>
      </Button>
      {showPopupBlockerDisclaimer && (
        <Flex horizontalSpacing="dense" alignItems="center">
          <Icon icon={Icons.INFO} color="info.main" size="large" />
          <SimpleText size="small">
            <Localized id="authentication-facebook-pop-up-blocker-disclaimer" />
          </SimpleText>
        </Flex>
      )}
    </>
  )
}

FacebookLoginButton.defaultProps = {
  scope: ['public_profile', 'email'].join(','),
}

export default FacebookLoginButton
