import { default as MuiDialog, DialogProps } from '@mui/material/Dialog'
import * as React from 'react'

// The default element type of MUI's Dialog is 'div' and we don't allow the change of this prop
type DialogRef = HTMLElementTagNameMap['div']

// eslint-disable-next-line no-shadow
const Dialog = React.forwardRef<DialogRef, DialogProps>(function Dialog(props, ref) {
  return <MuiDialog {...props} ref={ref} />
})

export default Dialog
