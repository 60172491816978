import { useSettingsContext } from '@eversports/react-app-base/SettingsContext'
import { AnimatePresence } from '@eversports/react-components/design-tokens/motion'
import NotFound from '@eversports/react-components/patterns/NotFound'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Route, Switch, useLocation } from 'react-router-dom'

import { AuthRoutes } from '../auth-routes'
import AccountActivationFailure from '../components/AccountActivationFailure'
import AccountActivationPending from '../components/AccountActivationPending'
import AccountActivationSuccess from '../components/AccountActivationSuccess'
import PasswordResetRequested from '../components/PasswordResetRequested'
import PasswordResetSuccess from '../components/PasswordResetSuccess'
import RegistrationSuccess from '../components/RegistrationSuccess'
import { useIntl } from '../localization/react'

import AccountActivation from './AccountActivation'
import EversportsConsent from './ConsentScreen'
import CorporatePartnerPage from './CorporatePartnerPage'
import FamilyAccountCreation from './FamilyAccountCreation'
import FamilyAccountDisclaimer from './FamilyAccountDisclaimer'
import FamilyAccountLogin from './FamilyAccountLogin'
import FamilyAccountMemberCreation from './FamilyAccountMemberCreation'
import ForgotPassword from './ForgotPassword'
import Invitation from './Invitation'
import InvitationSuccess from './InvitationSuccess'
import Login from './Login'
import PasswordReset from './PasswordReset'
import Registration from './Registration'
import Self from './Self'

const NoIndexMetaTag: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    {children}
  </>
)

export const AuthenticationRouting: React.FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl()
  const { origin, tld } = useSettingsContext()

  const location = useLocation()
  const hasSearchParams = location.search

  const loginCanonicalHref = new URL('auth/', origin).href
  const registerCanonicalHref = new URL(`auth${AuthRoutes.REGISTER}`, origin).href

  const countryName = intl!.tldCountryName({ tld })

  return (
    <AnimatePresence exitBeforeEnter>
      <Switch>
        <Route path={AuthRoutes.LOGIN} exact>
          <>
            <Helmet>
              <title>{`${intl!.loginMetaTitle({ countryName })}`}</title>
              <meta name="description" content={intl.loginMetaDescription({ countryName })} />
              <link rel="canonical" href={loginCanonicalHref} />
              {hasSearchParams && <meta name="robots" content="noindex" />}
            </Helmet>
            <Login />
          </>
        </Route>
        <Route path={AuthRoutes.REGISTER}>
          <>
            <Helmet>
              <title>{`${intl!.registrationMetaTitle({ countryName })}`}</title>
              <meta name="description" content={intl.registrationMetaDescription({ countryName })} />
              <link rel="canonical" href={registerCanonicalHref} />
              {hasSearchParams && <meta name="robots" content="noindex" />}
            </Helmet>
            <Registration />
          </>
        </Route>
        <Route path={AuthRoutes.CONSENT}>
          <NoIndexMetaTag>
            <EversportsConsent />
          </NoIndexMetaTag>
        </Route>
        <Route path={AuthRoutes.REGISTRATION_SUCCESS}>
          <NoIndexMetaTag>
            <RegistrationSuccess />
          </NoIndexMetaTag>
        </Route>
        <Route path={AuthRoutes.ACCOUNT_ACTIVATION_PENDING}>
          <NoIndexMetaTag>
            <AccountActivationPending />
          </NoIndexMetaTag>
        </Route>
        <Route path={AuthRoutes.ACCOUNT_ACTIVATION}>
          <NoIndexMetaTag>
            <AccountActivation />
          </NoIndexMetaTag>
        </Route>
        <Route path={AuthRoutes.ACCOUNT_ACTIVATION_SUCCESS}>
          <NoIndexMetaTag>
            <AccountActivationSuccess />
          </NoIndexMetaTag>
        </Route>
        <Route path={AuthRoutes.ACCOUNT_ACTIVATION_FAILURE}>
          <NoIndexMetaTag>
            <AccountActivationFailure />
          </NoIndexMetaTag>
        </Route>
        <Route path={AuthRoutes.FORGOT_PASSWORD}>
          <NoIndexMetaTag>
            <ForgotPassword />
          </NoIndexMetaTag>
        </Route>
        <Route path={AuthRoutes.PASSWORD_RESET}>
          <NoIndexMetaTag>
            <PasswordReset />
          </NoIndexMetaTag>
        </Route>
        <Route path={AuthRoutes.PASSWORD_RESET_REQUESTED}>
          <NoIndexMetaTag>
            <PasswordResetRequested />
          </NoIndexMetaTag>
        </Route>
        <Route path={AuthRoutes.PASSWORD_RESET_SUCCESS}>
          <NoIndexMetaTag>
            <PasswordResetSuccess />
          </NoIndexMetaTag>
        </Route>
        <Route path={AuthRoutes.INVITATION}>
          <NoIndexMetaTag>
            <Invitation />
          </NoIndexMetaTag>
        </Route>
        <Route path={AuthRoutes.INVITATION_SUCCESS}>
          <NoIndexMetaTag>
            <InvitationSuccess />
          </NoIndexMetaTag>
        </Route>
        <Route path={AuthRoutes.SELF}>
          <NoIndexMetaTag>
            <Self />
          </NoIndexMetaTag>
        </Route>
        <Route path={AuthRoutes.CORPORATE_PARTNER}>
          <NoIndexMetaTag>
            <CorporatePartnerPage />
          </NoIndexMetaTag>
        </Route>
        <Route path={AuthRoutes.FAMILY_ACCOUNT_DISCLAIMER}>
          <NoIndexMetaTag>
            <FamilyAccountDisclaimer />
          </NoIndexMetaTag>
        </Route>
        <Route path={AuthRoutes.FAMILY_ACCOUNT_CREATION}>
          <NoIndexMetaTag>
            <FamilyAccountCreation />
          </NoIndexMetaTag>
        </Route>
        <Route path={AuthRoutes.FAMILY_ACCOUNT_LOGIN}>
          <NoIndexMetaTag>
            <FamilyAccountLogin />
          </NoIndexMetaTag>
        </Route>
        <Route path={AuthRoutes.FAMILY_ACCOUNT_MEMBER_CREATION}>
          <NoIndexMetaTag>
            <FamilyAccountMemberCreation />
          </NoIndexMetaTag>
        </Route>
        <Route>
          <NoIndexMetaTag>
            <NotFound />
          </NoIndexMetaTag>
        </Route>
      </Switch>
    </AnimatePresence>
  )
}
