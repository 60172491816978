import { default as MuiCheckbox } from '@mui/material/Checkbox'
import { PyroFieldInnerRenderProps } from 'pyro-form'
import * as React from 'react'
import { Stack } from '@mui/material'

import Text from '../Text'

import { CheckboxPrimitiveProps } from './Checkbox'

type ValueType = string | number | boolean | undefined

export type CheckboxRef = HTMLLabelElement | null

const CheckboxWrapper = React.forwardRef<CheckboxRef, PyroFieldInnerRenderProps & CheckboxPrimitiveProps>(
  function CheckboxWrapper(
    { name, label, value, core: { onChange, onBlur, value: selectedValues }, meta: { touched }, ...props },
    ref,
  ) {
    const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
      const { value: eventValue, checked } = e.currentTarget
      // Eventually remove event value from selected values
      const fieldValue = (selectedValues as Array<ValueType>).filter((val: ValueType) => val && val !== eventValue)
      // If checkbox has been checked add it again
      if (checked) fieldValue.push(eventValue)
      // Propagate changes
      onChange(fieldValue)
      // it's touched (true) by the first click
      if (!touched) {
        onBlur()
      }
    }

    const renderCheckbox = (innerRef?: React.Ref<HTMLElementTagNameMap['button']>) => (
      <MuiCheckbox
        {...props}
        name={name}
        value={value}
        checked={(selectedValues as Array<ValueType>).includes(value as ValueType)}
        onChange={handleChange}
        color="primary"
        disableRipple
        ref={innerRef}
      />
    )

    return label ? (
      <Stack direction="row" alignItems="center" component="label" sx={{ cursor: 'pointer' }} ref={ref}>
        {renderCheckbox()}
        <Text is="span">{label}</Text>
      </Stack>
    ) : (
      renderCheckbox(ref as React.Ref<HTMLElementTagNameMap['button']>)
    )
  },
)

export default CheckboxWrapper
