import { ApolloError } from '@apollo/client'
import { parseApolloError } from '@eversports/react-components/logical/parse-apollo-error'
import parseExpectedError from '@eversports/react-components/logical/parse-expected-error'
import useAmplitude from '@eversports/amplitude-react/useAmplitude'
import { ClientError } from '@eversports/react-components/client-error'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  LoginCredentialLoginMutation,
  LoginCredentialLoginMutationVariables,
  RegistrationSource,
  useLoginCredentialLoginMutation,
  useLoginRequestVerificationEmailMutationMutation,
  UseSocialLoginExternalLoginMutation,
} from '../graphql'

import { AuthRoutes } from '../auth-routes'
import LoginForm from '../components/LoginForm'

import getAmplitudeLoginData from '../utils/get-amplitude-login-data'
import { getAuthParams } from '../utils/get-auth-params'
import handleAuthResult from '../utils/handle-auth-result'
import useSocialLogin from '../utils/useSocialLogin'
import LoginRegisterToggle from './LoginRegisterToggle'
import CookiesBanner from './CookieBanner'

const Login: React.FC<React.PropsWithChildren<unknown>> = () => {
  const location = useLocation()
  const history = useHistory()
  const { amplitude } = useAmplitude()
  const [error, setError] = useState<ClientError | undefined>(undefined)
  const {
    handleFacebookLogin,
    handleGoogleLogin,
    loading: socialLoading,
    data: externalLoginData,
    error: socialError,
  } = useSocialLogin()

  const handleLoginError = (e: ApolloError) => {
    setError(parseApolloError(e))
  }

  const [requestVerificationEmailMutation] = useLoginRequestVerificationEmailMutationMutation({
    onError: handleLoginError,
  })

  const [credentialsMutation, { loading: credentialsLoading, error: credentialsError, data: credentialLoginData }] =
    useLoginCredentialLoginMutation({
      onError: handleLoginError,
    })

  useEffect(() => {
    if (!credentialLoginData) return

    // eslint-disable-next-line  @typescript-eslint/no-floating-promises
    handleLoginComplete(credentialLoginData.credentialLogin)
  }, [credentialLoginData])

  useEffect(() => {
    if (!externalLoginData) return

    // eslint-disable-next-line  @typescript-eslint/no-floating-promises
    handleLoginComplete(externalLoginData.externalLogin)
  }, [externalLoginData])

  const handleLoginComplete = async (
    result: UseSocialLoginExternalLoginMutation['externalLogin'] | LoginCredentialLoginMutation['credentialLogin'],
  ) => {
    if (result.__typename === 'ExpectedErrors') {
      return setError(parseExpectedError(result.errors))
    }

    if (
      result.registrationSource &&
      result.registrationSource === RegistrationSource.REGISTRATION_SOURCE_EXISTING_USER &&
      !result.user.hasVerifiedEmail
    ) {
      await requestVerificationEmailMutation()
    }

    const from = getAmplitudeLoginData(history.location.search)
    const type = credentialLoginData ? 'credentials' : 'social'

    if (amplitude) {
      amplitude.logEvent('Logged In', {
        type,
        from,
      })
    }

    handleAuthResult({ result, history })
  }

  const handleCredentialLogin = async (credentials: LoginCredentialLoginMutationVariables['credentials']) => {
    const params = getAuthParams(location.search)
    await credentialsMutation({ variables: { credentials, params } })
  }

  return (
    <>
      <CookiesBanner />
      <LoginRegisterToggle />
      <LoginForm
        forgotPasswordHref={AuthRoutes.FORGOT_PASSWORD}
        onCredentialsLogin={handleCredentialLogin}
        onFacebookLogin={handleFacebookLogin}
        onGoogleLogin={handleGoogleLogin}
        loading={credentialsLoading || socialLoading}
        error={parseApolloError(credentialsError) || socialError || error}
      />
    </>
  )
}

export default Login
