import { QueryResult } from '@apollo/client'
import React, { ReactElement } from 'react'
import {
  CurrentPolicyWrapperEversportsCurrentPoliciesQuery,
  useCurrentPolicyWrapperEversportsCurrentPoliciesQuery,
} from '../graphql'

interface Props {
  children: (
    result: Pick<QueryResult<CurrentPolicyWrapperEversportsCurrentPoliciesQuery>, 'data' | 'loading' | 'error'>,
  ) => ReactElement<any, any> | null
}

const EversportsPolicyWrapper: React.FC<Props> = ({ children }) => {
  const result = useCurrentPolicyWrapperEversportsCurrentPoliciesQuery()
  return children(result)
}

export default EversportsPolicyWrapper
