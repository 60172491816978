import { OperationVariables, QueryResult } from '@apollo/client'

export interface SimpleQueryResult<TData = any, TVariables extends OperationVariables = OperationVariables>
  extends Pick<QueryResult<TData, TVariables>, 'error' | 'loading'> {
  data?: TData[keyof TData]
  /**
   * Defines whether a query is really run or skipped
   */
  active: boolean
}

interface Options<TData = any, TVariables extends OperationVariables = OperationVariables> {
  queryResult: QueryResult<TData, TVariables>
  skip: boolean
}

function createSimpleQueryResult<
  TData extends Record<string, unknown> = Record<string, unknown>,
  TVariables extends OperationVariables = OperationVariables,
>({ queryResult: { data, error, loading }, skip }: Options<TData, TVariables>) {
  return {
    error,
    loading,
    data: typeof data === 'undefined' ? undefined : data[Object.keys(data)[0] as keyof TData],
    active: !skip,
  }
}

export default createSimpleQueryResult
