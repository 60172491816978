import { makeStyles } from '@mui/styles'
import PyroForm, { Form } from 'pyro-form'
import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { ClientError } from '../../client-error'
import { Localized } from '../../localization/react'
import Button from '../../primitives/Button'
import ErrorBox from '../../primitives/ErrorBox'
import Flex from '../../primitives/Flex'
import Heading from '../../primitives/Heading'
import Text from '../../primitives/Text'
import TextField from '../../primitives/TextField'

interface FamilyAccountMemberCreationInitialValues {
  firstname: string
  lastname: string
}

interface Props {
  onSubmit: (values: FamilyAccountMemberCreationInitialValues) => void
  error?: ClientError
}

const useStyles = makeStyles({
  container: {
    maxWidth: 520,
  },
})

const FamilyAccountMemberCreation: React.FC<React.PropsWithChildren<Props>> = ({ onSubmit, error }) => {
  const history = useHistory()
  const classes = useStyles()
  const initialValues: FamilyAccountMemberCreationInitialValues = { firstname: '', lastname: '' }

  const handleSubmit = (values: FamilyAccountMemberCreationInitialValues) => {
    onSubmit(values)
  }

  return (
    <Flex flexDirection="column" verticalSpacing="default" className={classes.container}>
      <Heading is="h1">
        <Localized id="family-account-new-member-heading" />
      </Heading>
      <Text>
        <Localized id="family-account-new-member-description" />
      </Text>
      <PyroForm initialValues={initialValues} onSubmit={handleSubmit}>
        {({ hasErrors }) => (
          <Form>
            <Flex flexDirection="column" verticalSpacing="default">
              <TextField
                name="firstname"
                label={<Localized id="family-account-new-member-first-name-label" />}
                fullWidth
                required
              />
              <TextField
                name="lastname"
                label={<Localized id="family-account-new-member-last-name-label" />}
                fullWidth
                required
              />
              {error && <ErrorBox error={error} />}
              <Button variant="primary" type="submit" disabled={hasErrors} fullWidth>
                <Localized id="family-account-new-member-continue" />
              </Button>
            </Flex>
          </Form>
        )}
      </PyroForm>
      <Text color="secondary.main" textAlign="left" showPointer onClick={() => history.goBack()}>
        <Localized id="family-account-new-member-go-back" />
      </Text>
    </Flex>
  )
}

export default FamilyAccountMemberCreation
