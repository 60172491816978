import { CheckboxProps } from '@mui/material/Checkbox'
import { PyroFormValues, usePyroField } from 'pyro-form'
import * as React from 'react'

import CheckboxWrapper, { CheckboxRef } from './CheckboxWrapper'

export interface CheckboxPrimitiveProps extends Omit<CheckboxProps, 'onBlur' | 'onChange'> {
  label?: string | React.ReactElement
}

interface Props<Values extends PyroFormValues> extends CheckboxPrimitiveProps {
  name: Extract<keyof Values, string>
}

// eslint-disable-next-line no-shadow
export const Checkbox = React.forwardRef(function Checkbox<Values extends PyroFormValues>(
  { name, ...muiProps }: Props<Values>,
  ref?: React.Ref<CheckboxRef>,
) {
  const fieldProps = usePyroField<Values, Extract<keyof Values, string>>(name)
  return <CheckboxWrapper {...fieldProps} {...muiProps} name={name} ref={ref} />
})

export default Checkbox
