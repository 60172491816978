import { parse } from 'query-string'
import { motion } from '@eversports/react-components/design-tokens/motion'
import ActionOutcome from '@eversports/react-components/patterns/ActionOutcome'
import Heading from '@eversports/react-components/primitives/Heading'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'

import { useLocation } from 'react-router-dom'
import { Localized } from '../localization/react'

const AccountActivationPending = () => {
  const location = useLocation()
  const query = parse(location.search) as { activationLink?: string }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: 'easeInOut' }}
    >
      <ActionOutcome type="success" data-testid="account-activation-pending">
        <Heading is="h2">
          <Localized id="registration-success-header" />
        </Heading>
        <Text textAlign="center">
          <Localized id="registration-success-text" />
        </Text>
        {query && query.activationLink && (
          <>
            <Text textAlign="center" fontWeight="bold">
              DEV ONLY LOG OF activationLink
            </Text>
            <a data-testid="activation-link" href={query.activationLink}>
              <Text textAlign="center">{query.activationLink}</Text>
            </a>
          </>
        )}
      </ActionOutcome>
    </motion.div>
  )
}

export default AccountActivationPending
