import { makeStyles } from '@mui/styles'
import * as React from 'react'

import { Theme } from '../../design-tokens/theme'
import { Localized } from '../../localization/react'
import Avatar from '../../primitives/Avatar'
import Flex from '../../primitives/Flex'
import RadioButton from '../../primitives/RadioButton'
import Text from '../../primitives/Text'

import { User } from './types'

const useStyles = makeStyles((theme: Theme) => ({
  familyAccountUser: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  textAlignCenter: {
    textAlign: 'center',
  },
}))

interface Props {
  member: User
  isOwner?: boolean
}

const FamilyAccountMember: React.FC<React.PropsWithChildren<Props>> = ({ member, isOwner = false }) => {
  const classes = useStyles()
  const name = `${member.firstName} ${member.lastName}`

  return (
    <label key={member.email} className={classes.familyAccountUser}>
      <Flex horizontalSpacing="dense" padding="dense">
        <RadioButton name="email" value={member.email} />
        {member.profilePicture ? <Avatar src={member.profilePicture.small} /> : <Avatar>{name}</Avatar>}
        <div>
          <Text fontWeight="bold">{name}</Text>
          <Text>
            {isOwner ? (
              <Localized id="family-account-account-owner" />
            ) : (
              <Localized id="family-account-family-member" />
            )}
          </Text>
        </div>
      </Flex>
    </label>
  )
}

export default FamilyAccountMember
