import { parseApolloError } from '@eversports/react-components/logical/parse-apollo-error'
import parseExpectedError from '@eversports/react-components/logical/parse-expected-error'
import { FamilyAccountMemberCreation } from '@eversports/react-components/patterns/FamilyAccount'
import Processing from '@eversports/react-components/primitives/Processing'
import { ClientError } from '@eversports/react-components/client-error'
import React, { useEffect, useState } from 'react'

import { AuthRoutes } from '../auth-routes'
import useFamilyLogin from '../data/use-family-login'
import { useFamilyAccountCreationCreateFamilyMutation } from '../graphql'

interface Member {
  firstname: string
  lastname: string
}

const FamilyAccountCreation: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [error, setError] = useState<ClientError>()

  const { familyLogin, isLoading } = useFamilyLogin(AuthRoutes.FAMILY_ACCOUNT_LOGIN)
  const [createFamily, { loading: createFamilyLoading, data: createFamilyData }] =
    useFamilyAccountCreationCreateFamilyMutation({ onError: (e) => setError(parseApolloError(e)) })

  const handleSubmit = async (member: Member) => {
    await createFamily({ variables: { members: [member] } })
  }

  useEffect(() => {
    if (!createFamilyData) return

    if (createFamilyData.createFamily.__typename === 'ExpectedErrors') {
      setError(parseExpectedError(createFamilyData.createFamily.errors))
      return
    }

    const { owner, members } = createFamilyData.createFamily
    const newMember = members.find((member) => member.id !== owner.id)

    if (!newMember) {
      throw new Error('New member should be part of a family after its creation')
    }

    // eslint-disable-next-line  @typescript-eslint/no-floating-promises
    familyLogin(newMember.id)
  }, [createFamilyData])

  return (
    <Processing isLoading={createFamilyLoading || isLoading}>
      <FamilyAccountMemberCreation onSubmit={handleSubmit} error={error} />
    </Processing>
  )
}

export default FamilyAccountCreation
