import { makeStyles } from '@mui/styles'
import PyroForm from 'pyro-form'
import * as React from 'react'
import { useState } from 'react'

import { ClientError } from '../../client-error'
import { Localized } from '../../localization/react'
import Button from '../../primitives/Button'
import Divider from '../../primitives/Divider'
import ErrorBox from '../../primitives/ErrorBox'
import Flex from '../../primitives/Flex'
import Heading from '../../primitives/Heading'
import Link from '../../primitives/Link'
import Text from '../../primitives/Text'

import FamilyAccountMember from './FamilyAccountMember'
import { Family, User } from './types'

const useStyles = makeStyles({
  container: {
    maxWidth: 520,
  },
})

interface Props {
  loggedInUser: User
  family: Family
  memberCreationUrl: string
  onContinueClick: (userId: string) => void
  error?: ClientError
}

interface SelectedUserEmail {
  email: string
}

const FamilyAccountSelection: React.FC<React.PropsWithChildren<Props>> = ({
  loggedInUser,
  family,
  memberCreationUrl,
  onContinueClick,
  error,
}) => {
  const classes = useStyles()
  const [selectedUser, setSelectedUser] = useState<User>(loggedInUser)
  const alphabeticallyOrderedMembers = family.members.sort((a, b) => a.firstName.localeCompare(b.firstName))

  const handleChange = ({ email }: SelectedUserEmail) => {
    const newlySelectedUser = family.members.concat(family.owner).find((member) => member.email === email)

    if (!newlySelectedUser) {
      throw new Error('The selected user is not valid')
    }

    setSelectedUser(newlySelectedUser)
  }

  const handleClick = () => {
    onContinueClick(selectedUser.id)
  }

  return (
    <Flex
      flexDirection="column"
      verticalSpacing="default"
      className={classes.container}
      data-testid="family-account-login"
    >
      <Heading is="h1">
        <Localized id="family-account-selection-heading" />
      </Heading>
      <PyroForm initialValues={{ email: loggedInUser.email }} onChange={handleChange}>
        <Flex flexDirection="column" justifyContent="center" verticalSpacing="default">
          <FamilyAccountMember member={family.owner} isOwner />
          {alphabeticallyOrderedMembers.map((member) => (
            <FamilyAccountMember key={member.email} member={member} />
          ))}
        </Flex>
        {error && <ErrorBox error={error} />}
        <Button variant="primary" onClick={handleClick}>
          <Text color="white" fontWeight="bold">
            {selectedUser ? (
              <Localized
                id="family-account-continue-with-selected-user"
                params={{ selectedUserName: `${selectedUser.firstName} ${selectedUser.lastName}` }}
              />
            ) : (
              <Localized id="family-account-select-user" />
            )}
          </Text>
        </Button>
      </PyroForm>
      <Divider>
        <Localized id="family-account-divider-text" />
      </Divider>
      <Link to={memberCreationUrl}>
        <Text color="secondary.main" textAlign="center">
          <Localized id="family-account-create-new-family-member" />
        </Text>
      </Link>
    </Flex>
  )
}

export default FamilyAccountSelection
