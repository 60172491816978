import { ClientError } from '@eversports/react-components/client-error'

interface ClientIOError {
  __typename: 'ExpectedError'
  id: string
  message: string
  path: string | null
}

// TODO: Add better logging of multiple errors
function parseExpectedError(errors: Array<ClientIOError>): ClientError | undefined {
  if (errors.length === 0) return undefined

  return {
    type: 'message',
    message: errors[0].message,
  }
}

export default parseExpectedError
