import Flex from '@eversports/react-components/primitives/Flex/'
import Heading from '@eversports/react-components/primitives/Heading/'
import Text from '@eversports/react-components/primitives/Text'
import React from 'react'
import { useSelfMeQuery } from '../graphql'

const Self = () => {
  const { data, error, loading } = useSelfMeQuery()
  return (
    <Flex verticalSpacing="default" flexDirection="column">
      <Heading is="h2" textAlign="center">
        Personal Data {loading && '(Loading)'} {error && '(Error)'}
      </Heading>
      {!loading && data && data.me && (
        <>
          <div>
            <Text>Name: {`${data.me.firstName} ${data.me.lastName}`}</Text>
            <Text>Email: {data.me.email}</Text>
          </div>
        </>
      )}
      <Text textAlign="center">{error && JSON.stringify(error)}</Text>
    </Flex>
  )
}

export default Self
