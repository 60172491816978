import { motion } from '@eversports/react-components/design-tokens/motion'
import ActionOutcome from '@eversports/react-components/patterns/ActionOutcome'
import BaseLink from '@eversports/react-components/primitives/BaseLink'
import Button from '@eversports/react-components/primitives/Button'
import Heading from '@eversports/react-components/primitives/Heading'
import Text from '@eversports/react-components/primitives/Text'
import { makeStyles } from '@mui/styles'
import React from 'react'

import { useLocation } from 'react-router-dom'
import RedirectAppTextWrapper from '../container/RedirectAppTextWrapper'
import { Localized } from '../localization/react'
import getRedirectTo from '../utils/get-redirect-to'
import { useUseCorporateUserMeQuery } from '../graphql'
import getCustomCorporateRedirectPath from '../utils/get-custom-corporate-redirect-path'

const useStyles = makeStyles({
  redirectButton: {
    maxWidth: 300,
  },
})

const container = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeInOut',
      delay: 0.1,
      duration: 0.2,
    },
  },
}

const button = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeInOut',
      delay: 0.2,
      duration: 0.2,
    },
  },
}

const AccountActivationSuccess = () => {
  const location = useLocation()
  const { data } = useUseCorporateUserMeQuery()

  const customCorporateRedirectPath = data?.me && getCustomCorporateRedirectPath(data.me)

  const redirectTo = customCorporateRedirectPath || getRedirectTo(location)
  const classes = useStyles()

  return (
    <motion.div variants={container} initial="hidden" animate="visible">
      <ActionOutcome type="success">
        <Heading data-testid="verification-successful" is="h2">
          <Localized id="email-verification-header-success" />
        </Heading>
        <Text textAlign="center">
          <Localized id="email-verification-text-success" />
        </Text>
        <motion.span variants={button}>
          <BaseLink to={redirectTo} external data-testid="to-website">
            <Button variant="primary" className={classes.redirectButton}>
              <RedirectAppTextWrapper hasCustomRedirect={Boolean(customCorporateRedirectPath)} />
            </Button>
          </BaseLink>
        </motion.span>
      </ActionOutcome>
    </motion.div>
  )
}

export default AccountActivationSuccess
