import { parse } from 'query-string'
import React from 'react'

import { useLocation } from 'react-router-dom'
import RedirectAppText from '../components/RedirectAppText'
import { useRedirectAppTextWrapperVenueQuery } from '../graphql'
import getRedirectApp from '../utils/get-redirect-app'

interface Props {
  hasCustomRedirect: boolean
}

const RedirectAppTextWrapper = ({ hasCustomRedirect }: Props) => {
  const location = useLocation()
  const redirectApp = getRedirectApp(location)
  const { venueId } = parse(location.search) as { venueId: string }
  const { data } = useRedirectAppTextWrapperVenueQuery({
    variables: { id: venueId },
    skip: !venueId,
  })
  return (
    <RedirectAppText
      redirectApp={redirectApp}
      venueName={data && data.venue && data.venue.name}
      hasCustomRedirect={hasCustomRedirect}
    />
  )
}

export default RedirectAppTextWrapper
