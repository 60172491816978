import { ApolloError } from '@apollo/client'
import { parseApolloError } from '@eversports/react-components/logical/parse-apollo-error'
import parseExpectedError from '@eversports/react-components/logical/parse-expected-error'
import safeRedirect from '@eversports/react-components/utilities/safe-redirect'
import { ClientError } from '@eversports/react-components/client-error'
import { parse } from 'query-string'
import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { AuthRoutes } from '../auth-routes'
import { EversportsAndVenueConsentForm, EversportsConsentForm } from '../components/ConsentForms'
import handleAuthResult, { AuthResult } from '../utils/handle-auth-result'
import { normalizePolicyUrls } from '../utils/normalize-policy-urls'
import {
  useConsentScreenAcceptEversportsAndVenuePoliciesMutation,
  useConsentScreenAcceptEversportsPoliciesMutation,
  useConsentScreenCurrentPoliciesQuery,
  useConsentScreenEversportsAndVenueCurrentPoliciesQuery,
} from '../graphql'

interface VenueAndEversportsConsentProps {
  onCompleted: (result: AuthResult) => void
  handleConsentRejection: () => void
  venueId: string
}

const VenueAndEversportsConsent = ({
  onCompleted,
  handleConsentRejection,
  venueId,
}: VenueAndEversportsConsentProps) => {
  const location = useLocation()
  const [error, setError] = useState<ClientError | undefined>()

  const [consentMutation, { loading: mutationLoading }] = useConsentScreenAcceptEversportsAndVenuePoliciesMutation({
    variables: { venueId },
    onCompleted: ({ acceptEversportsPolicies, acceptVenuePolicies }) => {
      if (acceptEversportsPolicies.__typename === 'ExpectedErrors') {
        return setError(parseExpectedError(acceptEversportsPolicies.errors))
      }
      if (acceptVenuePolicies.__typename === 'ExpectedErrors') {
        return setError(parseExpectedError(acceptVenuePolicies.errors))
      }
      return onCompleted(acceptEversportsPolicies)
    },
    onError: (e: ApolloError) => {
      setError(parseApolloError(e))
    },
  })

  const { data } = useConsentScreenEversportsAndVenueCurrentPoliciesQuery({
    variables: { venueId },
  })

  return data ? (
    <EversportsAndVenueConsentForm
      eversportsPolicies={normalizePolicyUrls(data.latestEversportsPolicies)}
      companyPolicies={normalizePolicyUrls(data.venue.company.latestPolicies)}
      venueName={data.venue.name}
      onConsentAcceptance={consentMutation}
      loading={mutationLoading}
      onConsentRejection={handleConsentRejection}
      error={error}
      errorUrl={`/register?${location.search}`}
    />
  ) : null
}

interface EversportsConsentProps {
  onCompleted: (result: AuthResult) => void
  handleConsentRejection: () => void
}

const EversportsConsent = ({ onCompleted, handleConsentRejection }: EversportsConsentProps) => {
  const [error, setError] = useState<ClientError | undefined>()
  const location = useLocation()

  const [consentMutation, { loading: mutationLoading }] = useConsentScreenAcceptEversportsPoliciesMutation({
    onCompleted: ({ acceptEversportsPolicies }) => {
      if (acceptEversportsPolicies.__typename === 'ExpectedErrors') {
        return setError(parseExpectedError(acceptEversportsPolicies.errors))
      }
      return onCompleted(acceptEversportsPolicies)
    },
    onError: (e: ApolloError) => {
      setError(parseApolloError(e))
    },
  })

  const { data } = useConsentScreenCurrentPoliciesQuery()

  return data ? (
    <EversportsConsentForm
      policies={normalizePolicyUrls(data.latestEversportsPolicies)}
      onConsentAcceptance={consentMutation}
      loading={mutationLoading}
      onConsentRejection={handleConsentRejection}
      error={error}
      errorUrl={`/register?${location.search}`}
    />
  ) : null
}

const ConsentScreen = () => {
  const location = useLocation()
  const history = useHistory()

  const { venueId, redirectApp, origin } = parse(location.search) as Partial<{
    venueId: string
    redirectApp: string
    origin: string
  }>

  const onCompleted = async (result: AuthResult) => {
    const { n: nextLocation } = parse(history.location.search) as { n: string }

    return handleAuthResult({ result, history, nextLocation })
  }

  const handleConsentRejection = () => {
    safeRedirect(history, AuthRoutes.LOGIN)
  }

  return venueId && redirectApp === 'widget' && origin === 'widget' ? (
    <VenueAndEversportsConsent
      venueId={venueId}
      onCompleted={onCompleted}
      handleConsentRejection={handleConsentRejection}
    />
  ) : (
    <EversportsConsent onCompleted={onCompleted} handleConsentRejection={handleConsentRejection} />
  )
}

export default ConsentScreen
