// this is the prefix of our policy urls which is hardcoded like that in the database…
// we will just replace that prefix with a relative url, which then automatically
// has the correct TLD, wheeeee… no more MARKETPLACE_XX_URL \o/
const POLICY_PREFIX = 'https://www.eversports.com/h/'
const REPLACE_WITH = '/h/'

export function normalizePolicyUrls<T extends { url: string }>(urls?: Array<T>): Array<T> {
  if (!urls) {
    return []
  }
  const normalizedUrls = []
  for (const rawUrl of urls) {
    if (rawUrl.url.startsWith(POLICY_PREFIX)) {
      normalizedUrls.push({
        ...rawUrl,
        url: rawUrl.url.replace(POLICY_PREFIX, REPLACE_WITH),
      })
    } else {
      normalizedUrls.push(rawUrl)
    }
  }

  return normalizedUrls
}
