import React from 'react'

import Policies from '../components/Policies'
import { usePolicyWrapperCurrentPoliciesQuery } from '../graphql'
import { normalizePolicyUrls } from '../utils/normalize-policy-urls'

const PolicyWrapper = () => {
  const { data } = usePolicyWrapperCurrentPoliciesQuery()

  return <Policies policies={data ? normalizePolicyUrls(data.latestEversportsPolicies) : []} />
}

export default PolicyWrapper
