import { ApolloError } from '@apollo/client'
import { parseApolloError } from '@eversports/react-components/logical/parse-apollo-error'
import parseExpectedError from '@eversports/react-components/logical/parse-expected-error'
import { ClientError } from '@eversports/react-components/client-error'
import { parse } from 'query-string'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { AuthRoutes } from '../auth-routes'
import PasswordResetForm from '../components/PasswordResetForm'
import handleAuthResult from '../utils/handle-auth-result'
import { usePasswordResetResetPasswordMutation } from '../graphql'

const PasswordReset = () => {
  const history = useHistory()
  const location = useLocation()
  const [error, setState] = useState<ClientError | undefined>()
  const [resetPasswordMutation, { loading }] = usePasswordResetResetPasswordMutation({
    onCompleted: ({ resetPassword: result }) =>
      result.__typename === 'ExpectedErrors'
        ? setState(parseExpectedError(result.errors))
        : handleAuthResult({ result, history, nextLocation: AuthRoutes.PASSWORD_RESET_SUCCESS }),

    onError: (e: ApolloError) => setState(parseApolloError(e)),
  })

  const handleOnResetPassword = async ({ password }: { password: string }) => {
    const { token } = parse(location.search)
    return resetPasswordMutation({
      variables: { password, token: token as string },
    })
  }

  return <PasswordResetForm onResetPassword={handleOnResetPassword} loading={loading} error={error} />
}

export default PasswordReset
