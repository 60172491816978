import React, { useState } from 'react'
import { motion } from '@eversports/react-components/design-tokens/motion'
import useMediaQuery from '@eversports/react-components/design-tokens/useMediaQuery'
import useTheme from '@eversports/react-components/design-tokens/useTheme'
import { getParsedApolloErrorFormFields } from '@eversports/react-components/logical/parse-apollo-error'
import Button from '@eversports/react-components/primitives/Button'
import Checkbox from '@eversports/react-components/primitives/Checkbox'
import Divider from '@eversports/react-components/primitives/Divider'
import ErrorBox from '@eversports/react-components/primitives/ErrorBox'
import Flex from '@eversports/react-components/primitives/Flex'
import Processing from '@eversports/react-components/primitives/Processing'
import Text, { SimpleText } from '@eversports/react-components/primitives/Text'
import TextField from '@eversports/react-components/primitives/TextField'
import { ClientError } from '@eversports/react-components/client-error'
import { makeStyles } from '@mui/styles'
import PyroForm, { Form } from 'pyro-form'

import { Localized } from '../localization/react'
import FacebookLoginButton from './FacebookLoginButton'
import GoogleLoginButton from './GoogleLoginButton'
import { MAX_BUTTON_WIDTH } from './LoginForm'
import { GoogleLoginReturnType } from './GoogleLoginButton/GoogleLoginButton.types'

export interface RegistrationValues {
  firstname: string
  lastname: string
  email: string
  password: string
  newsletter: Array<string>
}

interface Props {
  loading?: boolean
  error?: ClientError
  hideNewsletterOnRegistration?: boolean
  onRegistration?: (values: RegistrationValues) => void
  // eslint-disable-next-line no-undef
  onFacebookLogin: (response: FacebookSDKResponse) => void
  onGoogleLogin: (response: GoogleLoginReturnType) => void
}

const initialValues: RegistrationValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  newsletter: [],
}

const useStyles = makeStyles({
  form: {
    width: '100%',
    maxWidth: 350,
  },
  buttons: {
    width: `${MAX_BUTTON_WIDTH}px`,
  },
})

// TODO : Add form field validation
const RegistrationForm: React.FC<React.PropsWithChildren<Props>> = ({
  loading,
  error,
  onRegistration,
  hideNewsletterOnRegistration = false,
  onFacebookLogin,
  onGoogleLogin,
}) => {
  const [showRegistrationForm, setShowRegistrationForm] = useState(false)

  const classes = useStyles()
  const theme = useTheme()
  const isInMobileView = useMediaQuery(theme.breakpoints.down('md'))

  const toggleShowRegistration = () => {
    setShowRegistrationForm(!showRegistrationForm)
  }

  return (
    <motion.div exit={{ opacity: 0 }} transition={{ ease: 'easeInOut' }}>
      <PyroForm initialValues={initialValues} errors={getParsedApolloErrorFormFields(error)} onSubmit={onRegistration}>
        {({ hasErrors }) => (
          <Processing isLoading={loading} fullWidth={isInMobileView}>
            <Form className={classes.form}>
              <Flex flexDirection="column" verticalSpacing="default" fullWidth alignItems="center">
                {showRegistrationForm ? (
                  <Flex flexDirection="column" verticalSpacing="default" fullWidth alignItems="center">
                    <TextField
                      name="firstname"
                      label={<Localized id="authentication-registration-firstname-label" />}
                      required
                      fullWidth
                      type="text"
                      autoComplete="given-name"
                    />
                    <TextField
                      name="lastname"
                      label={<Localized id="authentication-registration-lastname-label" />}
                      required
                      fullWidth
                      type="text"
                      autoComplete="family-name"
                    />
                    <TextField
                      name="email"
                      label={<Localized id="authentication-registration-email-label" />}
                      required
                      fullWidth
                      type="email"
                      autoComplete="email"
                    />
                    <TextField
                      name="password"
                      label={<Localized id="authentication-registration-password-label" />}
                      required
                      fullWidth
                      type="password"
                      autoComplete="new-password"
                    />
                    {!hideNewsletterOnRegistration && (
                      <label>
                        <Flex horizontalSpacing="default" alignItems="center">
                          <Checkbox name="newsletter" value={String(true)} />
                          <Text size="small">
                            <Localized id="eversports-newsletter" />
                          </Text>
                        </Flex>
                      </label>
                    )}
                    <Button data-testid="register" variant="primary" type="submit" fullWidth disabled={hasErrors}>
                      <SimpleText sx={{ textTransform: 'none' }}>
                        <Localized id="authentication-registration-action" />
                      </SimpleText>
                    </Button>
                  </Flex>
                ) : (
                  <Button
                    data-testid="toggle-email-registration"
                    variant="secondary"
                    className={classes.buttons}
                    onClick={toggleShowRegistration}
                  >
                    <SimpleText sx={{ textTransform: 'none' }}>
                      <Localized id="authentication-registration-continue-with-email" />
                    </SimpleText>
                  </Button>
                )}
                {error && <ErrorBox error={error} />}
                <Flex flexDirection="column" verticalSpacing="default" className={classes.buttons}>
                  <Divider>
                    <Localized id="authentication-login-separator-text" />
                  </Divider>
                  <GoogleLoginButton onLogin={onGoogleLogin} />
                  <FacebookLoginButton onLogin={onFacebookLogin} />
                </Flex>
              </Flex>
            </Form>
          </Processing>
        )}
      </PyroForm>
    </motion.div>
  )
}

export default RegistrationForm
