import Processing from '@eversports/react-components/primitives/Processing'
import Text from '@eversports/react-components/primitives/Text'

import React from 'react'

import CorporatePartnerComponent from '../components/CorporatePartnerComponent'
import useCorporateInvitation from '../data/use-corporate-invitation'
import { Localized } from '../localization/react'

const CorporatePartnerPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { data, loading, error } = useCorporateInvitation()

  if (error) {
    return (
      <Text>
        <Localized id="invalid-corporate-invitation" />
      </Text>
    )
  }

  if (loading || !data) {
    return <Processing isLoading fullWidth />
  }

  return (
    <CorporatePartnerComponent
      corporatePartner={data.corporatePartner}
      budget={data.corporateBudget}
      hasSpecialPriceAccess={data.hasSpecialPriceAccess}
    />
  )
}

export default CorporatePartnerPage
